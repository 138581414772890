import {
  ResponseFormula,
  ResponseFormulaDecimalPoint,
  ResponseFormulaSubTypeEnum,
  RuleDefTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Form, Row } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { ButtonGroup } from './EditResponseFormulaRuleDrawer.styled'
import FractionalDigitsRow from './FractionalDigitsRow'
import NormalValueRow from './NormalValueRow'

interface OwnProps {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  response?: ResponseFormula
  onClose: () => void
}

export type FormulaRuleProps = {
  hasRule: boolean
  minimum?: number
  maximum?: number
  scale?: string
  subType?: ResponseFormulaSubTypeEnum
  decimalPoint?: ResponseFormulaDecimalPoint
  ruleDefType?: RuleDefTypeEnum
}

const EditResponseFormulaRuleDrawerContainer: FC<OwnProps> = ({
  response,
  questionNode,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch()

  // 計算式タイプの質問にルールを設定する
  const changeResponseFormulaRule = (
    node: TemplateNodeSchema,
    scale: string,
    originalResponse: ResponseFormula,
    newResponseParams: Pick<
      ResponseFormula,
      'minimum' | 'maximum' | 'subType' | 'decimalPoint'
    >,
  ) => {
    const newResponse: ResponseFormula = {
      ...originalResponse,
      ...newResponseParams,
    }
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: {
          ...node.question,
          scale: scale,
          responseFormulas: [newResponse],
        },
      }),
    )
  }

  // subType が 0 である場合にもルールなしの判定としたいが、
  // 0 は Enum に定義されておらず型エラーが発生するため、falsy かどうかで判定する
  const hasRule = !!response?.subType

  const initialValues: FormulaRuleProps = {
    hasRule,
    minimum: response?.minimum,
    maximum: response?.maximum,
    scale: questionNode.question?.scale,
    subType: response?.subType,
    decimalPoint: {
      value: response?.decimalPoint?.value,
      isActive: response?.decimalPoint?.isActive,
    },
  }

  const formikProps = useFormik({
    initialValues: initialValues,
    onSubmit: (
      {
        hasRule,
        subType,
        minimum,
        maximum,
        decimalPoint,
        scale,
      }: FormulaRuleProps,
      { setErrors, resetForm }: FormikHelpers<FormulaRuleProps>,
    ) => {
      if (hasRule) {
        if (!subType) {
          setErrors({ subType: 'ルールの設定を行ってください' })
          return
        }

        switch (subType) {
          case ResponseFormulaSubTypeEnum.BETWEEN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            if (minimum > maximum) {
              setErrors({ minimum: '最小値 ー 最大値 で入力して下さい' })
              return
            }
            break
          case ResponseFormulaSubTypeEnum.LESS_THAN:
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            break
          case ResponseFormulaSubTypeEnum.GREATER_THAN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            break
        }
      }

      if (decimalPoint) {
        const { isActive, value } = decimalPoint

        if (isActive && value === undefined) {
          setErrors({
            decimalPoint: '小数点以下の桁数を入力してください。',
          })
          return
        }
        if (!!subType && isActive && value !== undefined) {
          const maximumDecimalPoint = String(maximum).split('.')[1]?.length || 0
          const minimumDecimalPoint = String(minimum).split('.')[1]?.length || 0
          const ruleDecimalPoint =
            maximumDecimalPoint > minimumDecimalPoint
              ? maximumDecimalPoint
              : minimumDecimalPoint

          if (value < ruleDecimalPoint) {
            setErrors({
              decimalPoint:
                '小数点以下の桁数をルールの小数点桁数より大きい数値で入力してください。',
            })
            return
          }
        }
      }

      if (subType === ResponseFormulaSubTypeEnum.GREATER_THAN) {
        maximum = undefined
      }
      if (subType === ResponseFormulaSubTypeEnum.LESS_THAN) {
        minimum = undefined
      }

      changeResponseFormulaRule(questionNode, scale ?? '', response || {}, {
        minimum,
        maximum,
        subType,
        decimalPoint,
      })
      onClose()
      resetForm()
    },
    enableReinitialize: true,
  })

  const { handleSubmit, resetForm } = formikProps

  const onCloseOrCancel = () => {
    onClose()
    resetForm()
  }

  const onSubmit = () => handleSubmit()

  return (
    <Drawer
      title="ルールの設定"
      maskClosable={false}
      placement="right"
      onClose={onCloseOrCancel}
      open={isOpen}
      width="600"
    >
      <Form>
        <NormalValueRow {...{ formikProps }} />
        <FractionalDigitsRow {...{ formikProps }} />
        <Row style={{ justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button type="link" onClick={onCloseOrCancel}>
              キャンセル
            </Button>
            <Button type="primary" onClick={onSubmit}>
              保存
            </Button>
          </ButtonGroup>
        </Row>
      </Form>
    </Drawer>
  )
}

export default EditResponseFormulaRuleDrawerContainer
