import {
  MasterFieldDef,
  ResponseNumber,
  ResponseNumberSubTypeEnum,
  RuleDefTypeEnum,
} from '@ulysses-inc/harami_api_client'
import convertDisplayCharacters, {
  convertDecimalPointCharacters,
} from 'src/exShared/util/numberRule/convertDisplayCharacters'
import { spreadRuleVariableDefs } from '../question'

export const getNumberRuleDisplayCharacters = (
  scale: string,
  responseNumber: ResponseNumber,
  productMasterFieldDefs: MasterFieldDef[],
): string => {
  if (responseNumber.ruleDefType == RuleDefTypeEnum.Master) {
    return convertNumberRuleDisplayCharacters(
      responseNumber,
      productMasterFieldDefs,
    )
  }
  return convertDisplayCharacters(scale, responseNumber)
}

const convertNumberRuleDisplayCharacters = (
  responseNumber: ResponseNumber,
  productMasterFieldDefs: MasterFieldDef[],
): string => {
  const rule = convertDisplayNumberRuleCharacters(
    responseNumber,
    productMasterFieldDefs,
  )
  const decimalPoint = convertDecimalPointCharacters(responseNumber)
  return [rule, decimalPoint].filter(v => !!v).join('・')
}

const convertDisplayNumberRuleCharacters = (
  responseNumber: ResponseNumber,
  productMasterFieldDefs: MasterFieldDef[],
): string => {
  const { minimumRuleVariableDef, maximumRuleVariableDef } =
    spreadRuleVariableDefs(responseNumber.ruleVariableDefs)
  const minimumFieldDef = productMasterFieldDefs.find(
    fieldDef => fieldDef.id === minimumRuleVariableDef?.masterFieldDefId,
  )
  const maximumFieldDef = productMasterFieldDefs.find(
    fieldDef => fieldDef.id === maximumRuleVariableDef?.masterFieldDefId,
  )

  switch (responseNumber.subType) {
    case ResponseNumberSubTypeEnum.BETWEEN:
      return `${minimumFieldDef?.name}〜${maximumFieldDef?.name}の間`
    case ResponseNumberSubTypeEnum.LESS_THAN:
      return `${maximumFieldDef?.name}以下`
    case ResponseNumberSubTypeEnum.GREATER_THAN:
      return `${minimumFieldDef?.name}以上`
    default:
      return 'ルールなし'
  }
}
