import {
  CloseOutlined,
  DownloadOutlined,
  ImportOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Employee,
  PlaceNode,
  PlaceNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import Papa, { ParseResult } from 'papaparse'
import React, { FC, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import Yup from 'src/features/validation/yup'
import { RootState } from 'src/state/store'
import Loading from '../../../components/loading/Loading'
import employeesOperations from '../../../state/ducks/employees/operations'
import {
  CSVImportButton,
  CSVImportButtonGroup,
  EmployeesHeader,
  EmployeesHeaderContainer,
  EmployeesHeaderWrap,
  HeaderRow,
  ImportDescription,
  PlaceNodeWarningMessage,
  PlaceNodeWarningMessageWrap,
  SampleDownloadButton,
  SelectFileButtonGroup,
  SelectFileInput,
  SelectFileName,
  SelectFileNameWrap,
  TableBody,
  TableRow,
  TableRowWrap,
} from './ImportEmployees.styled'

export type EmployeesForm = { employees: Employee[]; fileName: string }

const ImportEmployees: FC = () => {
  const dispatch = useDispatch()

  const { filter, isLoading, placeNodeWarning } = useSelector(
    (state: RootState) => state.employeesState.employees,
  )

  const nodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const places = useSelector(
    (state: RootState) => state.placesState.places.places,
  )
  const placeNodes = getUniqueFlattenNodes([
    ...flattenNodes(nodes ?? []),
    ...(places ?? []),
  ])

  const updatePlaceNodeWarning = (message: string) => {
    employeesOperations.updatePlaceNodeWarning(dispatch, message)
  }

  const initialValues: EmployeesForm = { employees: [], fileName: '' }
  const validationSchema = Yup.object().shape({
    employees: Yup.array().min(1, 'CSVファイルに従業員が含まれていません'),
    fileName: Yup.string().required().label('CSVファイル'),
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (
      values: EmployeesForm,
      formikHelpers: FormikHelpers<EmployeesForm>,
    ) => {
      const request = { limit: 25, offset: 0 }
      employeesOperations.addMultipleEmployees(
        dispatch,
        values.employees,
        request,
        filter,
      )
      formikHelpers.resetForm()
      updatePlaceNodeWarning('')
    },
    enableReinitialize: true,
  })

  const parseEmployeesFromFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files.item(0)
    formik.setFieldValue('fileName', file?.name ?? '')
    updatePlaceNodeWarning('')
    if (file) {
      Papa.parse(file, {
        complete: (results: ParseResult<string[]>) => {
          const csvRowLength = 4
          // 最大行数を1000行に固定した。
          // レコード数に上限を設けておかないとmobile側での表示にも影響が出ると考えられるため一定ラインの上限は必要
          const csvRowMaxCount = 1000
          if (results.data.length > csvRowMaxCount) {
            formik.setFieldError(
              'employees',
              `csv取り込みの最大行数は${csvRowMaxCount}件までです`,
            )
            formik.setFieldTouched('employees', true, false)
            return
          }
          const notExistsPlaceNames = new Set()
          const uniqueEmployees: { [key: string]: Employee } = {}
          results.data
            .slice(1)
            .filter((row: string[]) => row.length === csvRowLength)
            .forEach((row: string[]) => {
              const employee = {
                name: row[0],
                nameKana: row[1],
                employeeCode: row[2],
                placeNodes: row[3]
                  .split(',')
                  .map(placeName => {
                    const placeNode = getPlaceNodesFromName(
                      placeName,
                      placeNodes,
                    )
                    if (placeNode === undefined) {
                      notExistsPlaceNames.add(placeName)
                    }
                    return {
                      uuid: placeNode?.uuid ?? '',
                      id: placeNode?.id ?? 0,
                      type: placeNode?.type ?? 0,
                      isRootNode: 0,
                      parentNodes: [],
                      nodes: [],
                    }
                  })
                  .filter(placeNode => placeNode.uuid !== ''),
              }
              if (employee.employeeCode === '') return
              uniqueEmployees[employee.employeeCode] = employee
            })
          formik.setFieldValue('employees', Object.values(uniqueEmployees))
          if (notExistsPlaceNames.size !== 0) {
            const notExistsPlaceNamesString =
              Array.from(notExistsPlaceNames).join(', ')
            updatePlaceNodeWarning(
              `以下の現場は存在しません\n${notExistsPlaceNamesString}`,
            )
          }
        },
      })
    }
    e.target.value = ''
  }

  const getPlaceNodesFromName = (
    placeName: string,
    placeNodes: PlaceNode[],
  ) => {
    return placeNodes.find((placeNode: PlaceNode) => {
      if (placeNode.type === PlaceNodeTypeEnum.Place) {
        return placeNode.place?.name === placeName
      } else if (placeNode.type === PlaceNodeTypeEnum.PlaceGroup) {
        return placeNode.placeGroup?.name === placeName
      } else {
        return false
      }
    })
  }

  const sampleFileDownloadCSVData = [
    ['名前', '名前(カナ)', '従業員コード', '所属現場'],
    ['鈴木 太郎', 'スズキ タロウ', '0001', '神田店'],
    ['田中 次郎', 'タナカ ジロウ', '0002', '神田店'],
    ['佐藤 三郎', 'サトウ サブロウ', '0003', '渋谷店,大手町店'],
  ]

  const inputEl = useRef<HTMLInputElement>(null)
  const { errors, values, handleSubmit, touched, resetForm } = formik
  return (
    <EmployeesHeaderContainer>
      <ImportDescription>
        従業員インポート用のCSVファイルを選択してください
      </ImportDescription>
      <SelectFileButtonGroup align="middle">
        <Button
          onClick={() => {
            if (inputEl && inputEl.current) {
              inputEl.current.click()
            }
          }}
        >
          <UploadOutlined />
          ファイルを選択
        </Button>
        <SelectFileInput
          type="file"
          ref={inputEl}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            parseEmployeesFromFile(e)
          }}
          accept=".csv"
        />
        {values.fileName !== '' && (
          <SelectFileNameWrap>
            <PaperClipOutlined />
            <SelectFileName>{values.fileName}</SelectFileName>
            <Button
              icon={<CloseOutlined />}
              size="small"
              shape="circle"
              onClick={() => {
                resetForm()
                updatePlaceNodeWarning('')
              }}
            />
          </SelectFileNameWrap>
        )}
      </SelectFileButtonGroup>
      <div>
        <FormikErrorMessage name="fileName" errors={errors} touched={touched} />
      </div>
      {values.fileName && (
        <div>
          <FormikErrorMessage
            name="employees"
            errors={errors}
            touched={touched}
          />
        </div>
      )}
      {placeNodeWarning && (
        <PlaceNodeWarningMessageWrap>
          <PlaceNodeWarningMessage>{placeNodeWarning}</PlaceNodeWarningMessage>
        </PlaceNodeWarningMessageWrap>
      )}
      <CSVImportButtonGroup>
        <CSVImportButton
          type="primary"
          onClick={() => handleSubmit()}
          loading={isLoading}
        >
          <ImportOutlined />
          CSVインポート
        </CSVImportButton>
        <CSVLink
          data={sampleFileDownloadCSVData}
          uFEFF={true}
          filename="従業員サンプル.csv"
        >
          <SampleDownloadButton>
            <DownloadOutlined />
            サンプルダウンロード
          </SampleDownloadButton>
        </CSVLink>
      </CSVImportButtonGroup>
      {!isLoading && (
        <EmployeesHeaderWrap>
          <EmployeesHeader>
            <HeaderRow theme={{ width: '20%' }}>カラム名</HeaderRow>
            <HeaderRow theme={{ width: '80%' }}>説明</HeaderRow>
          </EmployeesHeader>
          <TableBody
            itemLayout="horizontal"
            dataSource={[
              { name: '名前', description: '(例)山田 太郎' },
              { name: '名前(かな)', description: '(例)やまだ たろう' },
              {
                name: '従業員コード',
                description: '貴社のフォーマットに合わせてご記入ください',
              },
              {
                name: '所属現場',
                description:
                  '現場設定画面で登録されている現場の名前をカンマ区切り(,)で指定ください',
              },
            ]}
            renderItem={(item: { name: string; description: string }) => (
              <TableRowWrap key={item.name}>
                <TableRow theme={{ width: '20%' }}>{item.name}</TableRow>
                <TableRow theme={{ width: '80%' }}>{item.description}</TableRow>
              </TableRowWrap>
            )}
          />
        </EmployeesHeaderWrap>
      )}
      {isLoading && <Loading />}
    </EmployeesHeaderContainer>
  )
}

export default ImportEmployees
