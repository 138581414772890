import { ResponseNumberSubTypeEnum } from '@ulysses-inc/harami_api_client'
import { Form, Input, InputNumber, Select } from 'antd'
import styled from 'styled-components'

export const SubTypeFormItem = styled(Form.Item)`
  width: 80px;
`

export const FixedRuleContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const NumberMinimumInputNumber = styled(InputNumber)`
  width: 90px;
`

export const NumberMaximumInputNumber = styled(InputNumber)`
  width: 90px;
`

export const BetweenFormItem = styled(Form.Item)`
  width: 12px;
  height: 20px;
  text-align: center;
  &&& {
    margin: 4px 4px;
  }
`

export const ScaleFormItem = styled(Form.Item)`
  width: 80px;
  &&& {
    margin-left: 16px;
    margin-bottom: 0px;
  }
`

export const NumberSubTypeSelect = styled(Select<ResponseNumberSubTypeEnum>)`
  && {
    width: 80px;
  }
`

export const ScaleInput = styled(Input)`
  && {
    width: 80px;
    height: 32px;
    margin-bottom: 0px;
  }
`
export const MinMaxFormItem = styled.div`
  display: flex;
  gap: 4px;
`

export const ScaleAndSubTypeFormItem = styled.div`
  display: flex;
  gap: 4px;
`

export const MasterRuleContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const MasterFieldDefSelect = styled(Select<number>)`
  && {
    width: 184px;
  }
`
