import { ResponseNumberSubTypeEnum } from '@ulysses-inc/harami_api_client'
import { Form, Row, Select, Switch } from 'antd'
import styled from 'styled-components'

export const RuleSwitchRow = styled(Row)`
  align-items: center;
  margin-bottom: 12px;
`

export const RuleSwitch = styled(Switch)`
  &.ant-switch {
    margin-right: 12px;
  }
`

export const SubTypeFormItem = styled(Form.Item)`
  width: 90px;
`

export const NumberMinimumFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-right: 0px;
  }
`

export const NumberMaximumFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-right: 0px;
  }
`

export const BetweenFormItem = styled(Form.Item)`
  width: 35px;
  text-align: center;
  &&& {
    margin-right: 0px;
  }
`

export const ScaleFormItem = styled(Form.Item)`
  width: 90px;
  &&& {
    margin-left: 16px;
  }
`

export const NumberSubTypeSelect = styled(Select<ResponseNumberSubTypeEnum>)`
  && {
    width: 90px;
  }
`
