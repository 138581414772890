// 画面遷移時に独自の確認モーダルを表示する
// https://reactrouter.com/web/api/BrowserRouter/getuserconfirmation-func

// Promptと合わせて使う
// <Prompt
//   when={props.isDirty}
//   message={'変更した内容が破棄されますがよろしいですか？'}
// />
import { Modal } from 'antd'

type GetUserConfirmationFunc = (
  message: string,
  callback: (result: boolean) => void,
) => void

export type HistoryState = {
  data: any // eslint-disable-line @typescript-eslint/no-explicit-any
  title: string
  url: string
}

// NOTE: キャンセルしたときにURLがもとに戻らないバグがある
// https://github.com/ReactTraining/react-router/issues/5405
// 対策でhistoryを書き換えるパッチを入れておく
// hostry@5.0では直るらしいが、react-routerがまだ対応していない
const parseMessage = (message: string): [string, HistoryState | undefined] => {
  let title = ''
  let prevState: HistoryState | undefined
  try {
    const parse = JSON.parse(message) as
      | { message: string; state: HistoryState }
      | string
    if (typeof parse === 'string') {
      title = parse
    } else {
      title = parse.message
      prevState = parse.state
    }
  } catch {
    title = message
  }
  return [title, prevState]
}
const fixState = (state: HistoryState | undefined) => {
  if (state) {
    window.history.pushState(state.data, state.title, state.url)
  }
}

const getUserConfirmation: GetUserConfirmationFunc = (message, callback) => {
  const [title, prevState] = parseMessage(message)
  Modal.confirm({
    title: title || '変更した内容が破棄されますがよろしいですか？',
    okText: 'はい',
    okType: 'danger',
    cancelText: 'キャンセル',
    onOk() {
      callback(true)
    },
    onCancel() {
      callback(false)
      fixState(prevState)
    },
  })
}

export default getUserConfirmation
