import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { GetUsersRequest, PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Input, Select, Switch, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { usePlaceLoadingStatus } from 'src/exShared/hooks/usePlaceLoadingStatus'
import {
  splitAccessiblePlaceNode,
  splitAllPlaceNodes,
} from 'src/exShared/libraries/filterDropDown/place'
import { mergedPlaceNodeIds } from 'src/exShared/util/place/placeNode'
import { splitLoginPlaceUUIDs } from 'src/exShared/util/place/splitLoginPlace'
import { BorderColor, White } from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { GetUsersFilter } from 'src/state/ducks/users/types'
import styled from 'styled-components'
import { ListFilter } from '../../views/types/utils/ListFilter'

const { Text } = Typography
const { Option } = Select

const TableCell = styled.div`
  display: table-cell;
  border: 1px solid ${BorderColor};
  background: ${White};
  vertical-align: middle;
`
const FilterButton = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
`

const UpOutlinedIcon = styled(UpOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const DownOutlinedIcon = styled(DownOutlined)`
  color: rgba(0, 0, 0, 0.65);
`

const SelectFilterTableCell = styled(TableCell)`
  min-width: ${({ theme }) => theme.minWidth};
  background-color: ${({ theme }) => theme.backgroundColor};
  height: 32px;
`

const DropDownTableCell = styled(TableCell)`
  width: 400px;
  background-color: ${White};
  padding: 16px;
`

const styles = {
  clearButtonRow: css`
    display: flex;
    justify-content: flex-end;
  `,
}

interface OwnProps {
  places: PlaceNode[]
  placeNodes: PlaceNode[]
  request?: GetUsersRequest
  filters: ListFilter | null
  setUserFilter: (filter?: GetUsersFilter) => void
  updatedUsers: boolean
  isLoadingUser?: boolean
}

const UsersFilterDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [userName, setUserName] = useState<string>('')
  const [placeNodeIds, setPlaceNodeIds] = useState<string[]>([])
  const [placeGroupNodeIds, setPlaceGroupNodeIds] = useState<string[]>([])
  const [accessiblePlaces, setAccessiblePlaces] = useState<PlaceNode[]>([])
  const [accessiblePlaceGroups, setAccessiblePlaceGroups] = useState<
    PlaceNode[]
  >([])
  const [isShowAllPlaceUsers, setIsShowAllPlaceUsers] = useState<boolean>(false)
  const { filters, setListFilter } = useListFilter()
  const { loginPlaceGroupIds, loginPlaceIds } = splitLoginPlaceUUIDs()
  const placeLoadingStatus = usePlaceLoadingStatus()

  // 初回及び、全現場表示ボタン切り替え時にフィルタで選択可能な現場をセット
  useEffect(() => {
    let accessiblePlaceNode: {
      accessiblePlaces: PlaceNode[]
      accessiblePlaceGroups: PlaceNode[]
    }
    if (isShowAllPlaceUsers) {
      accessiblePlaceNode = splitAllPlaceNodes(props.placeNodes, props.places)
    } else {
      accessiblePlaceNode = splitAccessiblePlaceNode(
        props.placeNodes,
        props.places,
      )
    }
    setAccessiblePlaces(accessiblePlaceNode.accessiblePlaces)
    setAccessiblePlaceGroups(accessiblePlaceNode.accessiblePlaceGroups)
  }, [props.placeNodes, props.places, isShowAllPlaceUsers])

  // フィルター条件の変更検知して再取得
  useEffect(() => {
    if (placeLoadingStatus !== 'finish') {
      return
    }
    // 更新が完了してない場合はリターン
    if (props.updatedUsers) {
      return
    }

    let accessiblePlaceNodeIds: string[]
    let accessiblePlaceNodeGroupIds: string[]
    if (isShowAllPlaceUsers) {
      const allPlaceNodeIds = mergedPlaceNodeIds(
        props.placeNodes,
        placeNodeIds ?? [],
        placeGroupNodeIds ?? [],
        true,
      )
      accessiblePlaceNodeIds = allPlaceNodeIds
      accessiblePlaceNodeGroupIds = []
    } else {
      accessiblePlaceNodeIds = loginPlaceIds
      accessiblePlaceNodeGroupIds = loginPlaceGroupIds
    }

    // 現場フィルタ設定が空の場合、アクセス可能な全ユーザー表示
    let filter: GetUsersFilter
    if (placeNodeIds?.length === 0 && placeGroupNodeIds.length === 0) {
      filter = getFilter(
        userName,
        accessiblePlaceNodeIds,
        accessiblePlaceNodeGroupIds,
      )
    } else {
      filter = getFilter(userName, placeNodeIds, placeGroupNodeIds)
    }
    props.setUserFilter(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.updatedUsers,
    userName,
    placeNodeIds,
    placeGroupNodeIds,
    placeLoadingStatus,
  ])

  const isFilterOn = () => {
    return userName || placeGroupNodeIds.length > 0 || placeNodeIds.length > 0
  }

  const clearFilter = () => {
    setPlaceNodeIds([])
    setPlaceGroupNodeIds([])
    setUserName('')
    saveFilter()
  }

  const saveFilter = (
    userName?: string,
    placeNodeIds?: string[],
    placeGroupNodeIds?: string[],
  ) => {
    setListFilter({
      ...(filters ?? {}),
      users: {
        pagination: props.request,
        filter: {
          userName: userName ?? '',
          placeNodeIds: placeNodeIds,
          placeGroupNodeIds: placeGroupNodeIds,
        },
      },
    })
  }

  const getFilter = (
    userName: string,
    placeNodeIds: string[],
    placeGroupNodeIds?: string[],
  ): GetUsersFilter => {
    const userNameCondition = userName ? { $like: userName } : { $like: '' }
    const allPlaceNodeIds = mergedPlaceNodeIds(
      props.placeNodes,
      placeNodeIds ?? [],
      placeGroupNodeIds ?? [],
      true,
    )
    const placeNodeIdCondition =
      allPlaceNodeIds && allPlaceNodeIds.length > 0
        ? { $in: allPlaceNodeIds }
        : undefined
    saveFilter(userName, placeNodeIds, placeGroupNodeIds)
    return {
      userName: userNameCondition,
      placeNodeId: placeNodeIdCondition,
    }
  }

  const groundArea = (
    <SelectFilterTableCell theme={{ width: '160px' }}>
      <FilterButton>
        <div style={{ flex: 1, marginLeft: 12 }}>
          フィルター：{isFilterOn() ? 'ON' : 'OFF'}
        </div>
        <div style={{ minWidth: 20, marginRight: 12 }}>
          {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
        </div>
      </FilterButton>
    </SelectFilterTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }}>
      <DropDownTableCell>
        <div css={styles.clearButtonRow}>
          <Button onClick={clearFilter}>クリア</Button>
        </div>

        <>
          <div style={{ marginBottom: 5 }}>
            <Text style={{ marginLeft: 3 }}>表示名</Text>
          </div>
          <Input
            allowClear
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const newValue = event.target.value
              setUserName(newValue)
            }}
            defaultValue={userName}
            value={userName}
          />
        </>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text>所属現場以外のユーザーも表示</Text>
        </div>
        <Switch
          checked={isShowAllPlaceUsers}
          onChange={(checked: boolean) => {
            setIsShowAllPlaceUsers(checked)

            // フィルタリセット
            setPlaceNodeIds([])
            setPlaceGroupNodeIds([])
          }}
        />
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場グループ名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeGroupNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceGroupIds: string[] | undefined) => {
            setPlaceGroupNodeIds(selectedPlaceGroupIds || [])
          }}
        >
          {accessiblePlaceGroups.map((placeNode: PlaceNode, index: number) => (
            <Option
              key={index}
              value={placeNode.uuid}
              label={placeNode.placeGroup?.name}
            >
              {placeNode.placeGroup?.name}
            </Option>
          ))}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 5 }}>
          <Text style={{ marginLeft: 3 }}>現場名</Text>
        </div>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          value={placeNodeIds}
          optionFilterProp="label"
          onChange={(selectedPlaceNodeIds: string[] | undefined) => {
            setPlaceNodeIds(selectedPlaceNodeIds || [])
          }}
        >
          {accessiblePlaces.map((placeNode: PlaceNode, index: number) => (
            <Option
              key={index}
              value={placeNode.uuid}
              label={placeNode.place?.name}
            >
              {placeNode.place?.name}
            </Option>
          ))}
        </Select>
      </DropDownTableCell>
    </div>
  )

  return (
    <Dropdown
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      {groundArea}
    </Dropdown>
  )
}

export default UsersFilterDropDown
