import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleShowScore } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import OptionToggleSwitch from './OptionToggleSwitch'

const ScoreSwitch: FC = () => {
  const dispatch = useDispatch()
  const isShowScore = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isShowScore,
    shallowEqual,
  )

  return (
    <OptionToggleSwitch
      isChecked={isShowScore}
      onChange={checked => dispatch(toggleShowScore(checked))}
      label="配点機能をつける"
    />
  )
}

export default ScoreSwitch
