import { isValidDateStr } from 'src/exShared/util/date'
import { RowPatch } from '../actions'
import { GRID_VARIABLES_LENGTH } from '../constants'
import { GridVariablesByNumber, Row, SectionsByName, Values } from '../types'
import { adjustThroughDate, resetHourPrecision } from './adjustDate'
import { createEmptyRow } from './createEmptyRow'
import { createEmptyValue } from './createEmptyValue'
import { deepCopyRow } from './deepCopyTable'

/**
 * patchData で更新した新たなRowを返す
 *
 * @param patchData
 * @param oldRow
 * @param sectionsByName
 * @returns
 */
export const createPatchedRow = (
  patchData: RowPatch,
  oldRow: Row | undefined,
  sectionsByName: SectionsByName,
) => {
  const newRow = oldRow ? deepCopyRow(oldRow) : createEmptyRow()

  if (patchData.from !== undefined) {
    newRow.from.dateValue = createPatchedFrom(patchData.from)
  }

  if (patchData.through !== undefined) {
    newRow.through.dateValue = createPatchedThrough(patchData.through)
  }

  if (patchData.sectionName !== undefined) {
    newRow.sectionName.name = patchData.sectionName

    // セクションを変更した場合 `取り込み項目` に紐づく questionUUID も変わるため補完する
    const gridVariables = sectionsByName[newRow.sectionName.name]?.gridVariables
    if (gridVariables) {
      newRow.values = completeQuestionUUIDs(newRow.values, gridVariables)
    } else {
      /**
       * 存在しないセクションだった場合、 `取り込み項目` に紐づく questionUUID は存在しない
       * このため、入力値(value)とエラー(errors)はそのままにして、questionUUID(uuid)のみクリアする
       */
      Object.keys(newRow.values).forEach(k => {
        const variableNumber = Number(k)
        const value = newRow.values[variableNumber]
        if (value) {
          value.uuid = ''
        }
      })
    }
  }

  if (patchData.variableSection !== undefined) {
    newRow.variableSection.name = patchData.variableSection.trim()
  }

  if (patchData.values !== undefined) {
    newRow.values = createPatchedValues(patchData.values, newRow.values)

    // 新しく追加された value があった場合、まだ questionUUID を持っていないので補完する
    const gridVariables = sectionsByName[newRow.sectionName.name]?.gridVariables
    if (gridVariables) {
      newRow.values = completeQuestionUUIDs(newRow.values, gridVariables)
    }
  }

  return newRow
}

const createPatchedFrom = (patchFrom: string): string => {
  if (!isValidDateStr(patchFrom)) {
    return patchFrom
  }

  return resetHourPrecision(new Date(patchFrom)).toISOString()
}

const createPatchedThrough = (patchThrough: string): string => {
  if (!isValidDateStr(patchThrough)) {
    return patchThrough
  }

  return adjustThroughDate(new Date(patchThrough)).toISOString()
}

const completeQuestionUUIDs = (
  values: Values,
  gridVariablesByNumber: GridVariablesByNumber,
) => {
  const newValues = { ...values }

  Object.keys(newValues).forEach(k => {
    const gridVariableNumber = Number(k)
    const gridVariable = gridVariablesByNumber[gridVariableNumber]
    const targetNewValue = newValues[gridVariableNumber]
    if (targetNewValue) {
      targetNewValue.uuid = gridVariable || ''
    }
  })

  return newValues
}

const createPatchedValues = (
  patchValues: RowPatch['values'],
  originalValues: Values,
) => {
  const newValues: Values = {}

  for (let i = 1; i <= GRID_VARIABLES_LENGTH; i++) {
    const gridVariableNumber = i

    const patchValueStr = patchValues?.[gridVariableNumber]
    const originalValue = originalValues[gridVariableNumber]

    // NOTE: 現状 `入力値がない` 場合には、存在しない key/value とするので処理がややこしい
    //       ReduxStateのRowデータには、
    //       入力状態に関わらず常にvariableValueの6個分保持するほうが処理が簡潔になる

    if (patchValueStr === undefined && originalValue === undefined) {
      continue
    }

    if (patchValueStr === '') {
      continue
    }

    newValues[gridVariableNumber] = {
      ...createEmptyValue(),
      value:
        patchValueStr !== undefined
          ? patchValueStr
          : (originalValue?.value ?? ''),
    }
  }

  return newValues
}

/**
 * originRow から変更がある場合はtrueを返す。
 *
 * @param originRow
 * @param newRow
 * @returns
 */
export const hasChanged = (newRow: Row, originRow?: Row) => {
  if (originRow === undefined) {
    return true
  }

  if (newRow.from.dateValue !== originRow.from.dateValue) {
    return true
  }
  if (newRow.through.dateValue !== originRow.through.dateValue) {
    return true
  }
  if (newRow.sectionName.name !== originRow.sectionName.name) {
    return true
  }
  if (newRow.variableSection.name !== originRow.variableSection.name) {
    return true
  }

  for (let i = 1; i <= GRID_VARIABLES_LENGTH; i++) {
    const newValue = newRow.values[i]
    const originValue = originRow.values[i]

    if (newValue?.value !== originValue?.value) {
      return true
    }
  }

  return false
}
