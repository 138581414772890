import { ConfigProvider, Layout } from 'antd'
import { FC } from 'react'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import MasterList from './MasterList'

const Masters: FC = () => {
  return (
    <Layout>
      <Header>
        <HeaderTitle isBeta title="マスタ" />
      </Header>
      <Layout.Content>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 8,
                cellPaddingInline: 8,
              },
            },
          }}
        >
          <MasterList />
        </ConfigProvider>
      </Layout.Content>
    </Layout>
  )
}

export default Masters
