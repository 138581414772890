import { StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'
import BreakText from '../../BreakText'
import Grid from '../../Grid'

type Props = {
  questionName: string
}

const styles = StyleSheet.create({
  gridText: { width: '100%' },
})

export const LabelCheck: React.FC<Props> = ({ questionName }: Props) => {
  return (
    <Grid borderLeft borderRight style={styles.gridText}>
      <View>
        <BreakText text={questionName} />
        <BreakText text="AIラベル検査(β版)は現在PDF出力には対応しておりません" />
      </View>
    </Grid>
  )
}
