import {
  ResponseTextSubTypeEnum,
  ResponseTypeEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Select, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ColorTextSecondary } from 'src/features/theme/KdsThemeColor'
import {
  setTemplateReportNameRuleErrorMessage,
  updateTemplateReportNamingRules,
} from 'src/state/ducks/templates/actions'
import { RootState } from 'src/state/store'
import {
  BasicRow,
  ErrorMessageText,
  ReportNamingRulesQuetionSelectContainer,
} from './EditTemplateOption.styled'
import OptionToggleSwitch from './OptionToggleSwitch'

const { Text } = Typography
const { Option } = Select

const ReportNamingRuleRow = () => {
  const dispatch = useDispatch()

  const { templateNodes } = useSelector(
    (state: RootState) => state.templatesState.templatePages,
  )
  const {
    templateReportNamingRulesTemplateNodeUuid,
    isReportNamingRulesEnabled,
  } = useSelector((state: RootState) => state.templatesState.templatePages)

  // 例外となる繰り返しセクション配下のノードをキャッシュ
  const exceptionQuestionNodeUuid = useCallback(() => {
    const ids: number[] = []
    const traverseNodes = (nodes?: number[]) => {
      if (!nodes) return
      nodes.forEach(nodeId => {
        const targetNode = templateNodes[nodeId]
        if (!targetNode) return
        ids.push(targetNode.id)
        if (
          // nodesがある場合は再帰的に処理
          targetNode.nodes.length > 0
        ) {
          traverseNodes(targetNode.nodes)
        }
      })
    }

    Object.values(templateNodes)
      .filter(
        node =>
          node.type === TemplateNodeTypeEnum.Section && node.section?.isRepeat,
      )
      .forEach(section => traverseNodes(section.nodes))

    return ids
  }, [templateNodes])

  // 選択可能なテキスト質問ノードをキャッシュ
  const textQuestionNodes = useCallback(
    () =>
      Object.values(templateNodes).filter(
        node =>
          node.type === TemplateNodeTypeEnum.Question &&
          node.question?.responseType === ResponseTypeEnum.TEXT &&
          node.id &&
          !exceptionQuestionNodeUuid().includes(node.id) &&
          node.question?.name &&
          node.question?.responseTexts?.[0]?.subType ===
            ResponseTextSubTypeEnum.SINGLE_LINE, // 複数行の場合は除外
      ),
    [templateNodes, exceptionQuestionNodeUuid],
  )

  const [filteredTextQuestionNodes, setFilteredTextQuestionNodes] =
    useState(textQuestionNodes())

  const filteredTextQuestionNodeUUIDSet = useMemo(
    () => new Set(filteredTextQuestionNodes.map(node => node.uuid)),
    [filteredTextQuestionNodes],
  )

  const updateFilterdNodes = useCallback(() => {
    setFilteredTextQuestionNodes(textQuestionNodes())
  }, [textQuestionNodes])

  const templateReportNameRuleErrorMessage = useSelector(
    (state: RootState) =>
      state.templatesState.templatePages.templateReportNameRuleErrorMessage,
    shallowEqual,
  )

  const updateReportNamingRule = useCallback(
    ({ uuid, isEnabled }: { uuid: string; isEnabled: boolean }) => {
      dispatch(updateTemplateReportNamingRules(uuid, isEnabled))
      dispatch(setTemplateReportNameRuleErrorMessage(''))
    },
    [dispatch],
  )

  // 編集開始時に、既にレポート名設定に設定済みの質問名を表示するために表示可能な質問を取得
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false)
  useEffect(() => {
    if (
      isInitialLoadComplete ||
      templateReportNamingRulesTemplateNodeUuid === ''
    ) {
      return
    }
    updateFilterdNodes()
    setIsInitialLoadComplete(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateReportNamingRulesTemplateNodeUuid])

  return (
    <BasicRow justify="space-between">
      <OptionToggleSwitch
        isChecked={isReportNamingRulesEnabled}
        onChange={checked =>
          updateReportNamingRule({ uuid: '', isEnabled: checked })
        }
        label="作成されるレポート名に質問の回答を加える"
      />
      <Text
        style={{
          fontSize: '12px',
          color: ColorTextSecondary,
          flexBasis: '100%',
          marginTop: '4px',
        }}
      >
        例：{'回答結果_ひな形名 もしくは 回答結果_スケジュール名'}
      </Text>
      {isReportNamingRulesEnabled && (
        <ReportNamingRulesQuetionSelectContainer>
          <Select
            placeholder="質問を選択"
            onChange={value => {
              const selectedNode = filteredTextQuestionNodes.find(
                node => node.uuid === value,
              )
              if (selectedNode) {
                updateReportNamingRule({
                  uuid: selectedNode.uuid || '',
                  isEnabled: true,
                })
              }
            }}
            onFocus={updateFilterdNodes}
            showSearch
            style={{ width: 500 }}
            // レンダリングのタイミングで filteredTextQuestionNodes が空の場合、UUID が見えてしまうため制御
            value={
              filteredTextQuestionNodeUUIDSet.has(
                templateReportNamingRulesTemplateNodeUuid,
              )
                ? templateReportNamingRulesTemplateNodeUuid
                : undefined
            }
          >
            {filteredTextQuestionNodes.map(node => (
              <Option key={node.uuid} value={node.uuid}>
                {node.question?.name}
              </Option>
            ))}
          </Select>
          <Text style={{ fontSize: '12px', color: ColorTextSecondary }}>
            1行フォーマットのテキスト質問のみ選択できます。
            <br />
            繰り返しセクションにある質問は選択できません。
          </Text>
          {templateReportNameRuleErrorMessage && (
            <ErrorMessageText id="error-message">
              {templateReportNameRuleErrorMessage}
            </ErrorMessageText>
          )}
        </ReportNamingRulesQuetionSelectContainer>
      )}
    </BasicRow>
  )
}

export default ReportNamingRuleRow
