import { logEvent } from 'firebase/analytics'
import { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { firebaseAnalytics } from 'src/features/firebase/firebase'
import { RootState } from 'src/state/store'
import { SanmaPromoBanner } from './SanmaPromoteBanner'
import { useSanmaPromoBanner } from './useSanmaPromoteBanner'

type Props = {
  children: ReactNode
}

export const SanmaPromoBannerWrapper = ({ children }: Props) => {
  const [showPromoBanner, setShowPromoBanner] = useState(false)
  const isPromoBannerEnabled = useSanmaPromoBanner()
  const user = useSelector((state: RootState) => state.usersState.user.user)

  useEffect(() => {
    const storedState = localStorage.getItem(localStorageKeys.showPromoBanner)
    if (storedState !== null) {
      setShowPromoBanner(JSON.parse(storedState))
    } else {
      setShowPromoBanner(true)
    }
  }, [])

  if (!isPromoBannerEnabled) {
    return <>{children}</>
  }

  return (
    <>
      {showPromoBanner && (
        <SanmaPromoBanner
          onClose={() => {
            localStorage.setItem(
              localStorageKeys.showPromoBanner,
              JSON.stringify(false),
            )
            setShowPromoBanner(false)
            logEvent(firebaseAnalytics, 'sanmaBannerClose', {
              company_id: user?.company.id,
              user_id: user?.id,
            })
          }}
        />
      )}
      {children}
    </>
  )
}
