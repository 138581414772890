import { appEnv } from 'src/util/appEnv'

const localNodeUUIDs = [
  'd881c101-42de-4f13-a699-eb7cde673296',
  'b612b88b-9340-47c5-ba49-1ec64ba28d5f',
]

const devNodeUUIDs = [
  'bf75ed28-fec7-4a28-8a77-39f3d64e712c',
  '19c1441f-8266-46f3-b71a-9d63110ef532',
]

const prodNodeUUIDs = [
  //--------------- company_id: 93 ---------------//
  // ページ: 野菜殺菌記録
  // 質問: 殺菌濃度(20-30ppm)
  '136d9a1b-1a06-42b7-8a16-85961138f14d',
  // 質問: 殺菌水ph(5.0-6.5)
  'ddff4038-beef-4e0c-89e5-bd083fd2ec61',

  //--------------- company_id: 8 ---------------//
  // ページ: 投入5㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  '3a14fa3a-22c4-4996-876a-ed0dbe224245',
  // 質問: 開始時pH（5.0〜6.5）
  '39514921-3631-4425-b95a-0098dffde5f4',
  // 質問: 終了時塩素濃度（20ppm以上）
  '90238e6c-21c9-41bc-98cd-68680328aadd',
  // 質問: 終了時pH（5.0〜6.5）
  '76ba93ec-e25c-41d7-9b80-f36031f94509',
  // ページ: 投入6㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  '084429cd-8c7e-406d-9f05-27cd70f5b61f',
  // 質問: 開始時pH（5.0〜6.5）
  'db4d2ddf-c3de-4631-b411-12d540d4afe4',
  // 質問: 終了時塩素濃度（20ppm以上）
  '0e7dbefa-b5e1-48a9-8c45-423d8915223a',
  // 質問: 終了時pH（5.0〜6.5）
  '1978ba8f-c557-493f-b094-0b01511a0499',
  // ページ: 投入6㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  'b7fbfe0e-4892-4d78-91db-77a3946db397',
  // 質問: 開始時pH（5.0〜6.5）
  'bbdbb4ce-f1c2-4b8d-beb9-103f16eb8568',
  // 質問: 終了時塩素濃度（20ppm以上）
  '53046625-5da9-499b-bab1-9686c4556552',
  // 質問: 終了時pH（5.0〜6.5）
  '2ec33669-8f0b-4072-8693-e5388dcbedcc',
  // ページ: 投入6㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  'b2c2d3a5-dcad-45a0-b1b9-1614bbf1e9e7',
  // 質問: 開始時pH（5.0〜6.5）
  '36150815-308c-4a26-8684-4f0d4fb56430',
  // 質問: 終了時塩素濃度（20ppm以上）
  '0d25ef10-b14b-45da-a439-d42e56c13730',
  // 質問: 終了時pH（5.0〜6.5）
  'e3bf54b7-8ba9-4140-9f54-1dd7d6d42b4e',
  // ページ: 投入3㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  'bfe24d27-60af-48df-bd69-8d54ba598927',
  // 質問: 開始時pH（5.0〜6.5）
  'c94f76e8-e59d-458a-a34c-0d82b1fc7ea7',
  // 質問: 終了時塩素濃度（20ppm以上）
  '1312fea4-64e4-4bb6-928f-44940b269ca8',
  // 質問: 終了時pH（5.0〜6.5）
  '7adcb7f2-21d4-4f9b-897f-f974838db20b',
  // ページ: 投入3㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  '067f89d4-ee16-4255-b7c8-a84afdf6470c',
  // 質問: 開始時pH（5.0〜6.5）
  'f7193dd0-39c2-484c-a94c-8cf25ac8ee82',
  // 質問: 終了時塩素濃度（20ppm以上）
  'bc8b1f0a-02a0-4e29-9764-3a426e93122e',
  // 質問: 終了時pH（5.0〜6.5）
  '514d2382-d8f1-4252-bcc0-f6d09b40c9e2',
  // ページ: 投入6㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  '194cccc4-f805-4f5d-9879-72a325b64d77',
  // 質問: 開始時pH（5.0〜6.5）
  '2c6ba7ab-beca-44c9-8ee1-a63e8b54f371',
  // 質問: 終了時塩素濃度（20ppm以上）
  '5b01353c-ae44-40e5-83bf-828f3bf6d6ad',
  // 質問: 終了時pH（5.0〜6.5）
  '1d3511e0-d90d-4255-b077-d86ac6270f03',

  // ひな形: エラー無し（3便）弁釜作成【IoT検証用】生食野菜殺菌記録
  // ページ: 投入6㎏/時間5分
  // 質問: 開始時塩素濃度（20ppm以上）
  'b5ec9bb5-1242-4348-a878-f5c70c4ca386',
  // 質問: 開始時pH（5.0〜6.5）
  'e7829f43-0572-4071-81d1-467ac85024b1',
  // 質問: 終了時塩素濃度（20ppm以上）
  '0cd83d87-634a-4403-9a6a-ae2fa0709e21',
  // 質問: 終了時pH（5.0〜6.5）
  'eb86ced1-5e17-4426-85ea-49bcd3308bf6',
]

// IoT 機器の読み取りボタンの表示対象の場合、 true を返す
export function isIoT(nodeUUID: string | undefined): boolean {
  let nodeUUIDs: string[]
  if (appEnv.isLocal) {
    nodeUUIDs = localNodeUUIDs
  } else if (appEnv.isDevelopment) {
    nodeUUIDs = devNodeUUIDs
  } else if (appEnv.isProduction) {
    nodeUUIDs = prodNodeUUIDs
  } else {
    nodeUUIDs = []
  }

  return nodeUUID && nodeUUIDs.includes(nodeUUID) ? true : false
}
