import { queryKeyPrefix } from 'src/features/tanStackQuery/queryKeyPrefix'

const prefix = queryKeyPrefix.common

/**
 * 複数の画面をまたいで共通的に利用するデータ (e.g. ユーザー情報やマスタデータなど) をここで管理する
 */
export const commonQueryKeys = {
  /**
   * ユーザーが所属する会社に存在するすべての現場ノード（現場および現場グループ）
   */
  allPlaceNodes: [...prefix, 'allPlaceNodes'],
  /**
   * ユーザーコンテキスト (ユーザーID、表示名、ユーザーロール、会社ID、会社名、契約プランなど)
   */
  loginUserContext: [...prefix, 'loginUserContext'],
  /**
   * ユーザーが直接的・間接的に所属する現場ノードの一覧
   */
  userPlaceNodes: [...prefix, 'userPlaceNodes'],
}
