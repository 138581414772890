import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleEditable } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import OptionToggleSwitch from './OptionToggleSwitch'

const EditableSwitch: FC = () => {
  const dispatch = useDispatch()
  const isEditable = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isEditable,
    shallowEqual,
  )
  const templateApprovalFlowId = useSelector(
    (state: RootState) => state.templatesState.editTemplate.approvalFlowId,
  )

  return (
    <OptionToggleSwitch
      isChecked={templateApprovalFlowId ? isEditable : true}
      onChange={checked => dispatch(toggleEditable(checked))}
      label="レポート完了後の編集可"
    />
  )
}

export default EditableSwitch
