import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleTenkey } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import OptionToggleSwitch from './OptionToggleSwitch'

const KeyboardSwitch: FC = () => {
  const dispatch = useDispatch()
  const isKeyboard = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isKeyboard,
    shallowEqual,
  )

  return (
    <OptionToggleSwitch
      isChecked={isKeyboard}
      onChange={checked => dispatch(toggleTenkey(checked))}
      label="数値回答で標準キーボードを使う"
    />
  )
}

export default KeyboardSwitch
