import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  MultipleChoice,
  ResponseEmployeeSubTypeEnum,
  ResponseTypeEnum,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Checkbox, Tooltip } from 'antd'
import React, { useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { ClampText } from 'src/components/clampText/ClampText'
import { getRelatedExcelConversionFlowInSection } from 'src/features/templateEdit/common/getRelatedExcelConversionFlow'
import {
  DragItem,
  HoveringPosition,
  MoveNodeEvent,
} from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import { DnDUtils } from 'src/features/templateEdit/dragAndDrop/DnDUtils'
import {
  copyNodeParams,
  copyPageParams,
} from 'src/features/templateEdit/section/CopySection'
import {
  CopyIconColor,
  DeleteIconColor,
} from 'src/features/theme/KdsThemeColor'
import {
  addSectionToPage as addSectionToPageAction,
  addSectionToSection as addSectionToSectionAction,
  changeActiveNodeId as changeActiveNodeIdAction,
  changeSectionToNext as changeSectionToNextAction,
  deleteTemplateNode as deleteTemplateNodeAction,
  moveTemplateNode as moveTemplateNodeAction,
  updateTemplateNode as updateTemplateNodeAction,
} from 'src/state/ducks/templates/actions'
import templatesSelectors from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import { createNode } from '../common/createNode'
import AddTemplateModifyButtonGroup from '../itemAppender/AddTemplateModifyButtonGroup'
import SelectNodeEditor from '../question/SelectNodeEditor'
import {
  BasicTableCell,
  CheckBoxText,
  ColumnText,
  ConfirmModalContentList,
  DragHandle,
  DraggingPlaceholder,
  HandleIcon,
  SectionContentWrapper,
  SectionNameParagraph,
  SectionNameParagraphWrapper,
  SectionOpenButtonWrapper,
  SectionTableCell,
  SectionWrapper,
  Table,
} from './Section.dumb'
import SectionContent from './SectionContent'

interface Props {
  node: TemplateNodeSchema
  isActive: boolean
  parentNode: TemplateNodeSchema | null
  path: number[]
}

const Section = (props: Props) => {
  const dispatch = useDispatch()

  const {
    editTemplate: { isShowScore, isAudit, excelConversionFlows },
    templatePages: { activePageId, activeNodeId, templatePages, templateNodes },
  } = useSelector((state: RootState) => state.templatesState)

  const changeActiveNodeId = (nextActiveNodeId: number) =>
    dispatch(changeActiveNodeIdAction(nextActiveNodeId))

  const updateTemplateNode = (nodeId: number, changeNode: TemplateNodeSchema) =>
    dispatch(
      // 以下のケースで利用されている
      // - セクション名入力欄にセクション名を入力する
      // - セクションを繰り返すかどうかを設定する
      // - セクションを従業員チェックタイプに設定する
      updateTemplateNodeAction(nodeId, changeNode),
    )

  const changeSectionToNext = (nodeId: number, node: TemplateNodeSchema) => {
    dispatch(changeSectionToNextAction(nodeId, node))
  }

  const deleteTemplateNode = (
    nodeId: number,
    parentNode: TemplateNodeSchema | null,
  ) => {
    dispatch(deleteTemplateNodeAction(nodeId, parentNode))
  }

  const copySectionToNode = (
    originalNodeId: number,
    copiedNode: TemplateNodeSchema,
    childNodes: { [key: number]: TemplateNodeSchema },
    parentNodeId: number,
  ) => {
    dispatch(
      addSectionToSectionAction(
        originalNodeId,
        copiedNode,
        childNodes,
        parentNodeId,
      ),
    )
  }

  const copySectionToPage = (
    originalNodeId: number,
    copiedNode: TemplateNodeSchema,
    childNodes: { [key: number]: TemplateNodeSchema },
    parentPageId: number,
  ) => {
    dispatch(
      addSectionToPageAction(
        originalNodeId,
        copiedNode,
        childNodes,
        parentPageId,
      ),
    )
  }

  const useTemplateNodeNodes = (templateNode: TemplateNodeSchema) =>
    templatesSelectors.useTemplateNodeNodes(templateNode)

  // 隣り合うセクション同士を並べ替えたときに発動するっぽい
  const moveNode = (event: MoveNodeEvent) => {
    const isSameParent =
      (!event.dragNodeParent && !event.dropNodeParent) ||
      (!!event.dragNodeParent &&
        !!event.dropNodeParent &&
        event.dragNodeParent.id === event.dropNodeParent.id)
    dispatch(moveTemplateNodeAction(event, isSameParent))
  }

  const sumSectionScore = (targetNode: TemplateNodeSchema): number => {
    // 子要素が存在しない場合に再帰を終了して値を返却
    if (targetNode.nodes.length === 0) return 0
    const childNodes = targetNode.nodes
      .map((id: number) => templateNodes[id])
      .filter(isDefined)
    const questionSumScore = childNodes
      .filter(thisNode => thisNode.type === TemplateNodeTypeEnum.Question)
      .map(thisNode => {
        const multipleChoices = thisNode.question?.responseMultipleChoices ?? []
        if (multipleChoices.length === 0) return 0
        const maxScore = (multipleChoices[0]?.responses ?? [])
          .filter((m: MultipleChoice) => !m.isExcludedFromScoring)
          .map(response => response.score)
          .reduce((prev, cur) => Math.max(prev, cur), 0)
        return maxScore
      })
      .reduce((prev, cur) => prev + cur, 0)
    return (
      questionSumScore +
      childNodes
        .map(thisNode => sumSectionScore(thisNode))
        .reduce((prev, cur) => prev + cur, 0)
    )
  }

  const addTemplateNode = (
    targetNode: TemplateNodeSchema | null,
    parentNode: TemplateNodeSchema | null,
    position: 'top' | 'bottom',
    addTemplateNodeType: TemplateNodeTypeEnum,
  ) => {
    createNode(templateNodes, dispatch, {
      targetNode,
      parentNode,
      position,
      addTemplateNodeType,
      question: targetNode?.question,
    })
  }

  const copySection = (
    nodeId: number,
    nodeUUID: string,
    parentNode: TemplateNodeSchema | null,
  ) => {
    if (parentNode) {
      const { copiedNode, flattenChildren } = copyNodeParams(
        templateNodes,
        nodeUUID,
      )
      if (copiedNode && flattenChildren) {
        copySectionToNode(nodeId, copiedNode, flattenChildren, parentNode.id)
      }
    } else {
      const { copiedNode, flattenChildren } = copyPageParams(
        templateNodes,
        nodeUUID,
      )
      if (copiedNode && flattenChildren) {
        copySectionToPage(nodeId, copiedNode, flattenChildren, activePageId)
      }
    }
  }

  const [isOpen, setIsOpen] = useState(true)

  const ref = useRef<HTMLDivElement>(null)
  const [hoverPosition, setHoverPosition] =
    useState<HoveringPosition>(undefined)

  const [{ isOver }, drop] = useDrop({
    accept: 'NODE',
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
    hover: (_: DragItem, monitor: DropTargetMonitor) => {
      if (monitor.isOver({ shallow: true })) {
        const position = DnDUtils.getHoveringPosition(ref, monitor)
        if (hoverPosition !== position) {
          setHoverPosition(position)
        }
      } else {
        setHoverPosition(undefined)
      }
    },
    drop: (dragingItem: DragItem, monitor: DropTargetMonitor) => {
      const dragNode = dragingItem.node
      if (dragNode.id === props.node.id) {
        return
      }
      const position = DnDUtils.getHoveringPosition(ref, monitor)
      const event: MoveNodeEvent = {
        dragNode: dragNode,
        dropNode: props.node,
        dropPosition: position,
        dragNodeParent: dragingItem.parentNode,
        dropNodeParent: props.parentNode,
      }
      moveNode(event)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'NODE',
    item: { node: props.node, parentNode: props.parentNode },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drop(ref)

  if (isDragging) {
    return <></>
  }

  const isShownDragHandle = !isOpen || props.node.nodes.length === 0

  const showEmployeeCheck = () => {
    const firstPage = Object.values(templatePages)[0]
    if (!firstPage || firstPage.id !== activePageId) {
      return false
    }
    const sectionNodes = Object.values(templateNodes).filter(
      node => node.type === TemplateNodeTypeEnum.Section,
    )
    const employeeCheckNode = sectionNodes.find(
      node => node.section && node.section.isEmployeeCheck,
    )
    if (employeeCheckNode) {
      return employeeCheckNode.id === props.node.id
    }
    const activePage = templatePages[activePageId]
    const activePageNodes = activePage?.nodes ?? []
    let nodeId = 0
    for (const pageNodeId of activePageNodes) {
      if (sectionNodes.some(node => node.id === pageNodeId)) {
        nodeId = pageNodeId
        break
      }
    }
    return nodeId === props.node.id
  }

  return (
    <div key={props.node.id} data-testid="section">
      <AddTemplateModifyButtonGroup
        isOpen={props.isActive}
        node={props.node}
        position="top"
        parentNode={props.parentNode}
        addTemplateNode={addTemplateNode}
      />
      <div ref={ref} data-testid="section-bar">
        {isOver && hoverPosition === 'TOP' && <DraggingPlaceholder />}
        <Table onClick={() => changeActiveNodeId(props.node.id)}>
          <SectionTableCell theme={{ width: '100%' }}>
            <SectionWrapper ref={preview}>
              <SectionOpenButtonWrapper>
                <Button
                  shape="circle"
                  icon={isOpen ? <DownOutlined /> : <RightOutlined />}
                  onClick={() => {
                    setIsOpen(!isOpen)
                  }}
                />
              </SectionOpenButtonWrapper>
              {isShownDragHandle && !props.node.section?.isEmployeeCheck && (
                <DragHandle ref={drag}>
                  <HandleIcon />
                </DragHandle>
              )}
              <SectionNameParagraphWrapper>
                {props.isActive ? (
                  <SelectNodeEditor
                    onChangeText={(text: string) =>
                      changeSectionToNext(props.node.id, {
                        ...props.node,
                        section: { ...props.node.section, name: text },
                      })
                    }
                    onUpdate={(text: string) =>
                      updateTemplateNode(props.node.id, {
                        ...props.node,
                        section: { ...props.node.section, name: text },
                      })
                    }
                    name={props.node.section?.name ?? ''}
                    node={props.node}
                  />
                ) : (
                  <SectionNameParagraph>
                    <ClampText
                      style={{
                        color: 'white',
                        // `If you want to have responsive ellipsis, please set width manually`とのこと
                        // https://ant.design/components/typography
                        width: 'calc(100vw - 700px)',
                      }}
                    >
                      {props.node.section?.name ?? ''}
                    </ClampText>
                  </SectionNameParagraph>
                )}
              </SectionNameParagraphWrapper>
              <Checkbox
                style={{ marginLeft: '8px' }}
                disabled={props.node.section?.isEmployeeCheck === 1}
                checked={props.node.section?.isRepeat === 1}
                onClick={() => {
                  updateTemplateNode(props.node.id, {
                    ...props.node,
                    section: {
                      ...props.node.section,
                      isRepeat:
                        (props.node.section?.isRepeat ?? 0) === 0 ? 1 : 0,
                    },
                  })
                }}
              >
                <CheckBoxText>このセクションを繰り返す</CheckBoxText>
              </Checkbox>
              {showEmployeeCheck() && !isAudit && (
                <Checkbox
                  style={{ marginLeft: '8px' }}
                  checked={props.node.section?.isEmployeeCheck === 1}
                  // セクション直下の最初のノードとして、従業員タイプの質問ノードを追加または削除する
                  onClick={() => {
                    const isEmployeeCheck =
                      props.node.section?.isEmployeeCheck ?? 0
                    updateTemplateNode(props.node.id, {
                      ...props.node,
                      section: {
                        ...props.node.section,
                        isEmployeeCheck: isEmployeeCheck === 0 ? 1 : 0,
                        isRepeat:
                          isEmployeeCheck === 0
                            ? 1
                            : props.node.section?.isEmployeeCheck,
                      },
                    })
                    if (isEmployeeCheck === 0) {
                      // ここは従業員タイプの質問ノードを追加する場合のブロック
                      const nodeId = props.node.nodes[0]
                      const childNode =
                        nodeId === undefined ? undefined : templateNodes[nodeId]

                      if (
                        !childNode ||
                        (childNode.type === TemplateNodeTypeEnum.Question &&
                          childNode.question?.responseType !==
                            ResponseTypeEnum.EMPLOYEE)
                      ) {
                        // セクション直下にノードがないときや、
                        // セクション直下の最初のノードが存在はするものの従業員タイプの質問ではないときは、
                        // 従業員タイプの質問ノードを先頭に追加する。
                        const responseType = ResponseTypeEnum.EMPLOYEE
                        const responseEmployees = [
                          {
                            subType: ResponseEmployeeSubTypeEnum.NAME,
                            isMultiple: 0,
                          },
                        ]
                        const targetNode = {
                          ...props.node,
                          question: {
                            ...props.node.question,
                            responseType: responseType,
                            responseEmployees: [...responseEmployees],
                            isRequired: 0,
                            isTimeDisplayed: 0,
                          },
                        }
                        addTemplateNode(
                          targetNode,
                          props.node,
                          'bottom',
                          TemplateNodeTypeEnum.Question,
                        )
                      } else {
                        // セクション直下の最初のノードが従業員タイプの質問ノードの場合には、値を上書きする。
                        // FIXME: セクション直下の最初のノードがセクションだった場合の考慮が漏れているっぽい
                        const responseEmployees = [
                          {
                            subType: ResponseEmployeeSubTypeEnum.NAME,
                            isMultiple: 0,
                          },
                        ]
                        updateTemplateNode(childNode.id, {
                          ...childNode,
                          question: {
                            ...childNode.question,
                            responseEmployees: [...responseEmployees],
                            isRequired: 0,
                            isTimeDisplayed: 0,
                          },
                        })
                      }
                    } else {
                      // ここは従業員タイプの質問ノードを削除する場合のブロック
                      const nodeId = props.node.nodes[0]
                      if (nodeId === undefined) return
                      const deleteNode = templateNodes[nodeId]
                      if (nodeId && deleteNode) {
                        deleteTemplateNode(deleteNode.id, deleteNode)
                      }
                    }
                  }}
                >
                  <CheckBoxText>従業員チェック</CheckBoxText>
                </Checkbox>
              )}
              {showEmployeeCheck() && isAudit && (
                <Tooltip
                  placement="top"
                  title="監査機能が有効なため、使用できません"
                >
                  <Checkbox
                    style={{ marginLeft: '8px' }}
                    checked={false}
                    disabled={true}
                  >
                    <CheckBoxText>従業員チェック</CheckBoxText>
                  </Checkbox>
                </Tooltip>
              )}
              <div>
                {!props.node.section?.isEmployeeCheck && (
                  <Button
                    type="link"
                    onClick={() => {
                      if (props.node.uuid) {
                        copySection(
                          props.node.id,
                          props.node.uuid,
                          props.parentNode,
                        )
                      }
                    }}
                  >
                    <CopyOutlined style={{ color: CopyIconColor }} />
                  </Button>
                )}
                <Button
                  type="link"
                  onClick={() => {
                    const content: Array<React.ReactNode> = []
                    if (props.node.nodes?.length) {
                      content.push(
                        '紐づく質問やセクションも一緒に削除されます。',
                      )
                    }
                    const flowNames = getRelatedExcelConversionFlowInSection(
                      props.node.id,
                      templateNodes,
                      excelConversionFlows,
                    )
                    if (flowNames.length > 0) {
                      content.push(
                        <>
                          <br />
                          <br />
                          以下のExcel変換設定に紐づいています。
                          <ConfirmModalContentList>
                            {flowNames.map((name, index) => (
                              <li key={index}>{name}</li>
                            ))}
                          </ConfirmModalContentList>
                        </>,
                      )
                    }
                    DeleteConfirmDialog({
                      title: 'セクションを削除してもよろしいですか？',
                      content,
                      onOk() {
                        deleteTemplateNode(props.node.id, props.parentNode)
                      },
                    })
                  }}
                >
                  <DeleteOutlined style={{ color: DeleteIconColor }} />
                </Button>
              </div>
            </SectionWrapper>
          </SectionTableCell>
          {isShowScore && (
            <BasicTableCell theme={{ minWidth: '90px' }}>
              <ColumnText>{sumSectionScore(props.node)}</ColumnText>
            </BasicTableCell>
          )}
        </Table>
        {!isOpen && isOver && hoverPosition === 'BOTTOM' && (
          <DraggingPlaceholder />
        )}
      </div>
      <SectionContentWrapper isOpened={isOpen} key={props.node?.id}>
        <SectionContent
          node={props.node}
          activeNodeId={activeNodeId}
          path={props.path}
          useTemplateNodeNodes={useTemplateNodeNodes}
          addTemplateNode={addTemplateNode}
          moveNode={moveNode}
        />
      </SectionContentWrapper>
      <AddTemplateModifyButtonGroup
        isOpen={props.isActive}
        node={props.node}
        position="bottom"
        parentNode={props.parentNode}
        addTemplateNode={addTemplateNode}
      />
    </div>
  )
}

export default Section
