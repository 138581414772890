import { RuleDefTypeEnum } from '@ulysses-inc/harami_api_client'
import { Row, Select, Switch } from 'antd'
import styled from 'styled-components'

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 25px;
`

export const RuleSwitchRow = styled(Row)`
  align-items: center;
`

export const RuleSwitch = styled(Switch)`
  &.ant-switch {
    margin-right: 12px;
  }
`

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const RuleDefTypeSelect = styled(Select<RuleDefTypeEnum>)`
  && {
    width: 184px;
  }
`
