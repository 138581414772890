import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import { SwitchProps } from 'antd/lib/switch'
import { FormikProps } from 'formik'
import { FC, useId } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { NumberRuleProps } from './EditResponseNumberRuleDrawer'
import { FormGroup } from './EditResponseNumberRuleDrawer.styled'
import {
  DecimalPointInputNumber,
  DecimalPointSwitch,
  FormGroupDecimalPointRow,
  FormGroupDecimalPointValue,
  FormGroupDecimalPointValueRow,
} from './FractionalDigitsRow.styled'

// NOTE: この 50 という上限はシステム保護のために後から設定されたものであり、
// 既存のデータで 50 より大きい有効な値がないことを確認できたため 50 としています。
export const MAX_NUMBER_FRACTIONAL_DIGITS = 50

type TSwitchValue = Parameters<Exclude<SwitchProps['onChange'], undefined>>[0]
type TInputValue = Parameters<
  Exclude<InputNumberProps['onChange'], undefined>
>[0]

const FractionalDigitsRow: FC<{
  formikProps: FormikProps<NumberRuleProps>
}> = ({
  formikProps: {
    values: { decimalPoint },
    setFieldValue,
    errors,
    touched,
  },
}) => {
  const onToggle = (checked: TSwitchValue) =>
    setFieldValue('decimalPoint.isActive', checked ? 1 : 0)
  const onChangeDigits = (value: TInputValue) =>
    setFieldValue('decimalPoint.value', Number(value))

  const decimalPointSwitchLabelId = useId()
  const decimalPointInputLabelId = useId()

  return (
    <FormGroup>
      <FormGroupDecimalPointRow>
        <DecimalPointSwitch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!decimalPoint?.isActive}
          onChange={onToggle}
          aria-labelledby={decimalPointSwitchLabelId}
        />
        <label id={decimalPointSwitchLabelId}>小数点以下の桁数を設定する</label>
      </FormGroupDecimalPointRow>
      <FormGroupDecimalPointValueRow>
        <FormGroupDecimalPointValue>
          <DecimalPointInputNumber
            name="decimalPoint"
            placeholder="1"
            min={0}
            max={MAX_NUMBER_FRACTIONAL_DIGITS}
            value={decimalPoint?.value}
            onChange={onChangeDigits}
            disabled={!decimalPoint?.isActive}
            aria-labelledby={decimalPointInputLabelId}
          />
          <label id={decimalPointInputLabelId}>桁</label>
        </FormGroupDecimalPointValue>
      </FormGroupDecimalPointValueRow>
      <Row>
        <div>
          <FormikErrorMessage
            name="decimalPoint"
            errors={errors}
            touched={touched}
          />
        </div>
      </Row>
    </FormGroup>
  )
}

export default FractionalDigitsRow
