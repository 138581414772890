import {
  FormulaValue,
  ResponseFormulaDecimalPoint,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { CellInner, TableBodyCellWrapper } from './Common.dumb'

type Props = {
  value?: FormulaValue
  scale?: string
  decimalPoint?: ResponseFormulaDecimalPoint
}

export const TableBodyCellFormula: FC<Props> = ({
  value,
  scale,
  decimalPoint,
}) => {
  const isInvalid = value?.isInvalid === 1

  return (
    <TableBodyCellWrapper
      backgroundColor={isInvalid ? 'invalid' : 'fixed'}
      fontColor={isInvalid ? 'invalid' : 'default'}
    >
      <CellInner>
        {convertDisplayFormula(value?.formulaValue, scale, decimalPoint)}
      </CellInner>
    </TableBodyCellWrapper>
  )
}

const convertDisplayFormula = (
  value: number | undefined,
  scale?: string,
  decimalPoint?: ResponseFormulaDecimalPoint,
) => {
  if (value === undefined) {
    return '未計算'
  }

  return convertDecimal(value, decimalPoint) + (scale ?? '')
}
