import type { LabelCheckValue } from '@ulysses-inc/harami_api_client'
import { Space } from 'antd'
import { useContext } from 'react'
import { Text } from 'src/components/nativeCompat/Text'
import { LabelCheckAnswerStoreContext } from 'src/modules/lobster/LobsterService/LabelCheckAnswerStoreContext'
import { ReportLabelSection } from './ReportLabelSection'

type Props = {
  labelCheckValue: LabelCheckValue
}

export const LabelCheckAnswer = ({ labelCheckValue }: Props) => {
  const { reportLabelInspectionId } = labelCheckValue

  const { reportLabelInspectionMap } = useContext(LabelCheckAnswerStoreContext)

  const reportLabelInspection = reportLabelInspectionMap.get(
    reportLabelInspectionId,
  )

  if (!reportLabelInspection) {
    return <></>
  }

  return (
    <Space direction="vertical" size="middle">
      <Text>{reportLabelInspection.name}</Text>
      <Space direction="vertical" size="middle">
        {reportLabelInspection.reportLabels.map(reportLabel => (
          <ReportLabelSection
            key={reportLabel.labelId}
            reportLabel={reportLabel}
          />
        ))}
      </Space>
    </Space>
  )
}
