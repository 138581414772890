// TODO リファクタ途中だが、上流のファイル群がきれいにならないとこれ以上きれいにできない
import {
  ApprovalFlow,
  ApprovalFlowStep,
  ApprovalStep,
  Assignee,
} from '@ulysses-inc/harami_api_client'
import { Badge, Button, Input, Typography } from 'antd'
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import date from 'src/exShared/util/date'
import { ApprovalStepBadgeColor, Gray } from 'src/features/theme/KdsThemeColor'
import {
  addApprovalStep as addApprovalStepAction,
  deleteApproval as deleteApprovalAction,
  getApproval,
  resetApproval,
} from 'src/state/ducks/approvals/actions'
import { RootState } from 'src/state/store'
import Loading from '../../components/loading/Loading'
import {
  ApprovalButtons,
  ApprovalFlowStepContainer,
  ApprovalTime,
  ApproverName,
  ApproverNameDisabled,
  ApproverWithStatusRow,
  IconApproved,
  StatusTextApproved,
  StatusTextPending,
} from './Approvals.components'
import ApprovalsModal from './ApprovalsModal'

interface OwnProps {
  reportUUID: string
  isReport: boolean
  approvalUUID: string
  approvalFlow: ApprovalFlow
  header: ReactNode
  onApproval: () => void
  onRevert?: () => void
  isDisabled?: boolean
  isRemanded?: boolean
  isInCompleted?: boolean
  assignees: Assignee[]
  reportName: string
  placeName: string
  reportDate: Date
  remandedUserName: string
}

const { TextArea } = Input

// 承認済みの場合
const renderApproved = (name = '', time: string) => (
  <div key={Math.random()}>
    <ApproverWithStatusRow>
      <IconApproved />
      <ApproverName>{name}</ApproverName>
      <StatusTextApproved>承認済み</StatusTextApproved>
    </ApproverWithStatusRow>
    <ApprovalTime>{time}</ApprovalTime>
  </div>
)

const renderApprovedDisabled = (name = '') => (
  <div key={Math.random()}>
    <ApproverWithStatusRow>
      <ApproverNameDisabled>{name}</ApproverNameDisabled>
    </ApproverWithStatusRow>
  </div>
)

// 承認待ちの場合（ただしなぜか2人目以降の承認者のみに適用される模様）
const renderApprovalPending = (count: number, name = '') => (
  <div key={Math.random()}>
    <ApproverWithStatusRow>
      <Badge
        count={count}
        style={{ backgroundColor: ApprovalStepBadgeColor }}
      />
      <ApproverName>{name}</ApproverName>
      <StatusTextPending>承認待ち</StatusTextPending>
    </ApproverWithStatusRow>
  </div>
)

const RenderApproval: React.FC<{
  count: number
  name: string | undefined
  isRemanded: boolean | undefined
  isInCompleted: boolean | undefined
  isDisabled: boolean | undefined
  setIsApproveDialog: Dispatch<SetStateAction<boolean>>
  setIsRemandDialog: Dispatch<SetStateAction<boolean>>
  setComment: Dispatch<SetStateAction<string>>
}> = ({
  count,
  name,
  isDisabled,
  isInCompleted,
  isRemanded,
  setIsApproveDialog,
  setIsRemandDialog,
  setComment,
}) => {
  // 承認、差し戻しボタンの制御
  // 未完了の場合、どちらも表示せず、未完了のため承認できない旨を表示
  // 差し戻しの場合、どちらも表示せず、差し戻しのため承認できない旨を表示
  // 承認済みのステップではこの Component は表示されないため、ここでの制御は不要
  const visibleApprovalButton = !isInCompleted && !isRemanded

  return (
    <div key={Math.random()}>
      <ApproverWithStatusRow>
        <Badge count={count} style={{ backgroundColor: Gray }} />
        <ApproverName>{name}</ApproverName>
        {!isInCompleted && <StatusTextPending>{'承認待ち'}</StatusTextPending>}
      </ApproverWithStatusRow>
      <ApprovalButtons>
        {isInCompleted && (
          <Typography>レポートが未完了のため承認できません</Typography>
        )}
        {isRemanded && (
          <Typography>レポートが差し戻されているため承認できません</Typography>
        )}
        {visibleApprovalButton && (
          <>
            <Button
              onClick={() => setIsApproveDialog(true)}
              type="primary"
              disabled={isDisabled || isRemanded}
            >
              承認
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => {
                setComment('')
                setIsRemandDialog(true)
              }}
              disabled={isDisabled || isRemanded}
            >
              差し戻し
            </Button>
          </>
        )}
      </ApprovalButtons>
    </div>
  )
}

// *承認が完了していない*レポートに関する承認ステップの表示
const RenderApprovalFlowSteps: React.FC<{
  approvalFlowSteps: ApprovalFlowStep[]
  isDisabled: boolean | undefined
  isInCompleted: boolean | undefined
  isRemanded: boolean | undefined
  setComment: Dispatch<SetStateAction<string>>
  setIsApproveDialog: Dispatch<SetStateAction<boolean>>
  setIsRemandDialog: Dispatch<SetStateAction<boolean>>
}> = ({
  approvalFlowSteps,
  isDisabled,
  isInCompleted,
  isRemanded,
  setComment,
  setIsApproveDialog,
  setIsRemandDialog,
}) => {
  const loginUserUUID = localStorage.getItem(localStorageKeys.loginUserUuid)

  return (
    <>
      {approvalFlowSteps.map(({ id, approvers }, index) => {
        const count = index + 1

        return (
          <ApprovalFlowStepContainer key={id}>
            {(approvers ?? []).map(({ uuid, name }) => {
              if (index === 0 && uuid === loginUserUUID) {
                return (
                  <RenderApproval
                    key={`${uuid}:${name}`}
                    {...{
                      count,
                      name,
                      isDisabled,
                      isInCompleted,
                      isRemanded,
                      setComment,
                      setIsApproveDialog,
                      setIsRemandDialog,
                    }}
                  />
                )
              }

              return renderApprovalPending(count, name)
            })}
          </ApprovalFlowStepContainer>
        )
      })}
    </>
  )
}

// *承認済み*のレポートに関する承認ステップの表示
const RenderApprovalSteps: React.FC<{
  currentStep: ApprovalFlowStep
  approvalSteps: ApprovalStep[]
  isDisabled: boolean | undefined
  isInCompleted: boolean | undefined
  isRemanded: boolean | undefined
  setComment: Dispatch<SetStateAction<string>>
  setIsApproveDialog: Dispatch<SetStateAction<boolean>>
  setIsRemandDialog: Dispatch<SetStateAction<boolean>>
}> = ({
  currentStep,
  approvalSteps,
  isDisabled,
  isInCompleted,
  isRemanded,
  setComment,
  setIsApproveDialog,
  setIsRemandDialog,
}) => {
  const loginUserUUID = localStorage.getItem(localStorageKeys.loginUserUuid)

  const currentStepId = currentStep.id ?? 0

  return (
    <>
      {approvalSteps.map(
        ({ id, approvers, stepApprovers, stepApprovalTimes }, stepIndex) => {
          const isApprovedStep = !!(stepApprovers ?? []).length
          const stepCount = stepIndex + 1
          const stepId = id ?? 0

          return (
            <ApprovalFlowStepContainer key={stepId}>
              {(approvers ?? []).map(({ uuid, name }) => {
                // 既に承認済なら表示だけ
                if (isApprovedStep) {
                  const stepApprover = (stepApprovers ?? []).find(
                    ({ uuid: id }) => id === uuid,
                  )
                  const stepApprovalTime = (stepApprovalTimes ?? []).find(
                    ({ stepId: id }) => id === stepId,
                  )
                  if (stepApprover) {
                    return renderApproved(
                      name,
                      date.formatYYYYMMDDHHMM(stepApprovalTime?.createdAt),
                    )
                  }
                  return renderApprovedDisabled(name)
                }

                if (currentStepId === stepId && uuid === loginUserUUID) {
                  return (
                    <RenderApproval
                      key={`${uuid}:${name}`}
                      {...{
                        count: stepCount,
                        name,
                        isDisabled,
                        isInCompleted,
                        isRemanded,
                        setComment,
                        setIsApproveDialog,
                        setIsRemandDialog,
                      }}
                    />
                  )
                }

                return renderApprovalPending(stepCount, name)
              })}
            </ApprovalFlowStepContainer>
          )
        },
      )}
    </>
  )
}

const ApprovalsContainer: React.FC<OwnProps> = ({
  reportUUID,
  approvalUUID: originalApprovalUUID,
  approvalFlow,
  header,
  onApproval,
  onRevert,
  isDisabled,
  isRemanded,
  isInCompleted,
  assignees,
  reportName,
  placeName,
  reportDate,
  remandedUserName,
}) => {
  const [isRemandDialog, setIsRemandDialog] = useState<boolean>(false)
  const [isApproveDialog, setIsApproveDialog] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')

  const dispatch = useDispatch()

  const { steps, uuid } = useSelector(
    (state: RootState) => state.approvalsState.approval.approval,
    shallowEqual,
  )
  const isLoading = useSelector(
    ({
      approvalsState: {
        approval,
        deleteApproval,
        addApprovalStep,
        addApprovalReport,
      },
    }: RootState) =>
      approval.isLoading ||
      deleteApproval.isLoading ||
      addApprovalStep.isLoading ||
      addApprovalReport.isLoading,
    shallowEqual,
  )

  const approvalSteps = steps ?? []
  const isApproved = !!approvalSteps.length
  const approvalUUID = isApproved ? (uuid ?? '') : originalApprovalUUID

  useEffect(() => {
    dispatch(getApproval(approvalUUID))

    return () => {
      dispatch(resetApproval())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <div>
        {header}
        <Loading />
      </div>
    )
  }

  const approvalFlowSteps = approvalFlow.steps ?? []
  const approvalOrApprovalFlowSteps = isApproved
    ? approvalSteps.filter(({ stepApprovers }) => !stepApprovers?.length)
    : approvalFlowSteps
  const currentStep = approvalOrApprovalFlowSteps[0] ?? {}

  const url = window.location.href
  const deleteApproval = () =>
    dispatch(
      deleteApprovalAction({
        reportUUID,
        approvalFlowId: approvalFlow.id ?? 0,
        url,
        approvalUUID,
        comment,
        currentStep,
        firstStep: approvalFlowSteps[0] ?? {},
        assignees,
        reportName,
        placeName,
        reportDate,
        remandedUserName,
      }),
    )
  const addApprovalStep = () =>
    dispatch(
      addApprovalStepAction(
        reportUUID,
        approvalUUID,
        currentStep,
        approvalOrApprovalFlowSteps[1] ?? {},
        url,
      ),
    )

  const onSubmitApprovingModal = () => {
    setIsApproveDialog(false)
    if (isApproved) {
      addApprovalStep()
    } else {
      onApproval()
    }
  }
  const onSubmitRemandingModal = () => {
    setIsRemandDialog(false)
    deleteApproval()
    if (onRevert) {
      onRevert()
    }
  }

  return (
    <div>
      {header}
      {isApproved ? (
        <RenderApprovalSteps
          {...{
            currentStep,
            approvalSteps,
            isDisabled,
            isInCompleted,
            isRemanded,
            setComment,
            setIsApproveDialog,
            setIsRemandDialog,
          }}
        />
      ) : (
        <RenderApprovalFlowSteps
          {...{
            approvalFlowSteps,
            isDisabled,
            isInCompleted,
            isRemanded,
            setComment,
            setIsApproveDialog,
            setIsRemandDialog,
          }}
        />
      )}
      <ApprovalsModal
        title="承認しますか？"
        open={isApproveDialog}
        loading={!isApproveDialog}
        onCancel={() => setIsApproveDialog(false)}
        onSubmit={onSubmitApprovingModal}
      />
      <ApprovalsModal
        title="差し戻しますか？"
        open={isRemandDialog}
        loading={!isRemandDialog}
        disabledSubmit={comment === ''}
        content={
          <TextArea
            rows={4}
            placeholder="コメントを入力してください"
            onChange={e => setComment(e.target.value)}
            value={comment}
          />
        }
        onCancel={() => setIsRemandDialog(false)}
        onSubmit={onSubmitRemandingModal}
      />
    </div>
  )
}

export default ApprovalsContainer
