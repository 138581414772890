import { css } from '@emotion/react'
import { Space } from 'antd'
import { Text } from 'src/components/nativeCompat/Text'
import {
  ReportInspectionArea,
  reportInspectionAreaStatusColor,
  reportInspectionAreaStatusLabel,
} from '../../report-label-inspection'

type Props = {
  reportInspectionArea: ReportInspectionArea
}

export const ReportInspectionAreaSection = ({
  reportInspectionArea: area,
}: Props) => {
  return (
    <Space
      key={area.labelInspectionAreaId}
      css={styles.reportLabelInspectionAreaSection}
    >
      <Text
        style={{
          color: reportInspectionAreaStatusColor[area.status],
          fontWeight: 'bold',
        }}
      >
        {reportInspectionAreaStatusLabel[area.status]}
      </Text>
      <Text>{area.name}</Text>
    </Space>
  )
}

const styles = {
  reportLabelInspectionAreaSection: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
}
