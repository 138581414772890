import { CaretRightOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import styled from 'styled-components'
import backgroundSvg from './bg.png'

const StyledConversionButton = styled(Button)`
  background-color: #ffd324;
  font-size: 14px;
  font-weight: 700;
  margin-left: 16px;
  border: none;
  color: #000000 !important;

  &:hover {
    background-color: #e4b700 !important;
  }
`

type Props = {
  onClose: () => void
}

const { Text } = Typography
const TextStyle = {
  color: 'white',
  fontWeight: 700,
  fontFamily: 'Noto Sans JP',
}
export const SanmaPromoBanner = ({ onClose }: Props) => {
  return (
    <>
      <div
        style={{
          height: '54px',
          display: 'flex',
          alignItems: 'center',
          padding: '0px 16px 0px 24px',
          backgroundImage: `url(${backgroundSvg})`,
          backgroundColor: '#0360E5',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Text
          style={{
            ...TextStyle,
            fontSize: '14px',
            border: '1px solid white',
            borderRadius: '50px',
            padding: '2px 12px',
          }}
        >
          新製品
        </Text>
        <Text style={{ ...TextStyle, fontSize: '19px' }}>
          「動画マニュアル・研修システム」「設備保全システム」を提供開始
        </Text>
        <StyledConversionButton
          onClick={() =>
            window.open(
              'https://lp.kaminashi.jp/training-cmms-inquiry_202502',
              '_blank',
            )
          }
        >
          詳細はこちら
          <CaretRightOutlined />
        </StyledConversionButton>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          type="link"
          style={{ marginLeft: '8px' }}
          onClick={onClose}
          icon={
            <div
              style={{
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloseOutlined style={{ color: '#FFFFFF', fontSize: '18px' }} />
            </div>
          }
        ></Button>
      </div>
    </>
  )
}
