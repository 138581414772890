import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import {
  Employee,
  GetEmployeesRequest,
  PlaceNode,
  PlaceNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Divider, Input, Select } from 'antd'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { getUniqueFlattenNodes } from 'src/exShared/util/place/getUniqueFlattenNodes'
import { flattenNodes } from 'src/exShared/util/place/placeNode'
import { UUID } from 'src/exShared/util/uuid'
import Yup from 'src/features/validation/yup'
import {
  addEmployee as addEmployeeAction,
  changeIsShowEditEmployeeDrawer as changeIsShowEditEmployeeDrawerAction,
  getEmployees as getEmployeesAction,
  updateEmployee as updateEmployeeAction,
} from 'src/state/ducks/employees/actions'
import { GetEmployeesFilter } from 'src/state/ducks/employees/types'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import {
  EditFormControl,
  EditFormLabel,
  EmployeePageHeader,
} from './EditEmployee.dumb'

const validationSchema = Yup.object().shape({
  name: Yup.string().required().label('従業員名'),
  employeeCode: Yup.string().required().label('従業員管理番号'),
})

const { Option } = Select

interface EmployeeForm {
  id?: number
  uuid?: string
  name: string
  nameKana?: string
  employeeCode: string
  placeNodeIds: string[]
}

const EditEmployee: React.FC = () => {
  const { activeEmployee, isLoading, request, filter } = useSelector(
    ({ employeesState: { employees } }: RootState) => {
      const {
        activeEmployeeUUID,
        isLoading,
        request,
        filter,
        employees: data,
      } = employees
      return {
        activeEmployee: activeEmployeeUUID
          ? data.find(({ uuid }) => uuid === activeEmployeeUUID)
          : undefined,
        isLoading,
        request,
        filter,
      }
    },
  )

  const placeNodes = useSelector(
    ({ placesState: { places, placeGroups } }: RootState) =>
      getUniqueFlattenNodes([
        ...flattenNodes(placeGroups.nodes ?? []),
        ...(places.places ?? []),
      ]),
  )

  const dispatch = useDispatch()
  const updateEmployee = (
    employee: Employee,
    isEdit: boolean,
    request: GetEmployeesRequest,
    filter?: GetEmployeesFilter,
  ) => {
    if (!isEdit) {
      dispatch(addEmployeeAction(employee))
    } else {
      if (employee.uuid !== undefined) {
        dispatch(updateEmployeeAction(employee.uuid, employee))
      }
    }
    dispatch(getEmployeesAction(request, filter))
  }
  const changeIsShowEditEmployeeDrawer = () =>
    dispatch(changeIsShowEditEmployeeDrawerAction(false))

  const { handleChange, handleSubmit, values, setFieldValue, errors, touched } =
    useFormik<EmployeeForm>({
      initialValues: {
        id: activeEmployee?.id,
        uuid: activeEmployee?.uuid ?? UUID(),
        name: activeEmployee?.name ?? '',
        nameKana: activeEmployee?.nameKana ?? '',
        employeeCode: activeEmployee?.employeeCode ?? '',
        placeNodeIds: activeEmployee?.placeNodes?.map(({ uuid }) => uuid) ?? [],
      },
      validationSchema: validationSchema,
      onSubmit: (form: EmployeeForm) =>
        updateEmployee(
          {
            ...form,
            name: form.name,
            nameKana: form.nameKana,
            employeeCode: form.employeeCode,
            placeNodes:
              form.placeNodeIds
                .map(strId => placeNodes.find(({ uuid }) => uuid === strId))
                .filter((v): v is PlaceNode => !!v) ?? [],
          },
          activeEmployee?.uuid === form.uuid,
          request,
          filter,
        ),
    })

  const renderInput = (
    key: keyof Omit<EmployeeForm, 'placeNodeIds'>,
    label: string,
  ) => (
    <EditFormControl>
      <EditFormLabel>{label}</EditFormLabel>
      <Input
        onChange={handleChange(key)}
        defaultValue={activeEmployee?.[key] ?? ''}
        value={values[key]}
      />
      <FormikErrorMessage name={key} errors={errors} touched={touched} />
    </EditFormControl>
  )

  const filterOption = (inputValue: string, option: any) => {
    const optionText = option.children.props.children[1].props.children // アイコンを除いたテキストを取得する
    return optionText?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }

  return (
    <>
      <EmployeePageHeader>
        {activeEmployee ? '従業員の編集' : '従業員の追加'}
      </EmployeePageHeader>
      {renderInput('name', '従業員名')}
      {renderInput('nameKana', '従業員名（フリガナ）')}
      {renderInput('employeeCode', '従業員管理番号')}
      <Divider />
      <EditFormControl>
        <EditFormLabel>所属する現場</EditFormLabel>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          optionFilterProp="children"
          filterOption={filterOption}
          onChange={placeNodeIds => setFieldValue('placeNodeIds', placeNodeIds)}
          defaultValue={
            activeEmployee?.placeNodes?.map(({ uuid }) => uuid) ?? []
          }
        >
          {placeNodes.map(({ uuid, type, place, placeGroup }) => (
            <Option key={uuid} value={uuid}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 8 }}>
                  {type === PlaceNodeTypeEnum.PlaceGroup ? (
                    <TagOutlined />
                  ) : (
                    <HomeOutlined />
                  )}
                </div>
                <span>
                  {type === PlaceNodeTypeEnum.PlaceGroup
                    ? placeGroup?.name
                    : place?.name || ''}
                </span>
              </div>
            </Option>
          ))}
        </Select>
      </EditFormControl>

      <FooterButton
        spinning={isLoading}
        onCancel={changeIsShowEditEmployeeDrawer}
        onSubmit={() => handleSubmit()}
      />
    </>
  )
}

export default EditEmployee
