import { useCallback } from 'react'
import { getReportLabelInspections } from '../../api/report-label-inspection'

export const useInitializeLabelCheck = () => {
  /**
   * レポートの検査結果のリストを使用して、Lobsterにラベル検査結果を取得しにいく。
   * その結果をContextに保存する。
   */
  const initializeLabelCheck = useCallback(
    async (reportLabelInspectionIds: string[]) => {
      if (reportLabelInspectionIds.length === 0) {
        return
      }

      const reportLabelInspections = await getReportLabelInspections(
        reportLabelInspectionIds,
      )
      if (reportLabelInspections instanceof Error) {
        throw reportLabelInspections
      }

      if (reportLabelInspections.length === 0) {
        return
      }

      const reportLabelInspectionMap = new Map(
        reportLabelInspections.map(reportLabelInspection => [
          reportLabelInspection.id,
          reportLabelInspection,
        ]),
      )

      return reportLabelInspectionMap
    },
    [],
  )
  return { initializeLabelCheck }
}
