import {
  ResponseNumber,
  ResponseNumberDecimalPoint,
  ResponseNumberSubTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Form, Row } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { ButtonGroup } from './EditResponseNumberRuleDrawer.styled'
import FractionalDigitsRow from './FractionalDigitsRow'
import NormalValueRow from './NormalValueRow'

interface OwnProps {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  response?: ResponseNumber
  onClose: () => void
}

export type NumberRuleProps = {
  hasNumberRule: boolean
  minimum?: number
  maximum?: number
  scale?: string
  subType?: ResponseNumberSubTypeEnum
  decimalPoint?: ResponseNumberDecimalPoint
}

const EditResponseNumberRuleDrawerContainer: FC<OwnProps> = ({
  response,
  questionNode,
  onClose,
  isOpen,
}) => {
  const dispatch = useDispatch()

  // 数値タイプの質問にルールを設定する
  const changeResponseNumberRule = (
    node: TemplateNodeSchema,
    scale: string,
    newResponse: Pick<
      ResponseNumber,
      'minimum' | 'maximum' | 'subType' | 'decimalPoint'
    >,
  ) => {
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: {
          ...node.question,
          scale: scale,
          responseNumbers: [newResponse],
        },
      }),
    )
  }

  // subType が 0 である場合にもルールなしの判定としたいが、
  // 0 は Enum に定義されておらず型エラーが発生するため、falsy かどうかで判定する
  const hasNumberRule = !!response?.subType

  const initialValues: NumberRuleProps = {
    hasNumberRule,
    minimum: response?.minimum,
    maximum: response?.maximum,
    scale: questionNode.question?.scale,
    subType: response?.subType,
    decimalPoint: {
      value: response?.decimalPoint?.value,
      isActive: response?.decimalPoint?.isActive,
    },
  }

  const formikProps = useFormik({
    initialValues: initialValues,
    onSubmit: (
      {
        hasNumberRule,
        subType,
        minimum,
        maximum,
        decimalPoint,
        scale,
      }: NumberRuleProps,
      { setErrors, resetForm }: FormikHelpers<NumberRuleProps>,
    ) => {
      if (hasNumberRule) {
        if (!subType) {
          setErrors({ subType: 'ルールの設定を行ってください' })
          return
        }

        switch (subType) {
          case ResponseNumberSubTypeEnum.BETWEEN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            if (minimum > maximum) {
              setErrors({ minimum: '最小値 ー 最大値 で入力して下さい' })
              return
            }
            break
          case ResponseNumberSubTypeEnum.LESS_THAN:
            if (maximum === undefined || maximum === null) {
              setErrors({ maximum: '最大値を入力して下さい' })
              return
            }
            if (isNaN(maximum)) {
              setErrors({ maximum: '最大値を数値で入力して下さい' })
              return
            }
            break
          case ResponseNumberSubTypeEnum.GREATER_THAN:
            if (minimum === undefined || minimum === null) {
              setErrors({ minimum: '最小値を入力して下さい' })
              return
            }
            if (isNaN(minimum)) {
              setErrors({ minimum: '最小値を数値で入力して下さい' })
              return
            }
            break
        }
      }
      if (decimalPoint) {
        const { isActive, value } = decimalPoint

        if (isActive && value === undefined) {
          setErrors({
            decimalPoint: '小数点以下の桁数を入力してください。',
          })
          return
        }
        if (!!subType && isActive && value !== undefined) {
          const maximumDecimalPoint = String(maximum).split('.')[1]?.length || 0
          const minimumDecimalPoint = String(minimum).split('.')[1]?.length || 0
          const ruleDecimalPoint =
            maximumDecimalPoint > minimumDecimalPoint
              ? maximumDecimalPoint
              : minimumDecimalPoint

          if (value < ruleDecimalPoint) {
            setErrors({
              decimalPoint:
                '小数点以下の桁数をルールの小数点桁数より大きい数値で入力してください。',
            })
            return
          }
        }
      }

      if (subType === ResponseNumberSubTypeEnum.GREATER_THAN)
        maximum = undefined
      if (subType === ResponseNumberSubTypeEnum.LESS_THAN) minimum = undefined

      changeResponseNumberRule(questionNode, scale ?? '', {
        minimum,
        maximum,
        subType,
        decimalPoint,
      })
      onClose()
      resetForm()
    },
    enableReinitialize: true,
  })

  const { handleSubmit, resetForm } = formikProps

  const onCloseOrCancel = () => {
    onClose()
    resetForm()
  }

  const onSubmit = () => handleSubmit()

  return (
    <Drawer
      title="ルールの設定"
      maskClosable={false}
      placement="right"
      onClose={onCloseOrCancel}
      open={isOpen}
      width="600"
    >
      <Form>
        <NormalValueRow {...{ formikProps }} />
        <FractionalDigitsRow {...{ formikProps }} />
        <Row style={{ justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button type="link" onClick={onCloseOrCancel}>
              キャンセル
            </Button>
            <Button type="primary" onClick={onSubmit}>
              保存
            </Button>
          </ButtonGroup>
        </Row>
      </Form>
    </Drawer>
  )
}

export default EditResponseNumberRuleDrawerContainer
