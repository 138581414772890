import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  MultipleChoice,
  MultipleChoiceGroup,
  MultipleChoiceSet,
} from '@ulysses-inc/harami_api_client'
import { Button, Dropdown, Tag, Typography } from 'antd'
import React, { ReactNode, useState } from 'react'
import InputMenu from 'src/features/templateEdit/itemAppender/InputMenu'
import {
  DownOutlinedIcon,
  MultipleChoiceHeaderContainer,
  MultipleChoiceSetHeaderContainer,
  MultipleChoiceSetItem,
  MultipleChoiceSetText,
  MultipleChoiceSetTitleText,
  ResponseEditButton,
  ResponseItem,
  ResponseMenu,
  ScrollContainer,
  SelectAnswerWrapper,
  SelectResponseTableCell,
  UpOutlinedIcon,
} from 'src/features/templateEdit/itemAppender/ResponseDropDown.dump'
import {
  DisabledTableCellBackgroundColor,
  White,
} from 'src/features/theme/KdsThemeColor'

const { Text } = Typography

interface OwnProps {
  multipleChoiceGroups: Array<MultipleChoiceGroup>
  multipleChoiceSets: Array<MultipleChoiceSet>
  selectAnswer: ReactNode
  hasLogic: boolean
  setSelectAnswer: (selectAnswer: ReactNode) => void
  setUpdateMultipleChoiceGroup: (group?: MultipleChoiceGroup) => void
  setIsVisibleAddMultipleChoiceDrawer: (
    isVisibleAddMultipleChoiceDrawer: boolean,
  ) => void
  onChangeQuestionType: (key: string) => void
  onClickMultipleChoice: (id: number) => void
  onClickMultipleChoiceSet: (id: number) => void
  isEmployeeCheck: boolean
}

const ResponseDropDown: React.FC<OwnProps> = (props: OwnProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOpenChange = (open: boolean) => {
    if (props.hasLogic || props.isEmployeeCheck) return
    setIsOpen(open)
  }

  const groundArea = (
    <SelectResponseTableCell
      theme={{
        minWidth: '300px',
        backgroundColor: props.hasLogic && DisabledTableCellBackgroundColor,
      }}
    >
      <div style={{ display: 'flex' }} data-testid="response-drop-down">
        <SelectAnswerWrapper>{props.selectAnswer}</SelectAnswerWrapper>
        {!props.isEmployeeCheck && (
          <div style={{ minWidth: 20, marginRight: 12 }}>
            {isOpen ? <UpOutlinedIcon /> : <DownOutlinedIcon />}
          </div>
        )}
      </div>
    </SelectResponseTableCell>
  )

  const hoverArea = (
    <div style={{ display: 'flex' }} data-testid="response-drop-down-hover">
      <SelectResponseTableCell
        theme={{ width: '200px', backgroundColor: White }}
      >
        <ScrollContainer>
          <MultipleChoiceHeaderContainer>
            <Text>選択肢</Text>
            <Button
              onMouseDown={() => {
                props.setUpdateMultipleChoiceGroup(undefined)
                props.setIsVisibleAddMultipleChoiceDrawer(true)
                setIsOpen(false)
              }}
              type="link"
              icon={<PlusCircleOutlined />}
            >
              選択肢を追加
            </Button>
          </MultipleChoiceHeaderContainer>
          <ResponseMenu key="multipleChoice">
            {props.multipleChoiceGroups.map(
              (multipleChoiceGroup: MultipleChoiceGroup, index: number) => (
                <ResponseItem
                  data-testid="multiple-choice-item"
                  key={index}
                  onClick={() => {
                    if (multipleChoiceGroup.id) {
                      props.onClickMultipleChoice(multipleChoiceGroup.id)
                    }
                    setIsOpen(false)
                  }}
                >
                  <ResponseEditButton>
                    {multipleChoiceGroup.responses.length > 0 && (
                      <Button
                        key={index}
                        onMouseDown={() => {
                          props.setUpdateMultipleChoiceGroup(
                            multipleChoiceGroup,
                          )
                          props.setIsVisibleAddMultipleChoiceDrawer(true)
                          setIsOpen(false)
                        }}
                        type="link"
                        icon={<EditOutlined />}
                      />
                    )}
                  </ResponseEditButton>
                  {multipleChoiceGroup.responses.map(
                    (multipleChoice: MultipleChoice) => (
                      <Tag key={multipleChoice.id} color={multipleChoice.color}>
                        {multipleChoice.response}
                      </Tag>
                    ),
                  )}
                </ResponseItem>
              ),
            )}
          </ResponseMenu>
          <ResponseMenu>
            <MultipleChoiceSetHeaderContainer>
              <MultipleChoiceSetTitleText>
                セット選択肢
              </MultipleChoiceSetTitleText>
            </MultipleChoiceSetHeaderContainer>
            {props.multipleChoiceSets.map(choiceSet => (
              <MultipleChoiceSetItem
                key={choiceSet.id}
                onClick={() => {
                  if (choiceSet.id) {
                    props.onClickMultipleChoiceSet(choiceSet.id)
                  }
                  setIsOpen(false)
                }}
              >
                <MultipleChoiceSetText ellipsis>
                  {choiceSet.name}
                </MultipleChoiceSetText>
              </MultipleChoiceSetItem>
            ))}
          </ResponseMenu>
        </ScrollContainer>
      </SelectResponseTableCell>
      <SelectResponseTableCell
        theme={{ minWidth: '300px', backgroundColor: White }}
      >
        <MultipleChoiceHeaderContainer>
          <Text>入力フォーム</Text>
        </MultipleChoiceHeaderContainer>
        <InputMenu
          onClick={key => {
            props.onChangeQuestionType(key)
            setIsOpen(false)
          }}
        />
      </SelectResponseTableCell>
    </div>
  )

  return (
    <Dropdown
      destroyPopupOnHide // 閉じたあとも要素が残っているとE2Eテスト時に厄介なのできっちり消す
      dropdownRender={() => hoverArea}
      trigger={['click']}
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      {groundArea}
    </Dropdown>
  )
}

export default ResponseDropDown
