import {
  BranchesOutlined,
  DeleteOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  ResponseTypeEnum,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Modal } from 'antd'
import { FC, ReactNode } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ClampText } from 'src/components/clampText/ClampText'
import { getRelatedExcelConversionFlowInQuestion } from 'src/features/templateEdit/common/getRelatedExcelConversionFlow'
import {
  BranchesIconColor,
  DeleteIconColor,
} from 'src/features/theme/KdsThemeColor'
import {
  changeLogicToNext,
  changeQuestionToNext,
  deleteTemplateNode,
  updateTemplateNode,
} from 'src/state/ducks/templates/actions'
import {
  showEditMultipleChoiceLogic,
  showEditNumberLogic,
} from 'src/state/ducks/templates/editLogic/actions'
import { RootState } from 'src/state/store'
import DeleteConfirmDialog from 'src/views/components/dialogs/DeleteConfirmDialog'
import { useGridLayoutSoftLimitErrorLog } from '../useSoftLimitErrorLog'
import { validateLogicNestCountOnInsertingNewLogic } from '../validate'
import { QuestionProps } from './Question'
import {
  ConfirmModalContentList,
  DragHandle,
  HandleIcon,
  IconButtonTag,
  LogicOpenButton,
  QuestionNameParagraph,
  QuestionNameParagraphWrapper,
  QuestionOpenButtonWrapper,
  QuestionTableCell,
  QuestionWrapper,
} from './Question.components'
import { useDragAndDrop } from './Question.hooks'
import { checkIsEmployeeCheck } from './Question.utils'
import { IQuestionBodyStates } from './QuestionBody'
import SelectNodeEditor from './SelectNodeEditor'

const QuestionNameAndButtons: FC<
  Pick<QuestionProps, 'node' | 'parentNode' | 'isActive' | 'templateNodes'> &
    Pick<IQuestionBodyStates, 'isOpenState'> &
    Pick<ReturnType<typeof useDragAndDrop>, 'drag' | 'preview'>
> = ({
  node,
  parentNode,
  isActive,
  templateNodes,
  isOpenState: [isOpen, setIsOpen],
  preview,
  drag,
}) => {
  const excelConversionFlows = useSelector(
    (state: RootState) =>
      state.templatesState.editTemplate.excelConversionFlows,
    shallowEqual,
  )

  const { layoutType } = useSelector(
    (state: RootState) => state.templatesState.editTemplate,
    shallowEqual,
  )

  const { logSoftLimitErrorEvent } = useGridLayoutSoftLimitErrorLog('addNode')

  const dispatch = useDispatch()
  const dispatchChangeQuestionToNext = (name: string) => {
    const n: TemplateNodeSchema = {
      ...node,
      question: { ...node.question, name },
    }

    if (n.nodes?.length) {
      dispatch(changeLogicToNext(node.id, n))
    } else {
      dispatch(changeQuestionToNext(node.id, n, parentNode))
    }
  }
  const dispatchDeleteTemplateNode = () => {
    dispatch(deleteTemplateNode(node.id, parentNode))
  }
  const dispatchShowEditLogic = () => {
    const showErrorModal = (errorMsg: string) => {
      Modal.error({
        title: '条件分岐の追加ができません',
        content: errorMsg,
      })
      logSoftLimitErrorEvent('logicNestCount', errorMsg)
    }

    const errorMsg = validateLogicNestCountOnInsertingNewLogic(
      layoutType,
      node,
      templateNodes,
    )

    if (errorMsg) {
      showErrorModal(errorMsg)
      return
    }

    if (node.question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE) {
      dispatch(showEditMultipleChoiceLogic(node))
      return
    }

    if (node.question?.responseType === ResponseTypeEnum.NUMBER) {
      dispatch(showEditNumberLogic(node))
      return
    }
  }
  const dispatchUpdateTemplateNode = (
    values: Partial<TemplateNodeSchema['question']>,
    nodeId: number = node.id,
  ) => {
    dispatch(
      updateTemplateNode(nodeId, {
        ...node,
        question: { ...node.question, ...values },
      }),
    )
  }

  const questionName = node.question?.name ?? ''
  const isEmployeeCheck = checkIsEmployeeCheck(parentNode, node)
  const isShownDragHandle = !isOpen || node.nodes.length === 0
  const isNumber = node.question?.responseType === ResponseTypeEnum.NUMBER
  const isMultipleChoice =
    node.question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE
  const isShowBranchesIcon =
    isMultipleChoice ||
    (isNumber && !!(node.question?.responseNumbers ?? [])[0]?.subType)

  const onClickDelete = () => {
    const content: ReactNode[] = []

    if (node.nodes.length) {
      content.push('紐づく質問やセクションも一緒に削除されます。')
    }

    const flowNames = getRelatedExcelConversionFlowInQuestion(
      node.id,
      templateNodes,
      excelConversionFlows,
    )
    if (flowNames.length) {
      if (content.length > 0) {
        content.push(
          <>
            <br />
            <br />
          </>,
        )
      }

      content.push(
        <>
          以下のExcel変換設定に紐づいています。
          <ConfirmModalContentList>
            {flowNames.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ConfirmModalContentList>
        </>,
      )
    }

    DeleteConfirmDialog({
      title: '質問を削除してもよろしいですか？',
      content,
      onOk: () => dispatchDeleteTemplateNode(),
    })
  }

  return (
    <QuestionTableCell theme={{ width: '100%' }}>
      <QuestionWrapper ref={preview}>
        {!!node.nodes.length && (
          <QuestionOpenButtonWrapper>
            <LogicOpenButton
              type="primary"
              shape="circle"
              icon={isOpen ? <DownOutlined /> : <RightOutlined />}
              onClick={() => setIsOpen(!isOpen)}
            />
          </QuestionOpenButtonWrapper>
        )}
        {isShownDragHandle && !isEmployeeCheck && (
          <DragHandle ref={drag}>
            <HandleIcon />
          </DragHandle>
        )}
        <QuestionNameParagraphWrapper data-testid="question-name">
          {isActive ? (
            <SelectNodeEditor
              onChangeText={dispatchChangeQuestionToNext}
              onUpdate={name => dispatchUpdateTemplateNode({ name })}
              // どのタイプの質問であっても複数行の入力を許可する
              multiline
              name={questionName}
              node={node}
            />
          ) : (
            <QuestionNameParagraph>
              <ClampText line={2}>{questionName}</ClampText>
            </QuestionNameParagraph>
          )}
        </QuestionNameParagraphWrapper>
        <IconButtonTag>
          {!isEmployeeCheck && (
            <Button type="link" onClick={onClickDelete}>
              <DeleteOutlined style={{ color: DeleteIconColor }} />
            </Button>
          )}
          {isShowBranchesIcon && (
            <Button
              type="link"
              onClick={dispatchShowEditLogic}
              aria-label="条件分岐の設定"
            >
              <BranchesOutlined
                rotate={90}
                style={{ color: BranchesIconColor }}
              />
            </Button>
          )}
        </IconButtonTag>
      </QuestionWrapper>
    </QuestionTableCell>
  )
}

export default QuestionNameAndButtons
