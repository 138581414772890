import {
  ResponseNumber,
  ResponseNumberDecimalPoint,
  ResponseNumberSubTypeEnum,
  RuleDefTypeEnum,
  RuleVariableDef,
  TemplateNodeSchema,
} from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Form, Row } from 'antd'
import { FormikHelpers, useFormik } from 'formik'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { updateTemplateNode } from 'src/state/ducks/templates/actions'
import { spreadRuleVariableDefs } from '../question'
import { ButtonGroup } from './EditResponseNumberRuleEnableProductMasterDrawer.styled'
import FractionalDigitsRow from './FractionalDigitsRow'
import NormalValueRow from './NormalValueRow'

interface OwnProps {
  isOpen: boolean
  questionNode: TemplateNodeSchema
  response?: ResponseNumber
  onClose: () => void
}

export type NumberRuleProps = {
  hasNumberRule: boolean
  minimum?: number
  maximum?: number
  scale?: string
  subType?: ResponseNumberSubTypeEnum
  decimalPoint?: ResponseNumberDecimalPoint
  ruleDefType?: RuleDefTypeEnum
  minimumRuleVariableDef?: RuleVariableDef
  maximumRuleVariableDef?: RuleVariableDef
}

const EditResponseNumberRuleEnableProductMasterDrawerContainer: FC<
  OwnProps
> = ({ response, questionNode, onClose, isOpen }) => {
  const dispatch = useDispatch()

  // 数値タイプの質問にルールを設定する
  const changeResponseNumberRule = (
    node: TemplateNodeSchema,
    scale: string,
    newResponseNumber: Pick<
      ResponseNumber,
      | 'minimum'
      | 'maximum'
      | 'subType'
      | 'decimalPoint'
      | 'ruleDefType'
      | 'ruleVariableDefs'
    >,
  ) => {
    dispatch(
      updateTemplateNode(node.id, {
        ...node,
        question: {
          ...node.question,
          scale: scale,
          responseNumbers: [newResponseNumber],
        },
      }),
    )
  }

  // subType が 0 である場合にもルールなしの判定としたいが、
  // 0 は Enum に定義されておらず型エラーが発生するため、falsy かどうかで判定する
  const hasNumberRule = !!response?.subType

  const initialValues: NumberRuleProps = {
    hasNumberRule,
    minimum: response?.minimum,
    maximum: response?.maximum,
    scale: questionNode.question?.scale,
    subType: response?.subType,
    decimalPoint: {
      value: response?.decimalPoint?.value,
      isActive: response?.decimalPoint?.isActive,
    },
    ruleDefType: response?.ruleDefType,
    ...spreadRuleVariableDefs(response?.ruleVariableDefs),
  }

  const formikProps = useFormik({
    initialValues: initialValues,
    onSubmit: (
      {
        hasNumberRule,
        subType,
        minimum,
        maximum,
        decimalPoint,
        scale,
        ruleDefType,
        minimumRuleVariableDef,
        maximumRuleVariableDef,
      }: NumberRuleProps,
      { setErrors, resetForm }: FormikHelpers<NumberRuleProps>,
    ) => {
      if (hasNumberRule) {
        if (!ruleDefType) {
          setErrors({ ruleDefType: '設定方法を選択してください' })
          return
        }

        if (!subType) {
          setErrors({ subType: '範囲を選択してください' })
          return
        }

        switch (ruleDefType) {
          case RuleDefTypeEnum.Master:
            switch (subType) {
              case ResponseNumberSubTypeEnum.BETWEEN:
                if (minimumRuleVariableDef == null) {
                  setErrors({
                    minimumRuleVariableDef: '最小値を選択してください',
                  })
                  return
                }
                if (maximumRuleVariableDef == null) {
                  setErrors({
                    maximumRuleVariableDef: '最大値を選択してください',
                  })
                  return
                }
                break
              case ResponseNumberSubTypeEnum.LESS_THAN:
                if (maximumRuleVariableDef == null) {
                  setErrors({
                    maximumRuleVariableDef: '最大値を選択してください',
                  })
                  return
                }
                break
              case ResponseNumberSubTypeEnum.GREATER_THAN:
                if (minimumRuleVariableDef == null) {
                  setErrors({
                    minimumRuleVariableDef: '最小値を選択してください',
                  })
                  return
                }
                break
            }
            break
          case RuleDefTypeEnum.Fixed:
            switch (subType) {
              case ResponseNumberSubTypeEnum.BETWEEN:
                if (minimum === undefined || minimum === null) {
                  setErrors({ minimum: '最小値を入力してください' })
                  return
                }
                if (isNaN(minimum)) {
                  setErrors({ minimum: '最小値を数値で入力してください' })
                  return
                }
                if (maximum === undefined || maximum === null) {
                  setErrors({ maximum: '最大値を入力してください' })
                  return
                }
                if (isNaN(maximum)) {
                  setErrors({ maximum: '最大値を数値で入力してください' })
                  return
                }
                if (minimum > maximum) {
                  setErrors({ minimum: '最小値 ー 最大値 で入力してください' })
                  return
                }
                break
              case ResponseNumberSubTypeEnum.LESS_THAN:
                if (maximum === undefined || maximum === null) {
                  setErrors({ maximum: '最大値を入力してください' })
                  return
                }
                if (isNaN(maximum)) {
                  setErrors({ maximum: '最大値を数値で入力してください' })
                  return
                }
                break
              case ResponseNumberSubTypeEnum.GREATER_THAN:
                if (minimum === undefined || minimum === null) {
                  setErrors({ minimum: '最小値を入力してください' })
                  return
                }
                if (isNaN(minimum)) {
                  setErrors({ minimum: '最小値を数値で入力してください' })
                  return
                }
                break
            }
            break
        }
      }
      if (decimalPoint) {
        const { isActive, value } = decimalPoint

        if (isActive && value === undefined) {
          setErrors({
            decimalPoint: '小数点以下の桁数を入力してください。',
          })
          return
        }
        if (!!subType && isActive && value !== undefined) {
          const maximumDecimalPoint = String(maximum).split('.')[1]?.length || 0
          const minimumDecimalPoint = String(minimum).split('.')[1]?.length || 0
          const ruleDecimalPoint =
            maximumDecimalPoint > minimumDecimalPoint
              ? maximumDecimalPoint
              : minimumDecimalPoint

          if (value < ruleDecimalPoint) {
            setErrors({
              decimalPoint:
                '小数点以下の桁数をルールの小数点桁数より大きい数値で入力してください。',
            })
            return
          }
        }
      }

      const newResponseNumber = buildNewResponseNumber({
        minimum,
        maximum,
        subType,
        decimalPoint,
        ruleDefType,
        minimumRuleVariableDef,
        maximumRuleVariableDef,
      })
      changeResponseNumberRule(questionNode, scale ?? '', newResponseNumber)
      onClose()
      resetForm()
    },
    enableReinitialize: true,
  })

  const { handleSubmit, resetForm } = formikProps

  const onCloseOrCancel = () => {
    onClose()
    resetForm()
  }

  const onSubmit = () => handleSubmit()

  return (
    <Drawer
      title="ルールの設定"
      maskClosable={false}
      placement="right"
      onClose={onCloseOrCancel}
      open={isOpen}
      width="608"
    >
      <Form>
        <NormalValueRow {...{ formikProps }} />
        <FractionalDigitsRow {...{ formikProps }} />
        <Row style={{ justifyContent: 'flex-end' }}>
          <ButtonGroup>
            <Button type="link" onClick={onCloseOrCancel}>
              キャンセル
            </Button>
            <Button type="primary" onClick={onSubmit}>
              保存
            </Button>
          </ButtonGroup>
        </Row>
      </Form>
    </Drawer>
  )
}

export default EditResponseNumberRuleEnableProductMasterDrawerContainer

const buildNewResponseNumber = ({
  minimum,
  maximum,
  subType,
  decimalPoint,
  ruleDefType,
  minimumRuleVariableDef,
  maximumRuleVariableDef,
}: Omit<NumberRuleProps, 'hasNumberRule'>): ResponseNumber => {
  const base = {
    ruleDefType,
    subType,
    decimalPoint,
  }

  switch (ruleDefType) {
    case RuleDefTypeEnum.Master:
      switch (subType) {
        case ResponseNumberSubTypeEnum.BETWEEN:
          return {
            ...base,
            minimum: undefined,
            maximum: undefined,
            ruleVariableDefs: [
              minimumRuleVariableDef,
              maximumRuleVariableDef,
            ].filter((v): v is RuleVariableDef => v != null),
          }
        case ResponseNumberSubTypeEnum.LESS_THAN:
          return {
            ...base,
            minimum: undefined,
            maximum: undefined,
            ruleVariableDefs: [maximumRuleVariableDef].filter(
              (v): v is RuleVariableDef => v != null,
            ),
          }
        case ResponseNumberSubTypeEnum.GREATER_THAN:
          return {
            ...base,
            minimum: undefined,
            maximum: undefined,
            ruleVariableDefs: [minimumRuleVariableDef].filter(
              (v): v is RuleVariableDef => v != null,
            ),
          }
      }
      break
    case RuleDefTypeEnum.Fixed:
      switch (subType) {
        case ResponseNumberSubTypeEnum.BETWEEN:
          return {
            ...base,
            minimum,
            maximum,
            ruleVariableDefs: undefined,
          }
        case ResponseNumberSubTypeEnum.LESS_THAN:
          return {
            ...base,
            minimum: undefined,
            maximum,
            ruleVariableDefs: undefined,
          }
        case ResponseNumberSubTypeEnum.GREATER_THAN:
          return {
            ...base,
            minimum,
            maximum: undefined,
            ruleVariableDefs: undefined,
          }
      }
      break
  }
  return {
    minimum: undefined,
    maximum: undefined,
    subType: undefined,
    decimalPoint,
    ruleDefType: undefined,
    ruleVariableDefs: undefined,
  }
}
