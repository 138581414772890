import {
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { isAnswered } from 'src/exShared/util/report/isAnswered'
import { selectDisplayCellStatus } from 'src/state/ducks/reports/reportResult/grid/selectors'
import { isIoT as isIoTBizStack } from 'src/util/iot/bizStack/isIoT'
import { isIoT as isIoTGugen } from 'src/util/iot/gugen/isIoT'
import { isIoT as isIoTKonektiEX } from 'src/util/iot/konektiEX/isIoT'
import { isIoT as isIoTNipponRad } from 'src/util/iot/nipponRad/isIoT'
import {
  getDatetimeSubType,
  getFormulaDecimalPoint,
  getNumberDecimalPoint,
} from './TableBodyCell.util'
import {
  TableBodyCellDatetime,
  TableBodyCellDisabled,
  TableBodyCellGridVariable,
  TableBodyCellMultipleChoice,
  TableBodyCellNotInputted,
  TableBodyCellNotWorked,
  TableBodyCellNumber,
  TableBodyCellResponseSet,
  TableBodyCellResultImage,
  TableBodyCellText,
} from './tableBodyCells'
import { TableBodyCellWrapper } from './tableBodyCells/Common.dumb'
import { TableBodyCellFormula } from './tableBodyCells/TableBodyCellFormula'
import { TableBodyCellNotCalculated } from './tableBodyCells/TableBodyCellNotCalculated'
import { TableBodyCellNotMeasured } from './tableBodyCells/TableBodyCellNotMeasured'
import { TableBodyCellSignature } from './tableBodyCells/TableBodyCellSignatures'
import { TableBodyCellTimeMeasurement } from './tableBodyCells/TableBodyCellTimeMeasurement'
import { TableBodyCellIoT as TableBodyCellIoTBizStack } from './tableBodyCells/iot/bizStack/TableBodyCellIoT'
import { TableBodyCellIoT as TableBodyCellIoTGugen } from './tableBodyCells/iot/gugen/TableBodyCellIoT'
import { TableBodyCellIoT as TableBodyCellIoTKonektiEX } from './tableBodyCells/iot/konektiEX/TableBodyCellIoT'
import { TableBodyCellIoT as TableBodyCellIoTNipponRad } from './tableBodyCells/iot/nipponRad/TableBodyCellIoT'

type Props = {
  nodeId: number
  nodeUUID: string | undefined // IoT 機器検証用に追加、検証完了後に削除予定
  question: ReportQuestion
  goPreviewPhoto: (imageUrls: string[], selectedImageIndex: number) => void
}

export const TableBodyCell: FC<Props> = ({
  nodeId,
  nodeUUID,
  question,
  goPreviewPhoto,
}) => {
  const { responseType, responseAnswer } = question

  const isDisabledGridVariable =
    responseType === ResponseTypeEnum.GRID_VARIABLE &&
    question?.responseGridVariables &&
    !question?.responseGridVariables[0]?.isEnabled

  const displayCellStatus = useSelector(selectDisplayCellStatus(nodeId))

  // ひな形側の取り込み項目質問のラジオボタンがoffで設定された場合、セルを非表示
  if (isDisabledGridVariable) return <></>

  // MEMO: responseTypeが入ってないことはありえないが、OpenAPIの定義上オプショナルになっているのでとりあえず枠だけ返しておく
  if (!responseType) return <></>

  switch (displayCellStatus) {
    case 'NoAnswer': {
      // noAnswerフィールドが存在する=iPad記録時に未実施を選択したということ
      return <TableBodyCellNotWorked />
    }
    case 'Disabled': {
      // 親ノードに条件が成立していない条件分岐ノードが存在する場合
      return <TableBodyCellDisabled />
    }
    default:
      break
  }

  if (!isAnswered(question, true)) {
    switch (responseType) {
      case ResponseTypeEnum.TIME_MEASUREMENT:
        return <TableBodyCellNotMeasured />
      case ResponseTypeEnum.FORMULA:
        return <TableBodyCellNotCalculated />
      default:
        return <TableBodyCellNotInputted />
    }
  }

  switch (responseType) {
    case ResponseTypeEnum.NUMBER:
      // IoT 機器の場合、 IoT 専用セルを呼び出す
      if (nodeUUID && isIoTKonektiEX(nodeUUID)) {
        return (
          <TableBodyCellIoTKonektiEX
            value={responseAnswer?.numberValue}
            scale={question.scale}
            decimalPoint={getNumberDecimalPoint(question)}
            recordedAt={responseAnswer?.recordedAt}
          />
        )
      } else if (nodeUUID && isIoTBizStack(nodeUUID)) {
        return (
          <TableBodyCellIoTBizStack
            value={responseAnswer?.numberValue}
            scale={question.scale}
            decimalPoint={getNumberDecimalPoint(question)}
            recordedAt={responseAnswer?.recordedAt}
          />
        )
      } else if (nodeUUID && isIoTNipponRad(nodeUUID)) {
        return (
          <TableBodyCellIoTNipponRad
            value={responseAnswer?.numberValue}
            scale={question.scale}
            decimalPoint={getNumberDecimalPoint(question)}
            recordedAt={responseAnswer?.recordedAt}
          />
        )
      } else if (nodeUUID && isIoTGugen(nodeUUID)) {
        return (
          <TableBodyCellIoTGugen
            value={responseAnswer?.numberValue}
            scale={question.scale}
            decimalPoint={getNumberDecimalPoint(question)}
            recordedAt={responseAnswer?.recordedAt}
          />
        )
      } else {
        return (
          <TableBodyCellNumber
            value={responseAnswer?.numberValue}
            scale={question.scale}
            decimalPoint={getNumberDecimalPoint(question)}
          />
        )
      }
    case ResponseTypeEnum.TEXT:
      return <TableBodyCellText value={responseAnswer?.textValue} />
    case ResponseTypeEnum.DATETIME:
      return (
        <TableBodyCellDatetime
          value={responseAnswer?.datetimeValue}
          subType={getDatetimeSubType(question)}
        />
      )
    case ResponseTypeEnum.GRID_VARIABLE:
      return (
        <TableBodyCellGridVariable
          value={responseAnswer?.gridVariableValue?.value}
        />
      )
    case ResponseTypeEnum.MULTIPLE_CHOICE:
      return (
        <TableBodyCellMultipleChoice value={responseAnswer?.multipleChoice} />
      )
    case ResponseTypeEnum.RESPONSE_SET:
      return <TableBodyCellResponseSet value={responseAnswer?.responseSets} />
    case ResponseTypeEnum.RESULT_IMAGE:
      return (
        <TableBodyCellResultImage
          value={responseAnswer?.resultImages}
          goPreviewPhoto={goPreviewPhoto}
        />
      )
    case ResponseTypeEnum.TIME_MEASUREMENT:
      return (
        <TableBodyCellTimeMeasurement
          value={responseAnswer?.timeMeasurementValue}
        />
      )
    case ResponseTypeEnum.FORMULA:
      return (
        <TableBodyCellFormula
          value={responseAnswer?.formulaValue}
          scale={question.scale}
          decimalPoint={getFormulaDecimalPoint(question)}
        />
      )
    case ResponseTypeEnum.SIGNATURE:
      return <TableBodyCellSignature value={responseAnswer?.signatures?.[0]} />
    default: {
      console.error(`unexpected responseType: ${responseType}`)
      return <TableBodyCellWrapper />
    }
  }
}
