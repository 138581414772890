import { Text, View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import { useSelector } from 'react-redux'
import { isAnswered } from 'src/exShared/util/report/isAnswered'
import {
  getDatetimeSubType,
  getFormulaDecimalPoint,
  getNumberDecimalPoint,
} from 'src/features/reports/result/components/reportResult/GridSection/TableBodyCell.util'
import { TableBorderColor } from 'src/features/theme/KdsThemeColor'
import { selectDisplayCellStatus } from 'src/state/ducks/reports/reportResult/grid/selectors'
import { gridLayoutTableBodyCell } from '../../shared/styles'
import { TableBodyCellDatetime } from './components/TableBodyCellDatetime'
import { TableBodyCellFormula } from './components/TableBodyCellFormula'
import { TableBodyCellGridVariable } from './components/TableBodyCellGridVariable'
import { TableBodyCellMultipleChoice } from './components/TableBodyCellMultipleChoice'
import { TableBodyCellNumber } from './components/TableBodyCellNumber'
import { TableBodyCellResponseSet } from './components/TableBodyCellResponseSet'
import { TableBodyCellResultImage } from './components/TableBodyCellResultImage'
import { TableBodyCellSignature } from './components/TableBodyCellSignatures'
import { TableBodyCellText } from './components/TableBodyCellText'
import { TableBodyCellTimeMeasurement } from './components/TableBodyCellTimeMeasurement'

interface Props {
  node: ReportNodeSchema
}

export const GridLayoutTableBodyCell: React.FC<Props> = ({ node }) => {
  const displayCellStatus = useSelector(selectDisplayCellStatus(node.id))

  if (!node.question) {
    return <></>
  }

  const question = node.question
  const { responseType, responseAnswer } = question

  // ひな形側の取り込み項目質問のラジオボタンがoffで設定された場合、セルを非表示
  const isDisabledGridVariable =
    responseType === ResponseTypeEnum.GRID_VARIABLE &&
    question?.responseGridVariables &&
    !question?.responseGridVariables[0]?.isEnabled
  if (isDisabledGridVariable) {
    return (
      <Text
        style={[
          gridLayoutTableBodyCell.baseTableBodyCell,
          gridLayoutTableBodyCell.tableBodyCell,
        ]}
      />
    )
  }

  // MEMO: responseTypeが入ってないことはありえないが、OpenAPIの定義上オプショナルになっているのでとりあえず枠だけ返しておく
  if (!responseType) {
    return (
      <Text
        style={[
          gridLayoutTableBodyCell.baseTableBodyCell,
          gridLayoutTableBodyCell.tableBodyCell,
        ]}
      />
    )
  }

  switch (displayCellStatus) {
    case 'NoAnswer': {
      // noAnswerフィールドが存在する=iPad記録時に未実施を選択したということ
      // MEMO: 行全体に取り消し線を引くのが難しいかつわかりにくいため、斜線で未実施を表現している
      // また、高さに合わせて車線の角度を調整したりはしない
      return (
        <View
          style={[
            gridLayoutTableBodyCell.baseTableBodyCell,
            gridLayoutTableBodyCell.tableBodyCellNotWorked,
          ]}
        >
          <Text
            style={{
              height: '1px',
              backgroundColor: TableBorderColor,
              transform: 'rotate(-16deg)',
            }}
          />
        </View>
      )
    }
    case 'Disabled': {
      // 親ノードに条件が成立していない条件分岐ノードが存在する場合
      return (
        <Text
          style={[
            gridLayoutTableBodyCell.baseTableBodyCell,
            gridLayoutTableBodyCell.tableBodyCell,
          ]}
        >
          -
        </Text>
      )
    }
    default:
      break
  }

  if (!isAnswered(question, true)) {
    switch (responseType) {
      case ResponseTypeEnum.TIME_MEASUREMENT:
        return (
          <Text
            style={[
              gridLayoutTableBodyCell.baseTableBodyCell,
              gridLayoutTableBodyCell.tableBodyCell,
            ]}
          >
            未計測
          </Text>
        )
      case ResponseTypeEnum.FORMULA:
        return (
          <Text
            style={[
              gridLayoutTableBodyCell.baseTableBodyCell,
              gridLayoutTableBodyCell.tableBodyCell,
            ]}
          >
            未計算
          </Text>
        )
      default:
        return (
          <Text
            style={[
              gridLayoutTableBodyCell.baseTableBodyCell,
              gridLayoutTableBodyCell.tableBodyCell,
            ]}
          >
            未回答
          </Text>
        )
    }
  }

  switch (responseType) {
    case ResponseTypeEnum.NUMBER:
      return (
        <TableBodyCellNumber
          value={responseAnswer?.numberValue}
          scale={question.scale}
          decimalPoint={getNumberDecimalPoint(question)}
        />
      )
    case ResponseTypeEnum.TEXT:
      return <TableBodyCellText value={responseAnswer?.textValue} />
    case ResponseTypeEnum.DATETIME:
      return (
        <TableBodyCellDatetime
          value={responseAnswer?.datetimeValue}
          subType={getDatetimeSubType(question)}
        />
      )
    case ResponseTypeEnum.GRID_VARIABLE:
      return (
        <TableBodyCellGridVariable
          value={responseAnswer?.gridVariableValue?.value}
        />
      )
    case ResponseTypeEnum.MULTIPLE_CHOICE:
      return (
        <TableBodyCellMultipleChoice value={responseAnswer?.multipleChoice} />
      )
    case ResponseTypeEnum.RESPONSE_SET:
      return <TableBodyCellResponseSet value={responseAnswer?.responseSets} />
    case ResponseTypeEnum.RESULT_IMAGE:
      return <TableBodyCellResultImage value={responseAnswer?.resultImages} />
    case ResponseTypeEnum.TIME_MEASUREMENT:
      return (
        <TableBodyCellTimeMeasurement
          value={responseAnswer?.timeMeasurementValue}
        />
      )
    case ResponseTypeEnum.FORMULA:
      return (
        <TableBodyCellFormula
          value={responseAnswer?.formulaValue}
          scale={question.scale}
          decimalPoint={getFormulaDecimalPoint(question)}
        />
      )
    case ResponseTypeEnum.SIGNATURE:
      return <TableBodyCellSignature value={responseAnswer?.signatures?.[0]} />
    default: {
      // MEMO: ここに来るのはAPIの仕様変更などで未対応のresponseTypeが追加された場合
      console.error(`unexpected responseType: ${responseType}`)
      return (
        <Text
          style={[
            gridLayoutTableBodyCell.baseTableBodyCell,
            gridLayoutTableBodyCell.tableBodyCell,
          ]}
        />
      )
    }
  }
}
