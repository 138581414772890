import { css } from '@emotion/react'
import { Breadcrumb, Button, Layout, notification } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Prompt, useLocation } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import Loading from 'src/components/loading/Loading'
import LoadingOverlay from 'src/components/loading/LoadingOverlay'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import { extractUniqueMultipleChoiceSets } from 'src/features/templateEdit/extractUniqueMultipleChoiceSets'
import EditGridTemplateOption from 'src/features/templateEdit/templateOptions/EditGridTemplateOption'
import { useSourceTemplateIdParam } from 'src/features/templateEdit/useSourceTemplateIdParam'
import { getMultipleChoiceSets } from 'src/state/ducks/multipleChoiceSets/actions'
import {
  getTemplatePages,
  resetTemplatePages,
  setTemplatePageErrorMessage,
} from 'src/state/ducks/templates/actions'
import {
  addTemplate,
  getEmptyGridTemplate,
  getPreparedTemplate,
  getTemplate,
  resetTemplate,
  setTemplateNameErrorMessage,
  updateTemplate,
} from 'src/state/ducks/templates/editTemplate/actions'
import { getMultipleChoices } from 'src/state/ducks/templates/responseMultipleChoices/actions'
import { resetTemplateHints } from 'src/state/ducks/templates/templateHints/actions'
import { getUserGroups } from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import { getBoolParamValue } from 'src/util/urlSearchParamsUtils'
import { HistoryState } from 'src/views/components/routes/getUserConfirmation'
import { GridTemplateLabel, LayoutIcon } from './EditGridTemplate.dumb'
import { SoftLimitErrorDialogForGrid } from './errorModal/SoftLimitErrorDialogForGrid'
import EditGridTemplatePages from './templatePages/EditGridTemplatePages'
import { useGridLayoutSoftLimitErrorLog } from './useSoftLimitErrorLog'
import { validateGridTemplatePage } from './validate'

// このコンポーネントが使われる場面 (通常の新規作成 or ひな形テンプレートを使った新規作成 or 既存ひな形の編集)
type TemplateEditMode = 'new' | 'newFromPreparedTemplate' | 'edit'

const EditGridTemplateContainer: FC = () => {
  const dispatch = useDispatch()

  const companyId = localStorage.getItem(localStorageKeys.loginCompanyId) ?? ''
  const {
    editTemplate: templateData, // ひな形関係
    templatePages: templatePagesData, // ひな形ページ関係
    templateHints: templateHintsData, // ひな形ヒント関係 (未利用だけどなぜか読み書きしている)
  } = useSelector((state: RootState) => state.templatesState)

  const isDirty = templateData.isDirty || templatePagesData.isDirty

  // 新規作成時にはURLパラメータにidという値は存在しないため、0になる
  const sourceTemplateId = useSourceTemplateIdParam()

  const location = useLocation()
  // ひな形テンプレートをもとにした新規作成かどうかを表すフラグ
  const isIndustry = location.search.includes('industry')
  const hasVariablesParam = getBoolParamValue(location.search, 'hasVariables')

  const templateEditMode = ((): TemplateEditMode => {
    switch (true) {
      case sourceTemplateId === 0: {
        return 'new'
      }
      case isIndustry: {
        return 'newFromPreparedTemplate'
      }
      default: {
        return 'edit'
      }
    }
  })()

  const dataUnavailableWidget = useDataUnavailableWidget()

  useEffect(() => {
    switch (templateEditMode) {
      case 'new': {
        dispatch(getEmptyGridTemplate(hasVariablesParam))
        break
      }
      case 'newFromPreparedTemplate': {
        // 表形式のテンプレートは現在は未利用だが、今後活用する可能性が少しだけある
        // https://kaminashi-inc.slack.com/archives/C047X5HBRK5/p1705995368660799
        dispatch(getPreparedTemplate(sourceTemplateId))
        break
      }
      case 'edit': {
        dispatch(getTemplatePages(sourceTemplateId))
        dispatch(getTemplate(sourceTemplateId))
        break
      }
    }
    dispatch(getUserGroups({}))
    dispatch(getMultipleChoices())
    dispatch(getMultipleChoiceSets({}))

    return () => {
      dispatch(resetTemplate())
      dispatch(resetTemplatePages())
      dispatch(resetTemplateHints())
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const overlayTip =
    templateEditMode === 'edit'
      ? 'ひな形を更新中です...'
      : 'ひな形を登録中です...'

  const { logSoftLimitErrorEvent } =
    useGridLayoutSoftLimitErrorLog('updateTemplate')

  const saveTemplate = () => {
    // ひな形名のエラーと、page 配下のエラーは別々に出せるので、両方のエラーを同時に出せるように分けて処理している
    let isInvalid = false
    if (templateData.name === '') {
      dispatch(setTemplateNameErrorMessage('ひな形名を入力してください'))
      isInvalid = true
    }

    const [isValid, errMessage, gridSoftLimitErrorType] =
      validateGridTemplatePage(
        templatePagesData.templateNodes,
        templatePagesData.templatePages,
        companyId,
      )
    if (!isValid) {
      dispatch(setTemplatePageErrorMessage(errMessage))
      isInvalid = true
    }

    // !isValid の場合しか、ソフトリミットのエラーも存在しないが、
    // !isValid の分岐内に書く意味も特にないので、この階層におく
    if (gridSoftLimitErrorType) {
      logSoftLimitErrorEvent(gridSoftLimitErrorType, errMessage)
    }

    if (isInvalid) {
      notification.error({
        message: 'ひな形の保存に失敗しました。',
        description: 'エラー箇所を確認して修正してください。',
        duration: 2,
      })

      setTimeout(() => {
        const errorElement = document.getElementById('error-message')
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }, 100)

      return
    }

    const multipleChoiceSets = extractUniqueMultipleChoiceSets(
      templatePagesData.templateNodes,
    )
    const templateArgs = {
      templatePages: templatePagesData.templatePages,
      templateNodes: templatePagesData.templateNodes,
      templatePageIds: templatePagesData.templatePageIds,
      templateHints: templateHintsData.templateHints,
      name: templateData.name,
      isShowScore: templateData.isShowScore,
      isEditable: templateData.isEditable,
      isExcelConversion: templateData.isExcelConversion,
      isHideQuestionOptions: templateData.isHideQuestionOptions,
      isAudit: templateData.isAudit,
      isKeyboard: templateData.isKeyboard,
      layoutType: templateData.layoutType,
      hasVariables: templateData.hasVariables,
      manuals: templateData.manuals,
      icons: templateData.icons,
      approvalFlowId: templateData.approvalFlowId,
      placeNodes: templateData.selectedPlaceNodes,
      multipleChoiceSets,
      templateReportNamingRulesTemplateNodeUuid:
        templatePagesData.templateReportNamingRulesTemplateNodeUuid,
    }
    if (templateEditMode === 'edit') {
      // updateTemplate 内で時間計測ノードの subType 設定を行う
      // NOTE: ローカルの state にはその変更は反映されないが、現時点(2023/05)では、ひな形詳細画面で、
      //       上記を参照して何かをする実装がないので問題ない
      dispatch(updateTemplate(templateArgs, sourceTemplateId))
      dispatch(setTemplatePageErrorMessage(''))
    } else {
      dispatch(addTemplate(templateArgs))
    }
  }

  const [historyState, setHistoryState] = useState<HistoryState>()
  useEffect(() => {
    setHistoryState({
      data: window.history.state,
      title: document.title,
      url: window.location.href,
    })
  }, [])

  return (
    <>
      <SoftLimitErrorDialogForGrid />
      <LoadingOverlay
        tip={overlayTip}
        spinning={templateData.isLoading}
        size="large"
        render={
          <>
            <Header
              rightSlot={[
                <Button
                  key="1"
                  type="primary"
                  onClick={() => {
                    saveTemplate()
                  }}
                  disabled={dataUnavailableWidget.isVisible}
                >
                  保存
                </Button>,
              ]}
              sticky
            >
              <Breadcrumb css={styles.breadcrumb}>
                <Breadcrumb.Item>
                  <Link to="/templates">
                    <LayoutIcon />
                    ひな形一覧
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  ひな形{templateEditMode === 'edit' ? 'の変更' : 'の追加'}{' '}
                  <GridTemplateLabel>表形式</GridTemplateLabel>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Header>
            {dataUnavailableWidget.isVisible ? (
              <DataUnavailableWidget />
            ) : (
              <Layout className="layout">
                <Prompt
                  when={isDirty}
                  message={location => {
                    const message =
                      '変更した内容が破棄されますがよろしいですか？'
                    // NOTE: キャンセルしたときにURLがもとに戻らないバグがある
                    if (location.pathname === '/templates') {
                      return JSON.stringify({ message, state: historyState })
                    }
                    return message
                  }}
                />
                <Layout.Content>
                  {templateHintsData.isLoading && templateData.isLoading ? (
                    <Loading />
                  ) : (
                    <EditGridTemplateOption />
                  )}
                  {templatePagesData.isLoading ? (
                    <Loading />
                  ) : (
                    <EditGridTemplatePages />
                  )}
                </Layout.Content>
              </Layout>
            )}
          </>
        }
      />
    </>
  )
}

const styles = {
  breadcrumb: css`
    font-weight: bold;
  `,
}

export default EditGridTemplateContainer
