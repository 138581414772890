import { css } from '@emotion/react'
import { Space } from 'antd'
import { format } from 'date-fns'
import { Text } from 'src/components/nativeCompat/Text'
import { ReportLabel } from '../../report-label-inspection'
import { ReportInspectionAreaSection } from './ReportInspectionAreaSection'

type Props = {
  reportLabel: ReportLabel
}

export const ReportLabelSection = ({ reportLabel }: Props) => {
  return (
    <Space direction="vertical" size="middle" key={reportLabel.labelId}>
      <Text>{reportLabel.name}</Text>

      <div css={styles.resultImageWrapper}>
        <img
          alt="ラベル検査結果画像"
          src={reportLabel.resultImageUrl}
          css={styles.resultImage}
        />
        <Text css={styles.resultImageRecordedAt}>
          {format(
            new Date(reportLabel.resultImageRecordedAt),
            'yyyy年M月d日 H:mm:ss',
          )}
        </Text>
      </div>
      <Space direction="vertical" size="small">
        <Text>解析結果</Text>
        <Space direction="vertical" size="small">
          {reportLabel.reportLabelInspectionAreas.map(area => (
            <ReportInspectionAreaSection
              key={area.labelInspectionAreaId}
              reportInspectionArea={area}
            />
          ))}
        </Space>
      </Space>
    </Space>
  )
}

const styles = {
  resultImageWrapper: css`
    position: relative;
    max-width: 270px;
    max-height: 270px;
    width: 100%;
    height: 100%;
    border: 1px solid #dbe1e9;
    border-radius: 4px;
  `,
  resultImage: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
  resultImageRecordedAt: css`
    color: white;
    padding: 4px 8px;
    font-size: 12.5px;
  `,
}
