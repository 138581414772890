import {
  MasterFieldTypeEnum,
  ProductMasterRecordValue,
} from '@ulysses-inc/harami_api_client'
import { TableColumnsType } from 'antd'
import { FC } from 'react'
import { Table } from 'src/components/table/Table'
import { useQueryProducts } from './api/useQueryProducts'

type DataType = {
  key: React.Key
  name: string
  code: string
}

const baseColumns: TableColumnsType<DataType> = [
  {
    title: '製品名',
    dataIndex: 'name',
    key: 'name',
    width: 280,
    fixed: 'left',
  },
  {
    title: '製品コード',
    dataIndex: 'code',
    key: 'code',
    width: 160,
  },
]

const getProductsResponseValue = (value: ProductMasterRecordValue) => {
  switch (value.fieldType) {
    case MasterFieldTypeEnum.Number:
      return value.numberValue
    case MasterFieldTypeEnum.Text:
      return value.textValue
    default:
      return undefined
  }
}

const MasterList: FC = () => {
  const { data, isLoading } = useQueryProducts()

  const columns: TableColumnsType<DataType> = [
    ...baseColumns,
    ...(data?.fieldDefs ?? []).map(fieldDef => ({
      title: fieldDef.name,
      dataIndex: String(fieldDef.id),
      key: fieldDef.code,
      width: 160,
    })),
  ]

  const dataSource: DataType[] | undefined = data
    ? data.records.map(record => ({
        key: record.id,
        name: record.name,
        code: record.code,
        ...Object.fromEntries(
          Object.entries(record.values).map(([key, value]) => [
            key,
            getProductsResponseValue(value),
          ]),
        ),
      }))
    : undefined

  return (
    <Table<DataType>
      style={{
        marginTop: 64,
      }}
      loading={isLoading}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
      scroll={{ y: 600 }}
    />
  )
}

export default MasterList
