import { ResponseNumberSubTypeEnum } from '@ulysses-inc/harami_api_client'
import { Select, Typography } from 'antd'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { SubTypeText } from 'src/constants/responseNumber'
import { NumberRuleProps } from './EditResponseNumberRuleEnableProductMasterDrawer'
import {
  BetweenFormItem,
  FixedRuleContainer,
  MinMaxFormItem,
  NumberMaximumInputNumber,
  NumberMinimumInputNumber,
  NumberSubTypeSelect,
  ScaleAndSubTypeFormItem,
  ScaleInput,
} from './RuleSelector.styled'

const { Option } = Select

export const FixedRuleSelector: FC<{
  formikProps: FormikProps<NumberRuleProps>
}> = ({
  formikProps: {
    values: { subType, minimum, maximum, scale },
    handleChange,
    setFieldValue,
  },
}) => {
  const update = (key: keyof NumberRuleProps) => (value: unknown) =>
    setFieldValue(key, value)

  return (
    <FixedRuleContainer>
      <MinMaxFormItem>
        {subType !== ResponseNumberSubTypeEnum.LESS_THAN && (
          <NumberMinimumInputNumber
            name="minimum"
            placeholder="0"
            value={minimum}
            onChange={update('minimum')}
            aria-label="正常値の下限値"
          />
        )}
        {(!subType || subType === ResponseNumberSubTypeEnum.BETWEEN) && (
          <BetweenFormItem>
            <Typography>ー</Typography>
          </BetweenFormItem>
        )}
        {subType !== ResponseNumberSubTypeEnum.GREATER_THAN && (
          <NumberMaximumInputNumber
            name="maximum"
            placeholder="100"
            value={maximum}
            onChange={update('maximum')}
            aria-label="正常値の上限値"
          />
        )}
      </MinMaxFormItem>
      <ScaleAndSubTypeFormItem>
        <ScaleInput
          name="scale"
          placeholder="℃"
          value={scale}
          maxLength={10}
          onChange={handleChange}
          aria-label="正常値の単位"
        />
        <NumberSubTypeSelect
          placeholder="の間"
          value={subType || undefined}
          onChange={update('subType')}
          aria-label="正常値の範囲指定"
        >
          <Option
            value={ResponseNumberSubTypeEnum.BETWEEN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.BETWEEN]}
          </Option>
          <Option
            value={ResponseNumberSubTypeEnum.GREATER_THAN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.GREATER_THAN]}
          </Option>
          <Option
            value={ResponseNumberSubTypeEnum.LESS_THAN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.LESS_THAN]}
          </Option>
        </NumberSubTypeSelect>
      </ScaleAndSubTypeFormItem>
    </FixedRuleContainer>
  )
}
