import { Result } from 'antd'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import ApprovalFlows from 'src/features/approvalFlows/ApprovalFlows'
import ApprovalListScene from 'src/features/approvals/ApprovalListScene'
import CompanySettingsContainer from 'src/features/companySettings/CompanySettings'
import DashboardContainer from 'src/features/dashboard/Dashboard'
import PlaceDashboard from 'src/features/dashboardPlace/PlaceDashboard'
import Employees from 'src/features/employees/Employees'
import EditExcelConversionFlowContainer from 'src/features/excelConversionFlows/EditExcelConversionFlow'
import ExcelConversionFlows from 'src/features/excelConversionFlows/ExcelConversionFlows'
import ExcelConversionLogs from 'src/features/excelConversionLogs/ExcelConversionLogs'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { setUserPropertiesFromLocalStorage } from 'src/features/firebase/userProperties'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import { EditGridVariables } from 'src/features/gridVariables/edit/EditGridVariables'
import GridVariableTemplates from 'src/features/gridVariables/list/Templates'
import ImproveExcelConversionLogsContainer from 'src/features/improveExcelConversionLogs/ImproveExcelConversionLogsContainer'
import ImproveDetailContainer from 'src/features/improves/improveDetail/ImproveDetailContainer'
import ImprovesContainer from 'src/features/improves/improveList/ImprovesContainer'
import IndicatedIssueLabels from 'src/features/improves/labels/IndicatedIssueLabels'
import Masters from 'src/features/masters/Masters'
import PlaceGroups from 'src/features/places/PlaceGroups'
import Places from 'src/features/places/Places'
import { ReportExportDetail } from 'src/features/reportExport/detail/ReportExportDetail'
import { ReportExportList } from 'src/features/reportExport/list/ReportExportList'
import { TempReportListPageSwitcher } from 'src/features/reports/listBeta/featurePreview/TempReportListPageSwitcher'
import ReportResult from 'src/features/reports/result/ReportResult'
import { ScheduledReportSummary } from 'src/features/scheduledReportSummary/ScheduledReportSummary'
import EditSchedule from 'src/features/schedules/editSchedule/EditSchedule'
import Schedules from 'src/features/schedules/Schedules'
import EditMultipleChoiceSetContainer from 'src/features/template/multipleChoiceSets/editMultipleChoiceSet/EditMultipleChoiceSet'
import MultipleChoiceSets from 'src/features/template/multipleChoiceSets/MultipleChoiceSets'
import Templates from 'src/features/template/templateList/Templates'
import EditGridTemplate from 'src/features/templateEdit/EditGridTemplate'
import { EditTemplate } from 'src/features/templateEdit/EditTemplate'
import CreateTemplateIndustry from 'src/features/templateEdit/onboarding/CreateTemplateIndustry'
import CreateTemplateIndustryDynamic from 'src/features/templateEdit/onboardingDynamic/CreateTemplateIndustryDynamic'
import { ChannelTalk } from 'src/features/users/channelTalk/ChannelTalk'
import UserGroups from 'src/features/users/UserGroups'
import Users from 'src/features/users/Users'
import { PrivateRoute } from 'src/views/components/PrivateRoute'

/**
 * 認証が必要な画面へのルーティングを設定しているコンポーネント
 *
 * ルートの設定には、まずユーザーがその画面にアクセスできるかどうかを確認する必要がある。
 * この確認は認証が完了した後にしか行えない。
 * そのため、パブリックなルートとプライベートなルートのコンポーネントを分割したうえで、
 * 認証後に権限の確認を行うように構成している。
 */
export const RoutesPrivate: React.FC = () => {
  // このフックのなかで、ユーザーロール、契約プラン、フィーチャーフラグの情報を取得し、利用可否を判定している。
  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  useEffect(() => {
    setUserPropertiesFromLocalStorage()
  }, [])

  return (
    <>
      <Switch>
        <Route
          exact
          // サイドメニューを持つ画面群
          // ここと配下Routeの2カ所にpathの記載が必要なので注意
          // TODO: サイドメニューを持つ画面群は近日中に全てここに集約する予定
          path={[
            '/approvalFlows',
            '/approvals',
            '/companySettings',
            '/dashboards',
            '/excelConversionFlows',
            '/improves',
            '/indicatedIssueLabels',
            '/masters',
            '/multipleChoiceSets',
            '/placeGroups',
            '/places',
            '/reportExports',
            '/scheduledReportSummary',
            '/schedules',
            '/userGroups',
            '/users',
          ]}
        >
          <GlobalNavigation>
            <Switch>
              <PrivateRoute
                exact
                path="/approvalFlows"
                component={ApprovalFlows}
                hasPermission={isFeatureAvailable('approvalFlows')}
              />
              <PrivateRoute
                exact
                path="/approvals"
                component={ApprovalListScene}
                hasPermission={isFeatureAvailable('approvals')}
              />
              <PrivateRoute
                exact
                path="/companySettings"
                component={CompanySettingsContainer}
                hasPermission={isFeatureAvailable('companySettings')}
              />
              <PrivateRoute
                exact
                path="/dashboards"
                component={DashboardContainer}
                hasPermission={isFeatureAvailable('dashboard')}
              />
              <PrivateRoute
                exact
                path="/excelConversionFlows"
                component={ExcelConversionFlows}
                hasPermission={isFeatureAvailable('excelConversionRead')}
              />
              <PrivateRoute
                exact
                path="/improves"
                component={ImprovesContainer}
                hasPermission={isFeatureAvailable('improves')}
              />
              <PrivateRoute
                exact
                path="/indicatedIssueLabels"
                component={IndicatedIssueLabels}
                hasPermission={isFeatureAvailable('improves')}
              />
              <PrivateRoute
                exact
                path="/masters"
                component={Masters}
                hasPermission={isFeatureAvailable('masters')}
              />
              <PrivateRoute
                exact
                path="/multipleChoiceSets"
                component={MultipleChoiceSets}
                hasPermission={isFeatureAvailable('multipleChoiceSets')}
              />
              <PrivateRoute
                exact
                path="/placeGroups"
                component={PlaceGroups}
                hasPermission={isFeatureAvailable('places')}
              />
              <PrivateRoute
                exact
                path="/places"
                component={Places}
                hasPermission={isFeatureAvailable('places')}
              />
              <PrivateRoute
                exact
                path="/reportExports"
                component={ReportExportList}
                hasPermission={isFeatureAvailable('reportExports')}
              />
              <PrivateRoute
                exact
                path="/scheduledReportSummary"
                component={ScheduledReportSummary}
                hasPermission={isFeatureAvailable('scheduledReportSummary')}
              />
              <PrivateRoute
                exact
                path="/schedules"
                component={Schedules}
                hasPermission={isFeatureAvailable('schedules')}
              />
              <PrivateRoute
                exact
                path="/userGroups"
                component={UserGroups}
                hasPermission={isFeatureAvailable('users')}
              />
              <PrivateRoute
                exact
                path="/users"
                component={Users}
                hasPermission={isFeatureAvailable('users')}
              />
            </Switch>
          </GlobalNavigation>
        </Route>
        <Route exact path="/">
          <Redirect to="/dashboards" />
        </Route>
        <PrivateRoute
          exact
          path="/improves/:improveUUID"
          component={ImproveDetailContainer}
          hasPermission={isFeatureAvailable('improves')}
        />
        <PrivateRoute
          exact
          path="/improves/:improveUUID/excelConversionLogs"
          component={ImproveExcelConversionLogsContainer}
          hasPermission={isFeatureAvailable('improves')}
        />
        <PrivateRoute
          exact
          path="/dashboards/places/:placeNodeUUID"
          component={PlaceDashboard}
          hasPermission={isFeatureAvailable('dashboard')}
        />
        <PrivateRoute
          exact
          path="/reports"
          component={TempReportListPageSwitcher}
          hasPermission={isFeatureAvailable('reports')}
        />
        <PrivateRoute
          exact
          path="/reports/:id"
          component={ReportResult}
          hasPermission={isFeatureAvailable('reports')}
        />
        <PrivateRoute
          exact
          path="/templates"
          component={Templates}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/templates/pages/add"
          component={EditTemplate}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/templates/pages/addGridTemplate"
          component={EditGridTemplate}
          hasPermission={isFeatureAvailable('templates')}
        />
        {/* FIXME: path="/templates/:templateId は利用されている箇所がなさそう(templateEditMode = new になるため既存のひな形情報が取得されない) */}
        <PrivateRoute
          exact
          path="/templates/:templateId"
          component={EditTemplate}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/templates/:id/pages/edit"
          component={EditTemplate}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/templates/:id/pages/editGridTemplate"
          component={EditGridTemplate}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/gridVariables"
          component={GridVariableTemplates}
          hasPermission={isFeatureAvailable('dynamicSheet')}
        />
        <PrivateRoute
          exact
          path="/schedules/add"
          component={EditSchedule}
          hasPermission={isFeatureAvailable('schedules')}
        />
        <PrivateRoute
          exact
          path="/schedules/:scheduleId"
          component={EditSchedule}
          hasPermission={isFeatureAvailable('schedules')}
        />
        {
          // 同じパスでコンポーネントを切り替えているためこのような手当てが必要になったもの。
          // コンポーネント内での切り替えの方が望ましい。
          isFeatureAvailable('dynamicSheet') ? (
            <PrivateRoute
              exact
              path="/templates/create/industry"
              component={CreateTemplateIndustryDynamic}
              hasPermission
            />
          ) : (
            <PrivateRoute
              exact
              path="/templates/create/industry"
              component={CreateTemplateIndustry}
              hasPermission={isFeatureAvailable('templates')}
            />
          )
        }
        <PrivateRoute
          exact
          path="/templates/create/type"
          component={CreateTemplateIndustry}
          hasPermission={isFeatureAvailable('templates')}
        />
        <PrivateRoute
          exact
          path="/excelConversionFlows/add"
          component={EditExcelConversionFlowContainer}
          hasPermission={isFeatureAvailable('excelConversionEdit')}
        />
        <PrivateRoute
          exact
          path="/excelConversionFlows/:flowId"
          component={EditExcelConversionFlowContainer}
          hasPermission={isFeatureAvailable('excelConversionEdit')}
        />
        <PrivateRoute
          exact
          path="/multipleChoiceSets/add"
          component={EditMultipleChoiceSetContainer}
          hasPermission={isFeatureAvailable('multipleChoiceSets')}
        />
        <PrivateRoute
          exact
          path="/multipleChoiceSets/:multipleChoiceSetId"
          component={EditMultipleChoiceSetContainer}
          hasPermission={isFeatureAvailable('multipleChoiceSets')}
        />
        <PrivateRoute
          exact
          path="/excelConversionLogs"
          component={ExcelConversionLogs}
          hasPermission={isFeatureAvailable('excelConversionRead')}
        />
        <PrivateRoute
          exact
          path="/employees"
          component={Employees}
          hasPermission={isFeatureAvailable('employees')}
        />
        <PrivateRoute
          exact
          path="/gridVariables/:templateId/edit"
          component={EditGridVariables}
          hasPermission={isFeatureAvailable('dynamicSheet')}
        />
        <PrivateRoute
          exact
          path="/reportExports/:reportExportId"
          component={ReportExportDetail}
          hasPermission={isFeatureAvailable('reportExports')}
        />
        <Route>
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
          />
        </Route>
      </Switch>
      <ChannelTalk />
    </>
  )
}
