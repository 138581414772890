import { all } from 'redux-saga/effects'
import approvalFlowsSaga from '../../ducks/approvalFlows/sagas'
import approvalsSaga from '../../ducks/approvals/sagas'
import companySettingsSaga from '../../ducks/companySettings/sagas'
import editGridVariablesSaga from '../../ducks/editGridVariables/sagas'
import emailSaga from '../../ducks/email/sagas'
import employeesSaga from '../../ducks/employees/sagas'
import excelConversionFlowsSaga from '../../ducks/excelConversionFlows/sagas'
import excelConversionLogsSaga from '../../ducks/excelConversionLogs/sagas'
import gridVariablesSaga from '../../ducks/gridVariables/sagas'
import improveEmailLogsSaga from '../../ducks/improveEmailLogs/sagas'
import improveExcelConversionLogsSaga from '../../ducks/improveExcelConversionLogs/sagas'
import improvesSaga from '../../ducks/improves/sagas'
import indicatedIssuesSaga from '../../ducks/indicatedIssues/sagas'
import interceptionsSagas from '../../ducks/interceptions/sagas'
import multipleChoiceSetsSaga from '../../ducks/multipleChoiceSets/sagas'
import placesSaga from '../../ducks/places/sagas'
import productMasterFieldDefsSaga from '../../ducks/productMasterFieldDefs/sagas'
import reportsSagas from '../../ducks/reports/sagas'
import scheduleListInRangeItemsSaga from '../../ducks/scheduleListInRangeItems/sagas'
import schedulesSaga from '../../ducks/schedules/sagas'
import templateListSaga from '../../ducks/templateList/sagas'
import templateResponseMultipleChoiceSagas from '../../ducks/templates/responseMultipleChoices/sagas'
import templatesSagas from '../../ducks/templates/sagas'
import usersSaga from '../../ducks/users/sagas'

export default function* rootSaga() {
  yield all([
    ...approvalFlowsSaga,
    ...approvalsSaga,
    ...companySettingsSaga,
    ...editGridVariablesSaga,
    ...emailSaga,
    ...employeesSaga,
    ...excelConversionFlowsSaga,
    ...excelConversionLogsSaga,
    ...improveExcelConversionLogsSaga,
    ...improveEmailLogsSaga,
    ...improvesSaga,
    ...indicatedIssuesSaga,
    ...interceptionsSagas,
    ...multipleChoiceSetsSaga,
    ...placesSaga,
    ...productMasterFieldDefsSaga,
    ...reportsSagas,
    ...scheduleListInRangeItemsSaga,
    ...schedulesSaga,
    ...templateListSaga,
    ...templateResponseMultipleChoiceSagas,
    ...templatesSagas,
    ...usersSaga,
    ...gridVariablesSaga,
  ])
}
