import { appEnv } from 'src/util/appEnv'

const localNodeUUIDs = [
  'c3a6ed1f-b619-4437-96d1-395d87a6c3b2',
  'c0aed518-61c4-40df-b4c0-721c8755ad8e',
  '7d56c497-5705-4a65-a037-916fe675fa23',
]

const devNodeUUIDs = [
  '0bb3026e-f909-4909-8080-c0c4b7bb1dc5',
  '9992d698-7891-446c-b3ff-862e6822a1f4',
  '859e59a1-fa57-4057-ac7e-52de2117ad59',
]

const prodNodeUUIDs: string[] = []

// IoT 機器の読み取りボタンの表示対象の場合、 true を返す
export function isIoT(nodeUUID: string | undefined): boolean {
  let nodeUUIDs: string[]
  if (appEnv.isLocal) {
    nodeUUIDs = localNodeUUIDs
  } else if (appEnv.isDevelopment) {
    nodeUUIDs = devNodeUUIDs
  } else if (appEnv.isProduction) {
    nodeUUIDs = prodNodeUUIDs
  } else {
    nodeUUIDs = []
  }

  return nodeUUID && nodeUUIDs.includes(nodeUUID) ? true : false
}
