import { ExportOutlined, HomeOutlined, TagOutlined } from '@ant-design/icons'
import { AppRoleEnum, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import { Divider } from 'antd'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getKaminashiIDBasePath } from 'src/config'
import { useQueryLoginUserContext } from 'src/features/tanStackQuery/commonQueries/useQueryLoginUserContext'
import {
  changeIsShowEditUserDrawer,
  changeUpdatedUsersFlag,
} from 'src/state/ducks/users/actions'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import { linkToKaminashiIDManualPage } from '../Users'
import { UserPageHeader, getRenderSelect } from './EditUser.components'
import { useFormikProps } from './EditUser.hooks'

export interface UserForm {
  role: AppRoleEnum
  groupIds: string[]
  placeNodeIds: string[]
}

const roleOptions: [AppRoleEnum, string][] = [
  [AppRoleEnum.OWNER, '管理者'],
  [AppRoleEnum.EDITOR, '編集者'],
  [AppRoleEnum.LEADER, 'リーダー'],
  [AppRoleEnum.REPORTER, 'レポート作成者'],
]

const EditUser: React.FC = () => {
  const activeUser = useSelector(({ usersState }: RootState) => {
    const activeUserId = usersState.users.activeUserId
    return activeUserId
      ? usersState.users.users.find(({ uuid }) => uuid === activeUserId)
      : undefined
  })
  const userGroups = useSelector(
    ({ usersState }: RootState) => usersState.userGroups.userGroups,
  )
  const placeNodes = useSelector(
    ({ placesState }: RootState) => placesState.placeNodes.nodes,
  )
  const isLoadingUsers = useSelector(
    ({ usersState }: RootState) => usersState.users.isLoading,
  )

  const { data: loginUserContext } = useQueryLoginUserContext()

  const linkToMemberEditPage = useMemo(() => {
    const rumpMemberId = activeUser?.rumpMemberId
    const rumpRealmId = loginUserContext?.rumpRealmId
    if (!rumpRealmId || !rumpMemberId) {
      return ''
    }

    const url = new URL(
      `/realms/${rumpRealmId}/members`,
      getKaminashiIDBasePath(),
    )
    url.searchParams.append('memberId', rumpMemberId)
    return url.toString()
  }, [activeUser, loginUserContext])

  const dispatch = useDispatch()
  const dispatchChangeIsShowEditUserDrawer = (isShow: boolean) =>
    dispatch(changeIsShowEditUserDrawer(isShow))
  const dispatchChangeUpdatedUsersFlag = (edited: boolean) =>
    dispatch(changeUpdatedUsersFlag(edited))

  const formikProps = useFormikProps()
  const renderSelect = getRenderSelect(formikProps)

  const { handleSubmit } = formikProps

  const onSubmit = () => {
    handleSubmit()
    dispatchChangeUpdatedUsersFlag(true)
  }

  return (
    <>
      <UserPageHeader>
        {activeUser ? 'ユーザーの編集' : 'ユーザーの追加'}
      </UserPageHeader>
      <div css={{ fontSize: '18px', fontWeight: 'bold' }}>
        {activeUser?.name ?? ''}
      </div>
      {renderSelect(
        'role',
        'ロール（役割）',
        roleOptions,
        activeUser?.role?.role ?? AppRoleEnum.REPORTER,
      )}
      <Divider />
      {renderSelect(
        'groupIds',
        '所属するユーザーグループ',
        userGroups?.map(({ id, name }) => [`${id ?? ''}`, name ?? '']) ?? [],
        activeUser?.userGroups?.map(({ id }) => `${id ?? ''}`),
        true,
      )}
      {renderSelect(
        'placeNodeIds',
        '所属する現場',
        placeNodes.map(({ uuid, type, place, placeGroup }) => [
          uuid,
          type === PlaceNodeTypeEnum.PlaceGroup
            ? (placeGroup?.name ?? '')
            : (place?.name ?? ''),
          type === PlaceNodeTypeEnum.PlaceGroup ? (
            <TagOutlined />
          ) : (
            <HomeOutlined />
          ),
        ]),
        activeUser?.placeNodes?.map(({ uuid }) => uuid),
        true,
      )}
      <div css={{ marginTop: '1rem' }}>
        {loginUserContext?.isRumpRealmAdmin ? (
          <>
            その他のユーザー情報の編集・ユーザーの削除は「カミナシID」で行ってください。
            <br />
            <a href={linkToMemberEditPage} target="_blank" rel="noreferrer">
              カミナシID
              <ExportOutlined
                css={{
                  display: 'inline-block',
                  marginLeft: '8px',
                  marginTop: '8px',
                  width: '12px',
                }}
              />
            </a>
            <br />
            <a
              href={linkToKaminashiIDManualPage}
              target="_blank"
              rel="noreferrer"
            >
              カミナシID操作マニュアル
              <ExportOutlined
                css={{
                  display: 'inline-block',
                  marginLeft: '8px',
                  marginTop: '8px',
                  width: '12px',
                }}
              />
            </a>
          </>
        ) : (
          <>
            その他のユーザー情報の編集・ユーザーの削除は管理者に依頼してください。
          </>
        )}
      </div>

      <FooterButton
        spinning={isLoadingUsers}
        onCancel={() => dispatchChangeIsShowEditUserDrawer(false)}
        onSubmit={onSubmit}
      />
    </>
  )
}

export default EditUser
