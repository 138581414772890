import styled from 'styled-components'

export const FormGroup = styled.div`
  margin-bottom: 25px;
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`
