import {
  MasterFieldDef,
  ProductMasterApi,
} from '@ulysses-inc/harami_api_client'
import { call, put, takeEvery } from 'redux-saga/effects'
import interceptionsActions from 'src/state/ducks/interceptions/actions'
import {
  getProductMasterFieldDefsFailed,
  getProductMasterFieldDefsRequested,
  getProductMasterFieldDefsSucceeded,
} from 'src/state/ducks/productMasterFieldDefs/slice'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import {
  handleHTTPError,
  isHTTPError,
} from 'src/state/middleware/saga/handleHttpError'

const getProductMasterFieldDefsRequest = () => {
  return baseClient
    .getApi(ProductMasterApi)
    .getProductMasterFieldDefs()
    .then(response => response)
    .catch(handleHTTPError)
}

function* getProductMasterFieldDefs() {
  try {
    const responseProductMasterFieldDefs: MasterFieldDef[] = yield call(
      getProductMasterFieldDefsRequest,
    )
    yield put(
      getProductMasterFieldDefsSucceeded(responseProductMasterFieldDefs),
    )
  } catch (error) {
    if (isHTTPError(error) || error instanceof Error) {
      yield put(interceptionsActions.handleHttpError(error))
    } else {
      yield put(
        interceptionsActions.handleHttpError(
          new Error('Unknown error occurred'),
        ),
      )
    }
    yield put(getProductMasterFieldDefsFailed())
  }
}

const sagas = [
  takeEvery(getProductMasterFieldDefsRequested.type, getProductMasterFieldDefs),
]

export default sagas
