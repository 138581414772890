import { LockTwoTone } from '@ant-design/icons'
import { Result } from 'antd'

export const NoPermissionScreen = () => {
  return (
    <div>
      <Result
        icon={<LockTwoTone />}
        title="現在のロール（役割）では表示できません"
        subTitle="表示するには管理者に確認してください"
      />
      <a style={{ display: 'flex', justifyContent: 'center' }} href="/login">
        ログイン画面へ戻る
      </a>
    </div>
  )
}
