import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Typography } from 'antd'
import { FC } from 'react'
import { SwitchDisabledColor } from 'src/features/theme/KdsThemeColor'
import { ScoreSwitchWrap, ToggleWrap } from './EditTemplateOption.styled'

const { Text } = Typography

interface OptionToggleSwitchProps {
  isChecked: boolean
  onChange: (checked: boolean) => void
  label: string
}
const OptionToggleSwitch: FC<OptionToggleSwitchProps> = ({
  isChecked,
  onChange,
  label,
}) => (
  <ToggleWrap>
    <ScoreSwitchWrap>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={isChecked}
        onChange={onChange}
        style={isChecked ? undefined : { backgroundColor: SwitchDisabledColor }}
      />
    </ScoreSwitchWrap>
    <div>
      <Text strong>{label}</Text>
    </div>
  </ToggleWrap>
)

export default OptionToggleSwitch
