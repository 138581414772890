import { PlaceNode, ReportListItem } from '@ulysses-inc/harami_api_client'
import { Layout } from 'antd'
import { isSameDay, isToday } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import dateUtil from 'src/exShared/util/date'
import { GlobalNavigation } from 'src/features/globalNavigation/GlobalNavigation'
import { TryNewScreenToggler } from 'src/features/reports/listBeta/featurePreview/TryNewScreenToggler'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import placesOperations from 'src/state/ducks/places/operations'
import reportsOperations from 'src/state/ducks/reports/operations'
import { GetReportsFilter } from 'src/state/ducks/reports/types'
import { RootState } from 'src/state/store'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import { isNullish } from 'src/util/isNullish'
import PlaceGroupsTreeModal from './PlaceGroupsTreeModal'
import {
  ReportsPagination,
  ReportsTable,
  ReportsTableWidth,
} from './Reports.styled'
import ReportsFilter from './ReportsFilter'
import ReportsTableHeader from './ReportsTableHeader'
import ReportsTableRow from './ReportsTableRow'

export type TReportListItemWithId = Omit<ReportListItem, 'id'> &
  Required<Pick<ReportListItem, 'id'>>

export const isReportListItemWithId = (
  v: ReportListItem,
): v is TReportListItemWithId => !isNullish(v.id)

const removeDuplicateDate = (reports: ReportListItem[]): Date[] => {
  const dates: Date[] = []
  reports.forEach(report => {
    if (
      !dates.some(
        date => report.reportDate && isSameDay(date, report.reportDate),
      )
    ) {
      if (report.reportDate) {
        dates.push(report.reportDate)
      }
    }
  })
  return dates
}

const createReports = (reports: ReportListItem[]) => {
  return removeDuplicateDate(reports).map((date: Date) => {
    const sectionDate =
      (isToday(date) ? '本日 ' : '') +
      dateUtil.formatYYYYMMDDEEE(new Date(date))
    const dateReports = reports
      .filter(report => report.reportDate && isSameDay(report.reportDate, date))
      .sort((left, right) => {
        if (left.updatedAt === undefined || right.updatedAt === undefined) {
          return 1
        }
        return left.updatedAt.getTime() > right.updatedAt.getTime() ? -1 : 1
      })
    return {
      sectionDate: sectionDate,
      dateReports: dateReports,
    }
  })
}

export const flattenNodes = (nodes: PlaceNode[]) => {
  const flatNodes: PlaceNode[] = []
  if (nodes && nodes.length > 0) {
    nodes.forEach(node => {
      flatNodes.push(node)
      if (node.nodes && node.nodes.length)
        flatNodes.push(...flattenNodes(node.nodes as PlaceNode[]))
    })
  }
  return flatNodes
}

const ReportsScene = () => {
  const {
    isLoading,
    reports: flatReports,
    filter,
    count,
    request,
  } = useSelector((state: RootState) => state.reportsState.reports)
  const reports = createReports(flatReports)

  const dispatch = useDispatch()
  const getFilterData = () => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
  }
  const changeReportsPage = (
    page: number,
    pageSize?: number,
    filter?: GetReportsFilter,
  ) => {
    reportsOperations.changeReportsPage(dispatch, page, pageSize, filter)
  }
  const changeReportsSize = (pageSize: number, filter?: GetReportsFilter) => {
    reportsOperations.changeReportsSize(dispatch, pageSize, filter)
  }
  const updatePagination = (limit: number, offset: number) => {
    reportsOperations.updatePagination(dispatch, limit, offset)
  }

  const selectedReportIdsState = useState(new Set<number>())
  const [selectedReportIds, setSelectedReportIds] = selectedReportIdsState
  const isVisiblePlaceModalState = useState<boolean>(false)
  const [, setIsVisiblePlaceModal] = isVisiblePlaceModalState
  const [reportGetTrigger, setReportGetTrigger] = useState<boolean>(false)
  const {
    // localstorageから読み出したフィルタリング設定＆ページネーション設定
    filters,
    setListFilter,
  } = useListFilter()

  useEffect(() => {
    getFilterData()
    setReportGetTrigger(!reportGetTrigger)

    // マウント時に、localstorageから読み出したフィルタリング設定＆ページネーション設定が存在する場合は、
    // それをstoreにセットする。
    //
    // - レポート個別ページからの戻り時
    //   => フィルタリング設定＆ページネーション設定のどちらもリストアされる
    //
    // - GlobalNavigationから別画面に遷移し、戻ってきたとき
    //   => フィルタリング設定のみリストアされる。
    //      これは、サイドメニュー(GlobalNavigation)をクリックして別画面に遷移したときに、
    //      ページネーションの設定をリセットしているため。
    if (filters) {
      const reportFilter = filters.reports
      updatePagination(
        reportFilter?.pagination?.limit ?? 25,
        reportFilter?.pagination?.offset ?? 0,
      )
    }

    // 画面破棄時に state を初期化する
    return () => reportsOperations.resetReportsTemplates(dispatch)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // ユーザのUI操作によりstoreのフィルタリング設定＆ページネーション設定に変更があった場合は、
    // localstorageに保存しておく。
    setListFilter({
      ...(filters ?? {}),
      reports: {
        pagination: request,
        filter: filters?.reports?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.limit, request.offset])

  useEffect(() => {
    // レポート一覧が更新された際、selectedTemplateIdsに不要なものが含まれていたら削除する
    const prunedSelectedReportIds = flatReports
      // idがないことは想定されない。以降、このコメントは省略。
      .filter((report): report is TReportListItemWithId =>
        isReportListItemWithId(report),
      )
      .filter(report => selectedReportIds.has(report.id))
      .map(report => report.id)

    setSelectedReportIds(new Set(prunedSelectedReportIds))

    // 無限ループに入るので`selectedReportIds`はdepsから外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatReports])

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(request.limit, request.offset, count)

  return (
    // TODO: サイドメニュー描写の責務を`Routes.tsx`に移植する
    <GlobalNavigation
      // サイドバーのメニューをクリックして他画面へ遷移した時に発動する。
      // `resetPagination`ではなく`onClickMenuItem`くらいが正しい名前と思われる。
      resetPagination={() => {
        // フィルタリング設定＆ページネーション設定をlocalstorageに保存しておく。その際、
        // - フィルタリング設定はそのまま保存する
        // - ページネーション設定（ページあたりの表示上限件数と、オフセット）は初期値にリセットしておく
        setListFilter({
          ...filters,
          reports: {
            pagination: { limit: 25, offset: 0 },
            filter: filters?.reports?.filter,
          },
        })
      }}
    >
      <Layout>
        <Header rightSlot={<TryNewScreenToggler />}>
          <HeaderTitle title="レポート" />
        </Header>
        <ReportsFilter
          {...{ selectedReportIds, setIsVisiblePlaceModal, reportGetTrigger }}
        />
        <ReportsTable>
          <ReportsTableWidth>
            <ReportsTableHeader {...{ flatReports, selectedReportIdsState }} />
            {isLoading ? (
              <Loading />
            ) : (
              reports.map((report, index) => (
                <ReportsTableRow
                  key={index}
                  {...{ report, selectedReportIdsState }}
                />
              ))
            )}
          </ReportsTableWidth>
        </ReportsTable>
        <ReportsPagination
          showSizeChanger
          current={currentPage}
          pageSizeOptions={['10', '25', '50', '100']}
          pageSize={request.limit}
          defaultCurrent={1}
          total={count}
          onChange={(pageNum, pageSize) =>
            changeReportsPage(pageNum, pageSize, filter)
          }
          onShowSizeChange={(_, size) => changeReportsSize(size, filter)}
        />
      </Layout>
      <PlaceGroupsTreeModal
        {...{ isVisiblePlaceModalState, selectedReportIdsState }}
      />
    </GlobalNavigation>
  )
}

export default ReportsScene
