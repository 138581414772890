import { useQuery } from '@tanstack/react-query'
import { ProductMasterApi } from '@ulysses-inc/harami_api_client'
import { baseClient } from 'src/state/middleware/saga/baseClient'
import { queryKeys } from './queryKeys'

export const useQueryProducts = () => {
  return useQuery({
    queryKey: queryKeys.products,
    queryFn: () => baseClient.getApi(ProductMasterApi).getProducts(),
  })
}
