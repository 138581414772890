import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { IndicatedIssueLabel } from '@ulysses-inc/harami_api_client'
import { Button, Layout, List, Modal, Row, Tag, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import Loading from 'src/components/loading/Loading'
import EditIndicatedIssueLabelDrawer from 'src/features/improves/labels/EditIndicatedIssueLabelDrawer'
import {
  Danger,
  ModalDeleteButtonColor,
  Primary,
  White,
} from 'src/features/theme/KdsThemeColor'
import { primitiveColors } from 'src/features/theme/primitiveColors'
import indicatedIssuesOperations from 'src/state/ducks/indicatedIssues/operations'
import { RootState } from 'src/state/store'
import styled from 'styled-components'

const { Content } = Layout
const { Text } = Typography

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.backgroundColor};
`

const THEME_COLORS = {
  PageBackgroudColor: primitiveColors.gray._1,
  TableBorder: primitiveColors.gray._3,
  DefaultFontColor: primitiveColors.gray._9,
}

interface StateProps {
  indicatedIssueLabels: Array<IndicatedIssueLabel>
  isLoading: boolean
}

interface DispatchProps {
  getIndicatedIssueLabels: () => void
  openAddIndicatedIssueLabel: () => void
  openEditIndicatedIssueLabel: (
    indicatedIssueLabel: IndicatedIssueLabel,
  ) => void
  deleteIndicatedIssueLabel: (uuid: string) => void
}

type IndicatedIssueLabelsProps = StateProps & DispatchProps

const useProps = (): IndicatedIssueLabelsProps => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)

  return {
    // -----------------------------------
    // StateProps
    // -----------------------------------

    indicatedIssueLabels:
      state.indicatedIssuesState.indicatedIssueLabel.indicatedIssueLabels,
    isLoading: state.indicatedIssuesState.indicatedIssueLabel.isLoading,

    // -----------------------------------
    // DispatchProps
    // -----------------------------------

    getIndicatedIssueLabels: () => {
      indicatedIssuesOperations.getIndicatedIssueLabels(dispatch)
    },
    openAddIndicatedIssueLabel: () => {
      indicatedIssuesOperations.resetEditIndicatedIssueLabel(dispatch)
      indicatedIssuesOperations.changeIsShowDrawer(dispatch, true)
    },
    openEditIndicatedIssueLabel: (indicatedIssueLabel: IndicatedIssueLabel) => {
      indicatedIssuesOperations.changeEditIndicatedIssueLabel(
        dispatch,
        indicatedIssueLabel,
      )
      indicatedIssuesOperations.changeIsShowDrawer(dispatch, true)
    },
    deleteIndicatedIssueLabel: (uuid: string) => {
      indicatedIssuesOperations.deleteIndicatedIssueLabel(dispatch, uuid)
    },
  }
}

const EditIndicatedIssueLabels = () => {
  // react-reduxのconnect関数の利用をやめた際のなごり
  // (今後このコンポーネントを触ることがあればそのときに解体する)
  const exProps = useProps()

  useEffect(() => {
    exProps.getIndicatedIssueLabels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const history = useHistory()
  const [activeIndicatedIssueLabelUUID, setActiveIndicatedIssueLabelUUID] =
    useState<string>()

  return (
    <Layout>
      <Header>
        <HeaderBackButton onClick={() => history.goBack()} />
        <HeaderTitle title="ラベル" />
      </Header>
      <Content
        style={{
          backgroundColor: THEME_COLORS.PageBackgroudColor,
        }}
      >
        {exProps.isLoading ? (
          <Loading />
        ) : (
          <>
            <Row
              justify="end"
              css={css`
                margin: 15px;
              `}
            >
              <Button
                key="1"
                type="primary"
                style={{
                  borderColor: Primary,
                  backgroundColor: Primary,
                  borderRadius: 4,
                }}
                onClick={() => exProps.openAddIndicatedIssueLabel()}
              >
                ラベルを追加
              </Button>
            </Row>
            <Row
              justify="center"
              css={css`
                margin: 15px;
                min-width: 600px;
              `}
            >
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    height: 46,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    background: White,
                  }}
                >
                  <div
                    style={{
                      width: '40%',
                      fontWeight: 'bold',
                      paddingLeft: '15px',
                      color: THEME_COLORS.DefaultFontColor,
                    }}
                  >
                    ラベル
                  </div>
                  <div
                    style={{
                      width: '20%',
                      fontWeight: 'bold',
                      paddingLeft: '15px',
                      color: THEME_COLORS.DefaultFontColor,
                    }}
                  >
                    色
                  </div>
                  <div
                    style={{
                      width: '40%',
                      fontWeight: 'bold',
                      paddingLeft: '15px',
                      color: THEME_COLORS.DefaultFontColor,
                    }}
                  >
                    プレビュー
                  </div>
                </div>
                <List
                  itemLayout="horizontal"
                  dataSource={exProps.indicatedIssueLabels}
                  renderItem={(indicatedIssueLabel: IndicatedIssueLabel) => (
                    <List.Item
                      style={{
                        position: 'relative',
                        minHeight: '46px',
                        backgroundColor: White,
                        padding: '8px 0',
                        borderTop: `1px ${THEME_COLORS.TableBorder} solid`,
                        borderBottom: 'none',
                      }}
                      onMouseEnter={() =>
                        setActiveIndicatedIssueLabelUUID(
                          indicatedIssueLabel.uuid,
                        )
                      }
                      onMouseLeave={() =>
                        setActiveIndicatedIssueLabelUUID(undefined)
                      }
                    >
                      <div style={{ width: '40%', paddingLeft: '15px' }}>
                        <Text>{indicatedIssueLabel.name}</Text>
                      </div>
                      <div style={{ width: '20%', paddingLeft: '15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Circle
                            theme={{
                              backgroundColor: indicatedIssueLabel.color,
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: '40%',
                          paddingLeft: '15px',
                          paddingRight: '5px',
                        }}
                      >
                        <Tag
                          style={{
                            verticalAlign: 'top',
                            textOverflow: 'ellipsis',
                            maxWidth: '100%',
                            margin: '5px 0',
                            overflow: 'hidden',
                          }}
                          color={indicatedIssueLabel.color}
                        >
                          {indicatedIssueLabel.name}
                        </Tag>
                      </div>
                      {activeIndicatedIssueLabelUUID ===
                        indicatedIssueLabel.uuid && (
                        <div
                          style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            flexDirection: 'row',
                            height: '100%',
                            width: '240px',
                            right: 0,
                          }}
                        >
                          <Button
                            style={{
                              height: '100%',
                              width: '50%',
                              borderRadius: 0,
                              backgroundColor: Primary,
                              borderColor: Primary,
                              color: White,
                            }}
                            onClick={() =>
                              exProps.openEditIndicatedIssueLabel(
                                indicatedIssueLabel,
                              )
                            }
                          >
                            <EditOutlined />
                            編集
                          </Button>
                          <Button
                            style={{
                              height: '100%',
                              width: '50%',
                              borderRadius: 0,
                              color: White,
                              borderColor: Danger,
                              backgroundColor: Danger,
                            }}
                            onClick={() => {
                              Modal.confirm({
                                onOk: () => {
                                  if (indicatedIssueLabel.uuid) {
                                    exProps.deleteIndicatedIssueLabel(
                                      indicatedIssueLabel.uuid,
                                    )
                                  }
                                },
                                title: `「${indicatedIssueLabel.name}」の削除を実行しますがよろしいですか？`,
                                okText: '削除',
                                cancelText: 'キャンセル',
                                okButtonProps: {
                                  style: {
                                    backgroundColor: ModalDeleteButtonColor,
                                    border: 'none',
                                  },
                                },
                              })
                            }}
                          >
                            <DeleteOutlined />
                            削除
                          </Button>
                        </div>
                      )}
                    </List.Item>
                  )}
                />
              </div>
            </Row>
          </>
        )}
      </Content>
      <EditIndicatedIssueLabelDrawer />
    </Layout>
  )
}

export default EditIndicatedIssueLabels
