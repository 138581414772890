import { HomeOutlined, TagOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  AddReportsExportsRequestBody,
  FeatureNameEnum,
} from '@ulysses-inc/harami_api_client'
import { Input, Modal, Select, Spin, Typography, message } from 'antd'
import { addDays, format, parseISO } from 'date-fns'
import moment from 'moment'
import { ReactNode, useEffect } from 'react'
import {
  Controller,
  DefaultValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { MomentDatePicker } from 'src/components/datepicker/MomentDatePicker'
import { ErrorMessage } from 'src/components/errorMessage/ErrorMessage'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { ReportExportDetailHeader } from 'src/features/reportExport/detail/ReportExportDetailHeader'
import { ReportExportDetailSendConfirmation } from 'src/features/reportExport/detail/ReportExportDetailSendConfirmation'
import { useMutationAddReportExport } from 'src/features/reportExport/detail/api/useMutationAddReportExport'
import { useQueryTemplates } from 'src/features/reportExport/detail/api/useQueryTemplates'
import { TitleAndContent } from 'src/features/reportExport/detail/components/TitleAndContent'
import { ellipsizeStrings } from 'src/features/reportExport/detail/utils/ellipsizeStrings'
import { useQueryUserPlaceNodes } from 'src/features/tanStackQuery/commonQueries/useQueryUserPlaceNodes'
import { ModalDeleteButtonColor } from 'src/features/theme/KdsThemeColor'
import yup from 'src/features/validation/yup'
import { useTogglable } from 'src/hooks/useTogglable'
import { countMultibyteChars } from 'src/util/countMultibyteChars'
import { toDateString } from 'src/util/datetime/toDateString'

const { Option } = Select

const formValuesSchema = yup
  .object({
    endDate: yup
      .string() // '2020-01-01'の形式で持つ。日付は曖昧な概念なので、曖昧なまま持つことでシンプルさを保つ。
      .nullable()
      .required('終了日を入力してください')
      .test(
        'is-greater-than-or-equal-to',
        '終了日は開始日よりも後の日付に設定してください',
        function (endDate) {
          const { startDate } = this.parent
          if (!endDate || !startDate) return true
          return parseISO(endDate) >= parseISO(startDate)
        },
      )
      .test(
        'max-92-days',
        '指定できる最長期間は92日間です',
        function (endDate) {
          const { startDate } = this.parent
          if (!endDate || !startDate) return true
          return (
            // 日付選択UIについては、内部的には選択した日付のみ（時刻を含まない）を値として持っており、
            // 最終的にAPIに投げる直前に、終了日を`選択した日付の0:00+24時間`に設定している。
            // それをふまえた上で、APIにリクエストする最大期間は92日間(≒3ヶ月)としたいので、
            // 下記で指定する値は91日間とする必要がある。
            // https://kaminashi.atlassian.net/browse/HONE-9
            parseISO(endDate).valueOf() - parseISO(startDate).valueOf() <=
            91 * 24 * 60 * 60 * 1000
          )
        },
      ),
    outputFileName: yup
      .string()
      .required('出力ファイル名を入力してください')
      .test(
        'max-60-multibyte-chars',
        '出力ファイル名は60文字以下である必要があります',
        function (outputFileName) {
          if (!outputFileName) return true
          return countMultibyteChars(outputFileName) <= 60
        },
      ),
    placeNodeUuids: yup
      .array(yup.string().required())
      .min(1, '現場を選択してください')
      .required(),
    startDate: yup
      .string() // '2020-01-01'の形式で持つ。日付は曖昧な概念なので、曖昧なまま持つことでシンプルさを保つ。
      .nullable()
      .required('開始日を入力してください'),
    templateId: yup.number().nullable().required('ひな形を選択してください'),
  })
  .required()

type ValidatedFormValues = yup.InferType<typeof formValuesSchema>

const defaultFormValues: DefaultValues<ValidatedFormValues> = {
  endDate: undefined,
  outputFileName: '',
  placeNodeUuids: [],
  startDate: undefined,
  templateId: undefined,
}

/**
 * レポート出力依頼画面
 */
export const ReportExportDetail = () => {
  const history = useHistory()

  const { canUse: isLabelCheckEnabled } = useFeature(
    FeatureNameEnum.LABEL_CHECK,
  )

  // Queries
  const templatesQuery = useQueryTemplates()
  const userBelongingPlacesQuery = useQueryUserPlaceNodes()

  const isPending =
    templatesQuery.isPending || userBelongingPlacesQuery.isPending

  const isError = templatesQuery.isError || userBelongingPlacesQuery.isError
  const userHasAssignedPlaces =
    userBelongingPlacesQuery.data && userBelongingPlacesQuery.data?.length > 0

  // Mutations
  const { mutate: addReportExportMutation, isPending: isAddingReportExport } =
    useMutationAddReportExport()

  const sendConfirmationModal = useTogglable()

  // MEMO: useForm() から取り出した watch で以下のようにするとデバッグ時に便利
  // console.log(JSON.stringify(watch(), null, 2))
  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    setValue,
    trigger,
    watch,
  } = useForm<ValidatedFormValues>({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver: yupResolver(formValuesSchema),
  })

  // レンダリングが頻発するためreact-hook-formの使い方としては非推奨だが、
  // この画面の要件を満たすには必須なのでやむなく使っている
  const formValues = watch()

  const stringifiedFormValues = {
    endDate: ((): string => {
      if (!formValues.endDate) return ''
      return format(parseISO(formValues.endDate), 'yyyyMMdd')
    })(),
    endDateWithSlash: ((): string => {
      if (!formValues.endDate) return ''
      return format(parseISO(formValues.endDate), 'yyyy/MM/dd')
    })(),
    placeNames: ((): string => {
      const placeNames = formValues.placeNodeUuids.map(
        placeNodeUuid =>
          userBelongingPlacesQuery.data?.find(
            place => place.uuid === placeNodeUuid,
          )?.name ?? '',
      )
      return ellipsizeStrings(placeNames)
    })(),
    templateName: ((): string => {
      if (!formValues.templateId || !templatesQuery.data) return ''
      return (
        templatesQuery.data.find(
          template => template.id === formValues.templateId,
        )?.name ?? ''
      )
    })(),
    startDate: ((): string => {
      if (!formValues.startDate) return ''
      return format(parseISO(formValues.startDate), 'yyyyMMdd')
    })(),
    startDateWithSlash: ((): string => {
      if (!formValues.startDate) return ''
      return format(parseISO(formValues.startDate), 'yyyy/MM/dd')
    })(),
  }

  // 出力ファイル名を必要に応じて自動でセットする
  useEffect(() => {
    if (
      !stringifiedFormValues.endDate ||
      !stringifiedFormValues.startDate ||
      !stringifiedFormValues.templateName
    )
      return

    setValue(
      'outputFileName',
      `${stringifiedFormValues.templateName}_${stringifiedFormValues.startDate}-${stringifiedFormValues.endDate}`,
    )
    trigger('outputFileName').then()
  }, [
    setValue,
    stringifiedFormValues.endDate,
    stringifiedFormValues.startDate,
    stringifiedFormValues.templateName,
    trigger,
  ])

  const backToPreviousScreen = () => {
    const execute = () => history.push('/reportExports')
    const message = '変更した内容が破棄されますがよろしいですか？'
    if (!isDirty) {
      execute()
      return
    }
    Modal.confirm({
      cancelText: 'キャンセル',
      okButtonProps: {
        style: {
          backgroundColor: ModalDeleteButtonColor,
          border: 'none',
        },
      },
      okText: 'はい',
      onOk: () => execute(),
      title: message,
      modalRender: content => (
        <div role="alertdialog" aria-label={message}>
          {content}
        </div>
      ),
    })
  }

  const onSubmit: SubmitHandler<ValidatedFormValues> = validatedFormValues => {
    const requestBody: AddReportsExportsRequestBody = {
      fileName: validatedFormValues.outputFileName,
      // Dateに関しては、OpenAPI Clientにおいて必ずUTCのISO8660形式の文字列に変換されてから送信される
      // (e.g. `2023-05-30T15:00:00:000Z`)
      filterCondition: {
        // 終点はユーザが指定した日の午前0:00の24時間後になるようにする
        endDateTime: addDays(parseISO(validatedFormValues.endDate), 1),
        placeNodeUuidList: validatedFormValues.placeNodeUuids,
        startDateTime: parseISO(validatedFormValues.startDate),
        templateId: validatedFormValues.templateId,
      },
    }

    addReportExportMutation(requestBody, {
      onSuccess: () => {
        message
          .success({
            // antdのスタイルをscssで微調整するにあたり影響範囲を狭めるため
            content:
              '現在レポートを出力中です。時間をおいてから再読み込みしてください。',
            duration: 3,
          })
          .then()
        history.push('/reportExports')
      },
      onError: () =>
        Modal.error({
          title: 'エラーが発生しました',
        }),
    })
  }

  const renderWithCommonContainer = (children: ReactNode) => (
    <div css={styles.container}>
      <ReportExportDetailHeader
        onBackButtonClicked={backToPreviousScreen}
        onOutputButtonClicked={handleSubmit(sendConfirmationModal.open)}
      />

      <div css={styles.contentArea}>{children}</div>
    </div>
  )

  if (isPending) {
    return renderWithCommonContainer(
      <div aria-label="データを取得中" role="progressbar">
        <Spin size="large" />
      </div>,
    )
  }

  if (isError) {
    return renderWithCommonContainer(
      <ErrorMessage noMargin>エラーが発生しました</ErrorMessage>,
    )
  }

  return renderWithCommonContainer(
    <form css={styles.contentGrid} aria-label="新規でレポート出力">
      <TitleAndContent title="ひな形">
        <Controller
          control={control}
          name="templateId"
          render={({ field }) => (
            <Select
              {...field}
              css={styles.templateSelect}
              optionFilterProp="label"
              options={templatesQuery.data.map(template => ({
                value: template.id,
                label: template.name,
              }))}
              placeholder="ひな形を選択してください"
              showSearch
              value={
                //nullを与えると型エラーになるためやむなく
                field.value ?? undefined
              }
            />
          )}
        ></Controller>
        <ErrorMessage>{errors['templateId']?.message}</ErrorMessage>
        {isLabelCheckEnabled === 'yes' && (
          <Typography>
            AIラベル検査(β版)を含むひな形は現在出力できません
          </Typography>
        )}
      </TitleAndContent>

      <TitleAndContent title="現場">
        <Controller
          name="placeNodeUuids"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                css={styles.placeSelect}
                disabled={!userHasAssignedPlaces}
                mode="multiple"
                optionFilterProp="label"
                placeholder={
                  userHasAssignedPlaces ? '現場を選択してください' : ''
                }
                showSearch
              >
                {userBelongingPlacesQuery.data.map(loginPlace => (
                  <Option
                    key={loginPlace.uuid}
                    value={loginPlace.uuid}
                    label={loginPlace.name}
                  >
                    <div css={styles.placeNodesOptionInner}>
                      <div css={styles.placeNodesOptionIcon}>
                        {loginPlace.type === 'placeGroup' ? (
                          <TagOutlined />
                        ) : (
                          <HomeOutlined />
                        )}
                      </div>
                      <span>{loginPlace.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
              {userHasAssignedPlaces ? (
                <ErrorMessage>{errors['placeNodeUuids']?.message}</ErrorMessage>
              ) : (
                <ErrorMessage>
                  ユーザーを編集し、所属する現場を設定してください
                </ErrorMessage>
              )}
            </>
          )}
        ></Controller>
      </TitleAndContent>

      <TitleAndContent title="期間">
        <div css={styles.datePickerRow}>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <MomentDatePicker
                {...field}
                css={styles.datePicker}
                onChange={value => {
                  if (value) {
                    field.onChange(toDateString(value.toDate()))
                  } else {
                    field.onChange(null)
                  }
                  // 開始日と終了日の組み合わせで判定するバリデーションルールがある。
                  // そのルールは終了日のフィールドに対してのみ設定されている。
                  // よって、既に終了日を入力済みの場合は、ここで手動でバリデーションを実行する必要がある。
                  if (dirtyFields.endDate) {
                    trigger('endDate').then()
                  }
                }}
                value={field.value ? moment(field.value) : null}
                placeholder="YYYY-MM-DD"
              />
            )}
          />

          <div css={styles.tilda}>〜</div>

          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <MomentDatePicker
                {...field}
                css={styles.datePicker}
                onChange={value => {
                  if (value) {
                    field.onChange(toDateString(value.toDate()))
                  } else {
                    field.onChange(null)
                  }
                }}
                value={field.value ? moment(field.value) : null}
                placeholder="YYYY-MM-DD"
              />
            )}
          />
        </div>
        <ErrorMessage>{errors['startDate']?.message}</ErrorMessage>
        <ErrorMessage>{errors['endDate']?.message}</ErrorMessage>
      </TitleAndContent>

      <TitleAndContent title="出力ファイル名">
        <Controller
          control={control}
          name="outputFileName"
          render={({ field }) => (
            <Input
              {...field}
              aria-label="出力ファイル名"
              css={styles.outputFileNameInput}
            />
          )}
        />
        <ErrorMessage>{errors['outputFileName']?.message}</ErrorMessage>
      </TitleAndContent>

      <ReportExportDetailSendConfirmation
        isSendingData={isAddingReportExport}
        onCancel={sendConfirmationModal.close}
        onOk={handleSubmit(onSubmit)}
        open={sendConfirmationModal.isOpen}
        sendingDataAsString={{
          dateFromTo: `${stringifiedFormValues.startDateWithSlash} 〜 ${stringifiedFormValues.endDateWithSlash}`,
          outputFileName: formValues.outputFileName,
          places: stringifiedFormValues.placeNames,
          templateName: stringifiedFormValues.templateName,
        }}
      />
    </form>,
  )
}

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
  `,
  contentArea: css`
    padding: 32px 24px;
  `,
  contentGrid: css`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
  `,
  templateSelect: css`
    width: 410px;
  `,
  placeSelect: css`
    width: 410px;
  `,
  datePickerRow: css`
    align-items: center;
    display: flex;
    width: 410px;
  `,
  datePicker: css`
    flex: 1;
  `,
  tilda: css`
    margin: 0 8px;
  `,
  outputFileNameInput: css`
    width: 520px;
  `,
  placeNodesOptionInner: css`
    display: flex;
    align-items: center;
  `,
  placeNodesOptionIcon: css`
    margin-right: 8px;
  `,
}
