import {
  MultipleChoice,
  MultipleChoiceGroup,
  ReportNodeSchema,
  ReportQuestion,
} from '@ulysses-inc/harami_api_client'
import { getAllQuestions } from 'src/exShared/util/reportResult/nodes'

const sumAllTotalScores = (nodes: {
  [key: number]: ReportNodeSchema
}): number => {
  const questions = getAllQuestions(nodes)
  // The ids of the option selected by the user to be excluded from scoring.
  const excludedIDs = questions
    .filter(
      (q: ReportQuestion) =>
        !!q?.responseAnswer?.multipleChoice?.isExcludedFromScoring,
    )
    .map((q: ReportQuestion) => q?.responseAnswer?.multipleChoice?.id)
  return questions
    .map((question: ReportQuestion) => {
      if (question === null) return 0
      const mChoice: MultipleChoiceGroup | undefined =
        (question.responseMultipleChoices ?? [])[0]
      if (excludedIDs.includes(mChoice.id)) return 0
      const mChoiceResponses: MultipleChoice[] = mChoice?.responses ?? []
      if (mChoiceResponses.length === 0) return 0
      return mChoiceResponses
        .filter((m: MultipleChoice) => !m.isExcludedFromScoring)
        .map((m: MultipleChoice) => m.score ?? 0)
        .reduce((prev, cur) => Math.max(prev, cur), 0)
    })
    .reduce((prev, cur) => prev + cur, 0)
}

const sumAllResultScores = (nodes: {
  [key: number]: ReportNodeSchema
}): number => {
  const questions = getAllQuestions(nodes)
  return questions
    .map((question: ReportQuestion) => {
      const mChoice = question?.responseAnswer?.multipleChoice
      const score = !mChoice?.isExcludedFromScoring ? (mChoice?.score ?? 0) : 0
      return score
    })
    .reduce((prev, cur) => prev + cur, 0)
}

export { sumAllResultScores, sumAllTotalScores }
