import {
  PlaceNode,
  PlaceNodeTypeEnum,
  ScheduleFrequencyEnum,
  ScheduleTypeEnum,
} from '@ulysses-inc/harami_api_client'
import Yup from 'src/features/validation/yup'

export const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required().label('スケジュール名'),
  templates: Yup.array().min(1).label('ひな形'),
  placeNodes: Yup.array().required().min(1).label('現場'),
  frequency: Yup.number().required().label('頻度'),
  frequencyWeekly: Yup.object().when('frequency', {
    is: ScheduleFrequencyEnum.Weekly,
    then: Yup.object({
      every: Yup.number().min(1).required().label('周期'),
    }).when('type', {
      is: ScheduleTypeEnum.Date,
      then: Yup.object({
        dayOfWeeks: Yup.array().min(1).required().label('曜日'),
      }),
    }),
  }),
  frequencyMonthly: Yup.object().when('frequency', {
    is: ScheduleFrequencyEnum.Monthly,
    then: Yup.object({
      every: Yup.number().min(1).required().label('周期'),
    }).when('type', {
      is: ScheduleTypeEnum.Date,
      then: Yup.object({
        weekOfMonth: Yup.number().required().label('週目'),
        dayOfWeek: Yup.number().required().label('曜日'),
      }),
    }),
  }),
  frequencyCustom: Yup.object().when('frequency', {
    is: ScheduleFrequencyEnum.Custom,
    then: Yup.object({
      every: Yup.number().min(1).max(180).required().label('周期'),
    }),
  }),
  startReportDate: Yup.date().required().label('開始日'),
  endReportDate: Yup.date().label('終了日'),
  visibleEndReportDate: Yup.boolean(),
  startTime: Yup.date().when('type', {
    is: ScheduleTypeEnum.Date,
    then: Yup.date().required().label('開始時刻'),
  }),
  endTime: Yup.date().when('type', {
    is: ScheduleTypeEnum.Date,
    then: Yup.date().required().label('終了時刻'),
  }),
  isCopied: Yup.boolean().default(false),
})

export const isPlace = (placeNodeId: string, allPlaceNodes: PlaceNode[]) => {
  const node = allPlaceNodes.find(node => node.uuid === placeNodeId)
  return node && node.type === PlaceNodeTypeEnum.Place
}

export const flattenNodes = (nodes: PlaceNode[]) => {
  const flatNodes: PlaceNode[] = []
  if (nodes && nodes.length > 0) {
    nodes.forEach(node => {
      flatNodes.push(node)
      if (node.nodes && node.nodes.length)
        flatNodes.push(...flattenNodes(node.nodes as PlaceNode[]))
    })
  }
  return flatNodes
}
