import { css } from '@emotion/react'
import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { FC, SVGProps } from 'react'
import { useSelector } from 'react-redux'
import DateSvg from 'src/assets/icons/date.svg?react'
import EmployeeSvg from 'src/assets/icons/employee.svg?react'
import FormulaSvg from 'src/assets/icons/formula.svg?react'
import ImageSvg from 'src/assets/icons/image.svg?react'
import InformationSvg from 'src/assets/icons/information.svg?react'
import LabelCheckSvg from 'src/assets/icons/label_check.svg?react'
import NumberSvg from 'src/assets/icons/number.svg?react'
import SignatureSvg from 'src/assets/icons/signature.svg?react'
import TextSvg from 'src/assets/icons/text.svg?react'
import TimeMeasurement from 'src/assets/icons/time_measurement.svg?react'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { selectIsGridLayout } from 'src/state/ducks/templates/selectors'

type MenuItem = {
  key: string
  label: string
  SvgComponent: FC<SVGProps<SVGSVGElement>>
}

const NumberItem: MenuItem = {
  key: 'number',
  label: '数値',
  SvgComponent: NumberSvg,
}

const SignatureItem: MenuItem = {
  key: 'signature',
  label: '署名',
  SvgComponent: SignatureSvg,
}

const TextItem: MenuItem = {
  key: 'text',
  label: 'テキスト',
  SvgComponent: TextSvg,
}

const ResultImageItem: MenuItem = {
  key: 'resultImage',
  label: '写真撮影',
  SvgComponent: ImageSvg,
}

const DateTimeItem: MenuItem = {
  key: 'datetime',
  label: '日時',
  SvgComponent: DateSvg,
}

const TimeMeasurementItem: MenuItem = {
  key: 'timeMeasurement',
  label: '時間計測',
  SvgComponent: TimeMeasurement,
}

const InformationItem: MenuItem = {
  key: 'information',
  label: 'インフォメーション',
  SvgComponent: InformationSvg,
}

const EmployeeItem: MenuItem = {
  key: 'employee',
  label: '従業員',
  SvgComponent: EmployeeSvg,
}

const FormulaItem: MenuItem = {
  key: 'formula',
  label: '計算式',
  SvgComponent: FormulaSvg,
}

const LabelCheckItem: MenuItem = {
  key: 'labelCheck',
  label: 'AIラベル検査',
  SvgComponent: LabelCheckSvg,
}

const defaultItems: MenuItem[] = [
  NumberItem,
  SignatureItem,
  TextItem,
  ResultImageItem,
  DateTimeItem,
  InformationItem,
  EmployeeItem,
  FormulaItem,
]

const itemsForGrid: MenuItem[] = [
  NumberItem,
  TextItem,
  ResultImageItem,
  DateTimeItem,
  TimeMeasurementItem,
  FormulaItem,
  SignatureItem,
]

/**
 * 入力フォームの選択肢を取得する。
 * レイアウトやFeatureFlagの条件によって、選択肢が変わる。
 */
const useItems = () => {
  const isGridLayout = useSelector(selectIsGridLayout)
  const baseItems = isGridLayout ? itemsForGrid : defaultItems
  const items = useLabelCheckItem(isGridLayout, baseItems)
  // MEMO: 刃こぼれなど、選択肢として表示するか条件がある場合は、に追加する
  return items
}

const useLabelCheckItem = (isGridLayout: boolean, items: MenuItem[]) => {
  // MEMO: 最終的にuseQueryでキャッシュしているため、毎回featureFlagの取得APIが走っているわけではない。
  const { canUse } = useFeature(FeatureNameEnum.LABEL_CHECK)
  const canLabelCheck = canUse === 'yes'

  // グリッドレイアウトであれば、ラベル検査を追加しない
  if (isGridLayout) {
    return items
  }

  // ラベル検査が許可されていない場合は、ラベル検査を追加しない
  if (!canLabelCheck) {
    return items
  }

  return [...items, LabelCheckItem]
}

interface Props {
  onClick: (key: string) => void
}

/**
 * ひな形編集画面 -> 各質問 -> 質問種別選択 -> アイコンとラベルの集合
 * (e.g. 数値、署名、...計算式)
 */
const InputMenu = (props: Props) => {
  const { onClick } = props

  const items = useItems()

  return items.map(({ key, label, SvgComponent }) => (
    <div css={styles.container} key={key} onClick={() => onClick(key)}>
      <SvgComponent width={30} height={30} />
      <div css={styles.label}>{label}</div>
    </div>
  ))
}

const styles = {
  container: css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 40px;
    margin: 5px;
    padding: 0 16px;
  `,
  label: css`
    margin-left: 10px;
  `,
}

export default InputMenu
