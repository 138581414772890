import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import 'src/components/swiper/setupSwiper'
import { AntdConfigProvider } from 'src/features/antd/setupAntd'
import { AuthErrorDialog } from 'src/features/auth/error/Dialog'
import 'src/features/firebase/firebase'
import { initializeGtm } from 'src/features/gtm/initializeGtm'
import { NotificationService } from 'src/features/notificationService/NotificationService'
import { initializeSentry } from 'src/features/sentry/initializeSentry'
import { SetupTanStackQuery } from 'src/features/tanStackQuery/SetupTanStackQuery'
import ErrorBoundary from 'src/libraries/ErrorBoundary'
import { LobsterServiceProvider } from 'src/modules/lobster'
import { history, store } from 'src/state/store'
import 'src/util/polyfill'
import Routes from 'src/views/Routes'
import { UAGuard } from './UAGuard'

initializeSentry()
initializeGtm()

const App: React.FC = () => {
  return (
    <UAGuard>
      <ErrorBoundary>
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <LobsterServiceProvider>
              <Router history={history}>
                <AntdConfigProvider>
                  <SetupTanStackQuery>
                    <AuthErrorDialog />
                    <NotificationService />
                    <Routes />
                  </SetupTanStackQuery>
                </AntdConfigProvider>
              </Router>
            </LobsterServiceProvider>
          </Provider>
        </DndProvider>
      </ErrorBoundary>
    </UAGuard>
  )
}

export default App
