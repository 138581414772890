import { createContext, useEffect, useState } from 'react'
import { ReportLabelInspection } from '../../model/report-label-inspection'
import { useInitializeLabelCheck } from './useInitializeLabelCheck'

type ReportLabelInspectionId = string
export const LabelCheckAnswerStoreContext = createContext<{
  reportLabelInspectionMap: Map<ReportLabelInspectionId, ReportLabelInspection>
  setReportLabelInspectionMap: (
    reportLabelInspectionMap: Map<
      ReportLabelInspectionId,
      ReportLabelInspection
    >,
  ) => void
}>({
  reportLabelInspectionMap: new Map(),
  setReportLabelInspectionMap: () => {},
})

export const LabelCheckAnswerStoreProvider = ({
  labelInspectionIds,
  children,
}: {
  labelInspectionIds: string[]
  children: React.ReactNode
}) => {
  const [reportLabelInspectionMap, setReportLabelInspectionMap] = useState<
    Map<ReportLabelInspectionId, ReportLabelInspection>
  >(new Map())

  const { initializeLabelCheck } = useInitializeLabelCheck()

  useEffect(() => {
    const initialize = async () => {
      const reportLabelInspectionMap =
        await initializeLabelCheck(labelInspectionIds)
      if (!reportLabelInspectionMap) return
      setReportLabelInspectionMap(reportLabelInspectionMap)
    }
    initialize()
  }, [labelInspectionIds, initializeLabelCheck])

  return (
    <LabelCheckAnswerStoreContext.Provider
      value={{
        reportLabelInspectionMap,
        setReportLabelInspectionMap,
      }}
    >
      {children}
    </LabelCheckAnswerStoreContext.Provider>
  )
}
