import { Text } from '@react-pdf/renderer'
import {
  FormulaValue,
  ResponseFormulaDecimalPoint,
} from '@ulysses-inc/harami_api_client'
import { FC } from 'react'
import { convertDecimal } from 'src/exShared/util/numberDecimalPoint/numberDecimalPoint'
import { gridLayoutTableBodyCell } from '../../../shared/styles'

type Props = {
  value?: FormulaValue
  scale?: string
  decimalPoint?: ResponseFormulaDecimalPoint
}

export const TableBodyCellFormula: FC<Props> = ({
  value,
  scale,
  decimalPoint,
}) => {
  return (
    <Text
      style={[
        gridLayoutTableBodyCell.baseTableBodyCell,
        gridLayoutTableBodyCell.tableBodyCell,
      ]}
    >
      {convertDisplayFormula(value?.formulaValue, scale, decimalPoint)}
    </Text>
  )
}

const convertDisplayFormula = (
  value: number | undefined,
  scale?: string,
  decimalPoint?: ResponseFormulaDecimalPoint,
) => {
  if (value === undefined) {
    return '未計算'
  }

  return convertDecimal(value, decimalPoint) + (scale ?? '')
}
