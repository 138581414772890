import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import {
  ExcelConversionStepTypeEnum,
  Image,
  MultipleChoiceGroup,
  ResponseDatetime,
  ResponseDatetimeSubTypeEnum,
  ResponseEmployee,
  ResponseEmployeeSubTypeEnum,
  ResponseFormula,
  ResponseNumber,
  ResponseText,
  ResponseTextSubTypeEnum,
  ResponseTimeMeasurement,
  ResponseTypeEnum,
  ResponseURL,
  TemplateNodeSchema,
  TemplateQuestionExcelConversionTypes,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HoveringPosition } from 'src/features/templateEdit/dragAndDrop/DnDTemplateType'
import {
  DisabledTableCellBackgroundColor,
  ScoreSlashColor,
  ScoreTextColor,
} from 'src/features/theme/KdsThemeColor'
import {
  changeActiveNodeId,
  updateTemplateNode,
} from 'src/state/ducks/templates/actions'
import { showEditHint } from 'src/state/ducks/templates/editHint/actions'
import { updateRepeatSerialNumber } from 'src/state/ducks/templates/editSerialNumber/actions'
import { useTemplateNodeNodes } from 'src/state/ducks/templates/selectors'
import { RootState } from 'src/state/store'
import ExcelLabelDropDown from '../itemAppender/ExcelLabelDropDown'
import ResponseDropDown from '../itemAppender/ResponseDropDown'
import { QuestionProps } from './Question'
import {
  BasicTableCell,
  ColumnTexScoretWrapper,
  DraggingPlaceholder,
  HintCheckIconWrapper,
  HintCheckText,
  HintRowWrapper,
  Table,
} from './Question.components'
import { useDragAndDrop } from './Question.hooks'
import { TReactState, checkIsEmployeeCheck } from './Question.utils'
import QuestionNameAndButtons from './QuestionNameAndButtons'

export interface IQuestionBodyStates {
  isOpenState: TReactState<boolean>
  selectAnswerState: TReactState<ReactNode>
  updateMultipleChoiceGroupState: TReactState<MultipleChoiceGroup | undefined>
  isVisibleAddMultipleChoiceDrawerState: TReactState<boolean>
  hoverPositionState: TReactState<HoveringPosition>
}

const QuestionBody: FC<
  QuestionProps & ReturnType<typeof useDragAndDrop> & IQuestionBodyStates
> = ({
  node,
  isActive,
  parentNode,
  path,
  templateHints,
  templateNodes,
  isExcelConversion,
  isShowScore,
  isOpenState,
  selectAnswerState: [selectAnswer, setSelectAnswer],
  updateMultipleChoiceGroupState: [, setUpdateMultipleChoiceGroup],
  isVisibleAddMultipleChoiceDrawerState: [
    ,
    setIsVisibleAddMultipleChoiceDrawer,
  ],
  isOver,
  hoverPositionState: [hoverPosition],
  drag,
  preview,
}) => {
  const multipleChoiceGroups = useSelector(
    (state: RootState) =>
      state.templatesState.responseMultipleChoices.multipleChoiceGroups,
  )
  const multipleChoiceSets = useSelector(
    (state: RootState) =>
      state.multipleChoiceSetsState.multipleChoiceSets.multipleChoiceSets,
  )

  const dispatch = useDispatch()
  const dispatchUpdateTemplateNode = (
    values: Partial<TemplateNodeSchema['question']>,
    nodeId: number = node.id,
  ) => dispatchUpdateSpecificTemplateNode(node, values, nodeId)
  const dispatchUpdateSpecificTemplateNode = (
    updateTarget: TemplateNodeSchema,
    values: Partial<TemplateNodeSchema['question']>,
    nodeId: number = node.id,
  ) =>
    dispatch(
      updateTemplateNode(nodeId, {
        ...updateTarget,
        question: { ...updateTarget.question, ...values },
      }),
    )
  const dispatchChangeActiveNodeId = () => dispatch(changeActiveNodeId(node.id))
  const dispatchShowEditHint = (hintId: string, blocks: unknown[]) =>
    dispatch(showEditHint(node, hintId, blocks))

  const templateNodeNodes = useTemplateNodeNodes(node)

  const excelConversionTypes = node.question?.excelConversionTypes ?? []
  const responseChoices = node.question?.responseMultipleChoices ?? []

  const hasSerialNumberTypes = [
    ExcelConversionStepTypeEnum.Multiple,
    ExcelConversionStepTypeEnum.Repeat,
  ]

  const onClickMultipleChoice = (clickedId: number) => {
    const responseMultipleChoices = multipleChoiceGroups.filter(
      ({ id }) => id === clickedId,
      [],
    )

    dispatchUpdateTemplateNode({
      responseType: ResponseTypeEnum.MULTIPLE_CHOICE,
      responseMultipleChoices: [...responseMultipleChoices],
      responseNumbers: [],
    })
  }

  const onClickMultipleChoiceSet = (clickedId: number) => {
    const responseMultipleChoiceSets = multipleChoiceSets.filter(
      ({ id }) => id === clickedId,
      [],
    )
    dispatchUpdateTemplateNode({
      responseType: ResponseTypeEnum.RESPONSE_SET,
      responseMultipleChoiceSets: [...responseMultipleChoiceSets],
      responseMultipleChoices: [],
      responseNumbers: [],
    })
  }

  const onChangeQuestionType = (questionType: string) => {
    let responseType
    let responseNumbers: ResponseNumber[] = []
    let responseTexts: ResponseText[] = []
    let responseDatetimes: ResponseDatetime[] = []
    let responseTimeMeasurements: ResponseTimeMeasurement[] = []
    let responseInformations: Image[] = []
    let responseEmployees: ResponseEmployee[] = []
    let responseFormulas: ResponseFormula[] = []
    let responseURLs: ResponseURL[] = []

    switch (questionType) {
      case 'number':
        responseType = ResponseTypeEnum.NUMBER
        responseNumbers = []
        break
      case 'signature':
        responseType = ResponseTypeEnum.SIGNATURE
        break
      case 'text':
        responseType = ResponseTypeEnum.TEXT
        responseTexts = [{ subType: ResponseTextSubTypeEnum.SINGLE_LINE }]
        break
      case 'resultImage':
        responseType = ResponseTypeEnum.RESULT_IMAGE
        break
      case 'datetime':
        responseType = ResponseTypeEnum.DATETIME
        responseDatetimes = [{ subType: ResponseDatetimeSubTypeEnum.DATETIME }]
        break
      case 'timeMeasurement':
        responseType = ResponseTypeEnum.TIME_MEASUREMENT
        responseTimeMeasurements = []
        break
      case 'information':
        responseType = ResponseTypeEnum.INFORMATION
        responseInformations = []
        responseURLs = []
        break
      case 'employee':
        responseType = ResponseTypeEnum.EMPLOYEE
        responseEmployees = [
          { subType: ResponseEmployeeSubTypeEnum.NAME, isMultiple: 0 },
        ]
        break
      case 'formula':
        responseType = ResponseTypeEnum.FORMULA
        responseFormulas = []
        break
      case 'labelCheck':
        responseType = ResponseTypeEnum.LABEL_CHECK
        break
      default:
        return
    }

    dispatchUpdateTemplateNode({
      scale: '',
      isShowInformationImages: 0,
      informationImages: [],
      responseType,
      responseMultipleChoices: [],
      responseNumbers: [...responseNumbers],
      responseTexts: [...responseTexts],
      responseDatetimes: [...responseDatetimes],
      responseTimeMeasurements: [...responseTimeMeasurements],
      responseInformations: [...responseInformations],
      responseEmployees: [...responseEmployees],
      responseFormulas: [...responseFormulas],
      responseURLs: [...responseURLs],
    })
  }

  const onChangeSerialNumber = (changedIndex: number, value: number) => {
    const excelConversionTypes:
      | TemplateQuestionExcelConversionTypes[]
      | undefined
      | null = node.question?.excelConversionTypes

    if (!excelConversionTypes || !parentNode) {
      return
    }
    dispatchUpdateTemplateNode({
      excelConversionTypes: excelConversionTypes.map((c, index) => {
        if (index !== changedIndex) {
          return c
        }
        return { ...c, serialNumber: value }
      }),
    })
    if (
      excelConversionTypes[changedIndex]?.excelConversionType ===
      ExcelConversionStepTypeEnum.Repeat
    ) {
      dispatch(updateRepeatSerialNumber(parentNode.id, value))
      parentNode.nodes?.forEach(nodeId => {
        if (nodeId !== node.id) {
          const nd = templateNodes[nodeId]
          // 単に型の都合
          if (!nd) return
          dispatchUpdateSpecificTemplateNode(
            nd,
            {
              excelConversionTypes: nd?.question?.excelConversionTypes?.map(
                c =>
                  c.excelConversionType === ExcelConversionStepTypeEnum.Repeat
                    ? { ...c, serialNumber: value }
                    : c,
              ),
            },
            nodeId,
          )
        }
      })
    }
  }
  const onClickLabel = (stepType: number, checked: boolean) => {
    const excelConversionTypes:
      | TemplateQuestionExcelConversionTypes[]
      | undefined
      | null = node.question?.excelConversionTypes
    if (!excelConversionTypes) {
      if (checked) {
        dispatchUpdateTemplateNode({
          excelConversionTypes: [
            {
              excelConversionType: stepType,
              serialNumber: hasSerialNumberTypes.includes(stepType)
                ? 1
                : undefined,
            },
          ],
        })
      }
      return
    }
    const isContainType =
      excelConversionTypes.find(c => c.excelConversionType === stepType) ??
      false
    if (!isContainType && checked) {
      dispatchUpdateTemplateNode({
        excelConversionTypes: [
          ...excelConversionTypes,
          {
            excelConversionType: stepType,
            serialNumber: hasSerialNumberTypes.includes(stepType)
              ? 1
              : undefined,
          },
        ],
      })
      return
    }
    if (isContainType && !checked) {
      dispatchUpdateTemplateNode({
        excelConversionTypes: excelConversionTypes.filter(
          c => c.excelConversionType !== stepType,
        ),
      })
      return
    }
  }

  const score = () => {
    return isMultipleChoice() && responseChoices.length
      ? responseChoices[0]?.responses
          .filter(m => !m.isExcludedFromScoring)
          .map(({ score }) => score)
          .reduce((prev, cur) => Math.max(prev, cur), 0)
      : '/'
  }

  const isMultipleChoice = () =>
    node.question?.responseType === ResponseTypeEnum.MULTIPLE_CHOICE
  const isEmployeeCheck = checkIsEmployeeCheck(parentNode, node)

  return (
    <>
      {isOver && hoverPosition === 'TOP' && <DraggingPlaceholder />}
      <Table onClick={dispatchChangeActiveNodeId} data-testid="question-body">
        <QuestionNameAndButtons
          {...{
            node,
            parentNode,
            isActive,
            templateNodes,
            isOpenState,
            preview,
            drag,
          }}
        />
        <ResponseDropDown
          hasLogic={!!templateNodeNodes.length}
          selectAnswer={selectAnswer}
          setSelectAnswer={setSelectAnswer}
          setUpdateMultipleChoiceGroup={setUpdateMultipleChoiceGroup}
          setIsVisibleAddMultipleChoiceDrawer={
            setIsVisibleAddMultipleChoiceDrawer
          }
          onChangeQuestionType={onChangeQuestionType}
          onClickMultipleChoice={onClickMultipleChoice}
          onClickMultipleChoiceSet={onClickMultipleChoiceSet}
          multipleChoiceGroups={multipleChoiceGroups}
          multipleChoiceSets={multipleChoiceSets}
          isEmployeeCheck={isEmployeeCheck}
        />
        {templateHints.map(({ id }) => {
          const questionHint = node.question?.hints?.filter(
            ({ hintId }) => hintId === id,
          )[0]

          return (
            <BasicTableCell key={id} theme={{ minWidth: '200px' }}>
              {!isEmployeeCheck && (
                <HintRowWrapper>
                  <Button
                    type="link"
                    onClick={() =>
                      dispatchShowEditHint(id ?? '', questionHint?.blocks ?? [])
                    }
                  >
                    <EditOutlined />
                  </Button>
                  {!!(questionHint?.blocks ?? []).length && (
                    <HintCheckIconWrapper>
                      <CheckOutlined />
                      <HintCheckText>入力済</HintCheckText>
                    </HintCheckIconWrapper>
                  )}
                </HintRowWrapper>
              )}
            </BasicTableCell>
          )
        })}
        {isExcelConversion && (
          <ExcelLabelDropDown
            checkedExcelConversionTypes={excelConversionTypes}
            onClickLabel={onClickLabel}
            isRepeat={
              path.find(
                parentId => templateNodes[parentId]?.section?.isRepeat,
              ) !== undefined
            }
            onChangeSerialNumber={onChangeSerialNumber}
          />
        )}
        {isShowScore && (
          <BasicTableCell
            theme={{
              minWidth: '90px',
              backgroundColor:
                !responseChoices.length && DisabledTableCellBackgroundColor,
            }}
          >
            <ColumnTexScoretWrapper
              theme={{
                color: !responseChoices.length
                  ? ScoreSlashColor
                  : ScoreTextColor,
              }}
            >
              {score()}
            </ColumnTexScoretWrapper>
          </BasicTableCell>
        )}
      </Table>
      {isOver && hoverPosition === 'BOTTOM' && <DraggingPlaceholder />}
    </>
  )
}

export default QuestionBody
