export class LocalStorageService<T> {
  setItem(key: string, item: T): void {
    localStorage.setItem(key, JSON.stringify(item))
  }

  getItem(key: string): T | null {
    const data: any = localStorage.getItem(key)
    if (!data) return null

    let obj: T
    try {
      obj = JSON.parse(data)
    } catch {
      return null
    }
    return obj
  }
}
