import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleExcelConversion } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import OptionToggleSwitch from './OptionToggleSwitch'

const ExcelSwitch: FC = () => {
  const dispatch = useDispatch()
  const isExcelConversion = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isExcelConversion,
    shallowEqual,
  )

  return (
    <OptionToggleSwitch
      isChecked={isExcelConversion}
      onChange={checked => dispatch(toggleExcelConversion(checked))}
      label="EXCEL出力機能をつける"
    />
  )
}

export default ExcelSwitch
