import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { ResponseNumberSubTypeEnum } from '@ulysses-inc/harami_api_client'
import { Input, InputNumber, Row, Select, Typography } from 'antd'
import { FormikProps } from 'formik'
import { FC, useId } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { SubTypeText } from 'src/constants/responseNumber'
import { NumberRuleProps } from './EditResponseNumberRuleDrawer'
import { FormGroup } from './EditResponseNumberRuleDrawer.styled'
import {
  BetweenFormItem,
  NumberMaximumFormItem,
  NumberMinimumFormItem,
  NumberSubTypeSelect,
  RuleSwitch,
  RuleSwitchRow,
  ScaleFormItem,
  SubTypeFormItem,
} from './NormalValueRow.styled'

const { Option } = Select

const NormalValueRow: FC<{
  formikProps: FormikProps<NumberRuleProps>
}> = ({
  formikProps: {
    values: { hasNumberRule, subType, minimum, maximum, scale },
    handleChange,
    setFieldValue,
    errors,
    touched,
  },
}) => {
  const update = (key: keyof NumberRuleProps) => (value: unknown) =>
    setFieldValue(key, value)

  const onChange = (checked: boolean) => {
    if (!checked) {
      update('minimum')(undefined)
      update('maximum')(undefined)
      update('scale')(undefined)
      update('subType')(undefined)
    }

    update('hasNumberRule')(!!checked)
  }

  const hasNumberRuleLabelId = useId()

  return (
    <FormGroup>
      <RuleSwitchRow>
        <RuleSwitch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={hasNumberRule}
          onChange={onChange}
          aria-labelledby={hasNumberRuleLabelId}
        />
        <label id={hasNumberRuleLabelId}>正常値を設定する</label>
      </RuleSwitchRow>
      {hasNumberRule && (
        <Row>
          {subType !== ResponseNumberSubTypeEnum.LESS_THAN && (
            <NumberMinimumFormItem>
              <InputNumber
                name="minimum"
                placeholder="0"
                value={minimum}
                onChange={update('minimum')}
                aria-label="正常値の下限値"
              />
            </NumberMinimumFormItem>
          )}
          {(!subType || subType === ResponseNumberSubTypeEnum.BETWEEN) && (
            <BetweenFormItem>
              <Typography>ー</Typography>
            </BetweenFormItem>
          )}
          {subType !== ResponseNumberSubTypeEnum.GREATER_THAN && (
            <NumberMaximumFormItem>
              <InputNumber
                name="maximum"
                placeholder="100"
                value={maximum}
                onChange={update('maximum')}
                aria-label="正常値の上限値"
              />
            </NumberMaximumFormItem>
          )}
          <ScaleFormItem>
            <Input
              name="scale"
              placeholder="℃"
              value={scale}
              maxLength={10}
              onChange={handleChange}
              aria-label="正常値の単位"
            />
          </ScaleFormItem>
          <SubTypeFormItem>
            <NumberSubTypeSelect
              placeholder="の間"
              value={subType || undefined}
              onChange={update('subType')}
              aria-label="正常値の範囲指定"
            >
              <Option
                value={ResponseNumberSubTypeEnum.BETWEEN}
                data-testid="select-number-subtype-item"
              >
                {SubTypeText[ResponseNumberSubTypeEnum.BETWEEN]}
              </Option>
              <Option
                value={ResponseNumberSubTypeEnum.GREATER_THAN}
                data-testid="select-number-subtype-item"
              >
                {SubTypeText[ResponseNumberSubTypeEnum.GREATER_THAN]}
              </Option>
              <Option
                value={ResponseNumberSubTypeEnum.LESS_THAN}
                data-testid="select-number-subtype-item"
              >
                {SubTypeText[ResponseNumberSubTypeEnum.LESS_THAN]}
              </Option>
            </NumberSubTypeSelect>
          </SubTypeFormItem>
        </Row>
      )}
      <Row>
        <div>
          <FormikErrorMessage
            name="subType"
            errors={errors}
            touched={touched}
          />
        </div>
        <div>
          <FormikErrorMessage
            name="maximum"
            errors={errors}
            touched={touched}
          />
        </div>
        <div>
          <FormikErrorMessage
            name="minimum"
            errors={errors}
            touched={touched}
          />
        </div>
      </Row>
    </FormGroup>
  )
}

export default NormalValueRow
