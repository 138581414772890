import {
  ExtremumTypeEnum,
  MasterFieldTypeEnum,
  ResponseNumberSubTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Select, Typography } from 'antd'
import { FormikProps } from 'formik'
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { SubTypeText } from 'src/constants/responseNumber'
import { RootState } from 'src/state/store'
import { NumberRuleProps } from './EditResponseNumberRuleEnableProductMasterDrawer'
import {
  BetweenFormItem,
  MasterFieldDefSelect,
  MasterRuleContainer,
  MinMaxFormItem,
  NumberSubTypeSelect,
  ScaleAndSubTypeFormItem,
  ScaleInput,
} from './RuleSelector.styled'

const { Option } = Select

export const MasterRuleSelector: FC<{
  formikProps: FormikProps<NumberRuleProps>
}> = ({
  formikProps: {
    values: { subType, scale, minimumRuleVariableDef, maximumRuleVariableDef },
    handleChange,
    setFieldValue,
  },
}) => {
  const update = (key: keyof NumberRuleProps) => (value: unknown) =>
    setFieldValue(key, value)

  const productMasterFieldDefs = useSelector(
    (state: RootState) =>
      state.productMasterFieldDefsState.productMasterFieldDefs.filter(
        productMasterFieldDef =>
          productMasterFieldDef.fieldType === MasterFieldTypeEnum.Number,
      ),
    shallowEqual,
  )

  const handleChangeMinProductMasterFieldDef = (value: number) => {
    setFieldValue('minimumRuleVariableDef', {
      masterFieldDefId: value,
      extremumType: ExtremumTypeEnum.Min,
    })
  }
  const handleChangeMaxProductMasterFieldDef = (value: number) => {
    setFieldValue('maximumRuleVariableDef', {
      masterFieldDefId: value,
      extremumType: ExtremumTypeEnum.Max,
    })
  }

  return (
    <MasterRuleContainer>
      <MinMaxFormItem>
        {subType !== ResponseNumberSubTypeEnum.LESS_THAN && (
          <MasterFieldDefSelect
            placeholder="最小値を選択"
            onChange={handleChangeMinProductMasterFieldDef}
            value={minimumRuleVariableDef?.masterFieldDefId || undefined}
            aria-label="最小値の選択"
          >
            {productMasterFieldDefs.map(productMasterFieldDef => (
              <Option
                key={productMasterFieldDef.id}
                value={productMasterFieldDef.id}
                data-testid="select-minimum-master-field-def-item"
              >
                {productMasterFieldDef.name}
              </Option>
            ))}
          </MasterFieldDefSelect>
        )}
        {(!subType || subType === ResponseNumberSubTypeEnum.BETWEEN) && (
          <BetweenFormItem>
            <Typography>ー</Typography>
          </BetweenFormItem>
        )}
        {subType !== ResponseNumberSubTypeEnum.GREATER_THAN && (
          <MasterFieldDefSelect
            placeholder="最大値を選択"
            onChange={handleChangeMaxProductMasterFieldDef}
            value={maximumRuleVariableDef?.masterFieldDefId || undefined}
            aria-label="最大値の選択"
          >
            {productMasterFieldDefs.map(productMasterFieldDef => (
              <Option
                key={productMasterFieldDef.id}
                value={productMasterFieldDef.id}
                data-testid="select-maximum-master-field-def-item"
              >
                {productMasterFieldDef.name}
              </Option>
            ))}
          </MasterFieldDefSelect>
        )}
      </MinMaxFormItem>
      <ScaleAndSubTypeFormItem>
        <ScaleInput
          name="scale"
          placeholder="℃"
          value={scale}
          maxLength={10}
          onChange={handleChange}
          aria-label="正常値の単位"
          width={80}
        />
        <NumberSubTypeSelect
          placeholder="の間"
          value={subType || undefined}
          onChange={update('subType')}
          aria-label="正常値の範囲指定"
        >
          <Option
            value={ResponseNumberSubTypeEnum.BETWEEN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.BETWEEN]}
          </Option>
          <Option
            value={ResponseNumberSubTypeEnum.GREATER_THAN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.GREATER_THAN]}
          </Option>
          <Option
            value={ResponseNumberSubTypeEnum.LESS_THAN}
            data-testid="select-number-subtype-item"
          >
            {SubTypeText[ResponseNumberSubTypeEnum.LESS_THAN]}
          </Option>
        </NumberSubTypeSelect>
      </ScaleAndSubTypeFormItem>
    </MasterRuleContainer>
  )
}
