import { InputNumber, Row, Switch } from 'antd'
import styled from 'styled-components'

export const FormGroupDecimalPointRow = styled(Row)`
  align-items: center;
  margin-bottom: 12px;
`

export const FormGroupDecimalPointValueRow = styled(Row)`
  display: flex;
  flex-direction: column;
`

export const FormGroupDecimalPointValue = styled.div`
  margin-bottom: 12px;
`

export const DecimalPointSwitch = styled(Switch)`
  &.ant-switch {
    margin-right: 12px;
  }
`
export const DecimalPointInputNumber = styled(InputNumber)`
  &.ant-input-number {
    width: 60px;
    margin-right: 12px;
  }
`
