import {
  ReportQuestion,
  ResponseDatetimeSubTypeEnum,
} from '@ulysses-inc/harami_api_client'

export const getDatetimeSubType = (
  question: ReportQuestion,
): ResponseDatetimeSubTypeEnum => {
  const responseDatetime =
    question?.responseDatetimes && question.responseDatetimes.length > 0
      ? question.responseDatetimes[0]
      : undefined

  return responseDatetime?.subType
    ? responseDatetime?.subType
    : ResponseDatetimeSubTypeEnum.DATETIME
}

export const getNumberDecimalPoint = (question: ReportQuestion) => {
  return question.responseNumbers?.[0]?.decimalPoint
}

export const getFormulaDecimalPoint = (question: ReportQuestion) => {
  return question.responseFormulas?.[0]?.decimalPoint
}
