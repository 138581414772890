import { css } from '@emotion/react'
import { PlaceNode } from '@ulysses-inc/harami_api_client'
import { Button, Drawer, Layout, Row } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'src/components/header/Header'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { FlatNode } from 'src/features/places/PlaceGroupsProps'
import { RootState } from 'src/state/store'
import styled from 'styled-components'
import Loading from '../../components/loading/Loading'
import placesOperations from '../../state/ducks/places/operations'
import EditPlaceGroup from './EditPlaceGroup'
import PlaceGroupList from './PlaceGroupList'

interface StateProps {
  nodes: PlaceNode[]
  flatNodes: FlatNode[]
  isLoading: boolean
  isShowEditPlaceGroupDrawer: boolean
}

interface DispatchProps {
  getPlaces: () => void
  getPlaceGroups: () => void
  updateSegmentIndex: (index: number) => void
  changeIsShowEditPlaceGroupDrawer: (isShow: boolean) => void
  openAddPlaceGroup: (parentNodeId?: number) => void
  openEditPlaceGroup: (node: PlaceNode, parentNodeId?: number) => void
  deletePlaceGroup: (id: string) => void
}

export type PlaceGroupsProps = StateProps & DispatchProps

const useProps = (): PlaceGroupsProps => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)

  return {
    // -----------------------------------
    // StateProps
    // -----------------------------------

    nodes: state.placesState.placeGroups.nodes ?? [],
    flatNodes: flattenNodes(state.placesState.placeGroups.nodes, []),
    isLoading: state.placesState.placeGroups.isLoading,
    isShowEditPlaceGroupDrawer:
      state.placesState.placeGroups.isShowEditPlaceGroupDrawer,

    // -----------------------------------
    // DispatchProps
    // -----------------------------------

    getPlaces: () => {
      placesOperations.getPlaces(dispatch, {})
    },
    getPlaceGroups: () => {
      placesOperations.getPlaceGroups(dispatch)
    },
    updateSegmentIndex: (index: number) => {
      placesOperations.updateSegmentIndex(dispatch, index)
    },
    changeIsShowEditPlaceGroupDrawer: (isShow: boolean) => {
      placesOperations.changeIsShowEditPlaceGroupDrawer(dispatch, isShow)
    },
    openAddPlaceGroup: (parentNodeId?: number) => {
      placesOperations.updateActiveNodeId(dispatch, undefined, parentNodeId)
      placesOperations.changeIsShowEditPlaceGroupDrawer(dispatch, true)
    },
    openEditPlaceGroup: (node: PlaceNode, parentNodeId?: number) => {
      placesOperations.updateActiveNodeId(dispatch, node.uuid, parentNodeId)
      placesOperations.changeIsShowEditPlaceGroupDrawer(dispatch, true)
    },
    deletePlaceGroup: (nodeId: string) => {
      placesOperations.deletePlaceGroup(dispatch, nodeId)
    },
  }
}

const flattenNodes = (nodes: PlaceNode[], path: number[]) => {
  const flatNodes: FlatNode[] = []
  if (nodes && nodes.length > 0) {
    nodes.forEach(n => {
      const node = n as PlaceNode
      flatNodes.push({ ...node, path: [...path] })
      if (node.nodes && node.nodes.length)
        flatNodes.push(
          ...flattenNodes(node.nodes as PlaceNode[], [...path, node.id]),
        )
    })
  }
  return flatNodes
}

const { Content } = Layout

const ContentWrapper = styled(Content)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`

const ListContainer = styled.div`
  width: 100%;
  max-width: 800px;
`

const PlaceGroupsScene = () => {
  // react-reduxのconnect関数の利用をやめた際のなごり
  // (今後このコンポーネントを触ることがあればそのときに解体する)
  const exProps = useProps()

  useEffect(() => {
    exProps.getPlaces()
    exProps.getPlaceGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 閉じるボタン or Drawer外のクリックしたとき
  const onClose = () => {
    exProps.updateSegmentIndex(0)
    exProps.changeIsShowEditPlaceGroupDrawer(false)
  }

  const renderPlaceGroupList = () => {
    if (exProps.isLoading) {
      return <Loading />
    }

    return (
      <ListContainer>
        <Row
          justify="end"
          css={css`
            margin: 15px;
          `}
        >
          <Button
            type="primary"
            onClick={() => exProps.openAddPlaceGroup()}
            style={{ borderRadius: 4 }}
          >
            現場グループを追加
          </Button>
        </Row>
        <PlaceGroupList {...exProps} />
      </ListContainer>
    )
  }

  return (
    <Layout>
      <Header>
        <HeaderTitle title="現場グループ" />
      </Header>
      <ContentWrapper>{renderPlaceGroupList()}</ContentWrapper>
      <Drawer
        placement="right"
        maskClosable={false}
        closable={false}
        onClose={() => onClose()}
        destroyOnClose={true}
        open={exProps.isShowEditPlaceGroupDrawer}
        width={600}
        drawerStyle={{ padding: 0 }}
      >
        <EditPlaceGroup />
      </Drawer>
    </Layout>
  )
}

export default PlaceGroupsScene
