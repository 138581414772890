import { appEnv } from 'src/util/appEnv'

const localNodeUUIDs = [
  '2169d2d4-dc32-4aca-9fb4-1ebaa7636128',
  '3b11ea5d-3503-4784-81c5-207798a78cdc',
  'c0889db7-a982-42a2-8418-f1999038efcc',
]

const devNodeUUIDs = [
  '163d95d1-8d69-43d4-824f-870d20fee416',
  'c7147724-b8ed-43b6-959b-e588512959c6',
  '2094c694-eb12-4ea8-a595-d106ecacaa4c',
]

const prodNodeUUIDs = [
  //--------------- company_id: 79 ---------------//
  // ひな形名: 野菜殺菌管理表（NEOラクーン）
  // ページ: こうじ味噌マヨネーズ野菜スティック、野菜スティック 韓国旨辛みそ
  // 質問: 塩素濃度（40～50ppm）
  '284f25a9-da7b-4d8f-967d-e0a51e3d2620',
  // 質問: ｐH値（5.0～6.5）
  '31d7d1ec-74e5-43d2-b659-36329138f151',
  // 質問: 脱水後品温（10.0℃以下）
  '9fccf986-6185-409f-bc2c-8058242ccf48',
  // ページ: 1食分の野菜が摂れるサラダ
  // 質問: 塩素濃度（40～50ppm）
  'fac0ec07-ddcf-44cd-9898-19ae5f2e4aea',
  // 質問: ｐH値（5.0～6.5）
  '2fa024cf-8372-4b21-863d-e982711b0fea',
  // 質問: 脱水後品温（10.0℃以下）
  '5cc7dedb-517a-480e-b051-8c7c092e382f',
  // ページ: ツナ＆コーンサラダ
  // 質問: 塩素濃度（40～50ppm）
  'e152774c-10f3-4b0d-a13f-a55f26ffaa80',
  // 質問: ｐH値（5.0～6.5）
  '87bb4916-e217-4517-b9d1-4bc1b8732c72',
  // 質問: 脱水後品温（10.0℃以下）
  'ed0efcbd-6c2d-49e9-9cd0-0b57bb26bb5e',
]

// IoT 機器の読み取りボタンの表示対象の場合、 true を返す
export function isIoT(nodeUUID: string | undefined): boolean {
  let nodeUUIDs: string[]
  if (appEnv.isLocal) {
    nodeUUIDs = localNodeUUIDs
  } else if (appEnv.isDevelopment) {
    nodeUUIDs = devNodeUUIDs
  } else if (appEnv.isProduction) {
    nodeUUIDs = prodNodeUUIDs
  } else {
    nodeUUIDs = []
  }

  return nodeUUID && nodeUUIDs.includes(nodeUUID) ? true : false
}
