import {
  ExtremumTypeEnum,
  RuleVariableDef,
} from '@ulysses-inc/harami_api_client'

export const spreadRuleVariableDefs = (
  ruleVariableDefs: RuleVariableDef[] | undefined,
) => {
  const minimumRuleVariableDef = ruleVariableDefs?.find(
    ruleVariableDef => ruleVariableDef.extremumType === ExtremumTypeEnum.Min,
  )
  const maximumRuleVariableDef = ruleVariableDefs?.find(
    ruleVariableDef => ruleVariableDef.extremumType === ExtremumTypeEnum.Max,
  )
  return { minimumRuleVariableDef, maximumRuleVariableDef }
}
