import { LabelCheckValue } from '@ulysses-inc/harami_api_client'
import { createContext, useContext } from 'react'
import type { LobsterServiceContract } from '../contract'
import { LabelCheckAnswer } from '../model/report-label-inspection/components'
import { LabelCheckAnswerStoreProvider } from './LabelCheckAnswerStoreContext/LabelCheckAnswerStoreContext'

const lobsterServiceContext = createContext<LobsterServiceContract | null>(null)

export const useLobsterService = () => {
  const lobsterService = useContext(lobsterServiceContext)
  if (!lobsterService) {
    throw new Error('LobsterService not found')
  }
  return lobsterService
}

const createLobsterService = (): LobsterServiceContract => {
  return {
    LabelCheckAnswerStoreProvider,
    renderLabelCheckAnswer: (labelCheckValue: LabelCheckValue) => {
      return <LabelCheckAnswer labelCheckValue={labelCheckValue} />
    },
  }
}

export const LobsterServiceProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <lobsterServiceContext.Provider value={createLobsterService()}>
      {children}
    </lobsterServiceContext.Provider>
  )
}
