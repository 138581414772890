import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { toggleAudit } from 'src/state/ducks/templates/editTemplate/actions'
import { RootState } from 'src/state/store'
import OptionToggleSwitch from './OptionToggleSwitch'

const EnabledAuditSwitch: FC = () => {
  const dispatch = useDispatch()
  const isAudit = useSelector(
    (state: RootState) => state.templatesState.editTemplate.isAudit,
    shallowEqual,
  )

  return (
    <OptionToggleSwitch
      isChecked={isAudit}
      onChange={checked => {
        dispatch(toggleAudit(checked))
      }}
      label="カイゼン機能をつける"
    />
  )
}

export default EnabledAuditSwitch
