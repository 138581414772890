/**
 * レポートから提出したラベル検査結果
 */

export type ReportLabelInspection = {
  id: string
  labelInspectionId: string
  name: string
  reportLabels: ReportLabel[]
}

export type ReportLabel = {
  labelId: string
  name: string
  resultImageId: string
  resultImageUrl: string
  resultImageRecordedAt: string
  reportLabelInspectionAreas: ReportInspectionArea[]
}

export type ReportInspectionArea = {
  labelInspectionAreaId: string
  name: string
  status: 'ok' | 'ng' | 'undetected' | 'unknown'
  matchingValue: string
}

export type ReportInspectionAreaStatus = 'ok' | 'ng' | 'undetected' | 'unknown'

export const reportInspectionAreaStatusColor = {
  ok: '#2E7D32',
  ng: '#D32F2F',
  unknown: '#EF6C00',
  undetected: '#D32F2F',
} as const satisfies Record<ReportInspectionAreaStatus, string>

export const reportInspectionAreaStatusLabel = {
  ok: 'OK',
  ng: 'NG',
  unknown: '要確認',
  undetected: '未検出',
} as const satisfies Record<ReportInspectionAreaStatus, string>
