import {
  ExclamationCircleOutlined,
  LikeOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  ApprovalFlowStep,
  BulkApproveReports,
  PlaceNode,
  ReportListItem,
  ReportStatusEnum,
  User,
} from '@ulysses-inc/harami_api_client'
import { Button, Checkbox, Layout, List, Modal, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { RowActionButton } from 'src/components/rowActionButton/RowActionButton'
import date from 'src/exShared/util/date'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { Danger } from 'src/features/theme/KdsThemeColor'
import { useListFilter } from 'src/hooks/filter/useListFilter'
import { GetApprovalReportsFilter } from 'src/state/ducks/approvals/types'
import placesOperations from 'src/state/ducks/places/operations'
import { adjustCurrentPage } from 'src/util/adjustCurrentPage'
import Loading from '../../components/loading/Loading'
import approvalsOperations from '../../state/ducks/approvals/operations'
import { RootState } from '../../state/store'
import {
  BulkApprovingText,
  ButtonGroupRow,
  CompleteIcon,
  CompleteText,
  ContentRow,
  ListHeader,
  ListHeaderWrap,
  NextApprovers,
  ReportsPagination,
  TableHeaderLabel,
  TableHeaderRow,
  TableRow,
  TableRowWrap,
} from './ApprovalReports.styled'
import ApprovalReportsFilterDropDown from './ApprovalReportsFilterDropDown'
import { useIsBulkApproving } from './useIsBulkApproving'

export const flattenNodes = (nodes: PlaceNode[]) => {
  const flatNodes: PlaceNode[] = []
  if (nodes && nodes.length > 0) {
    nodes.forEach(node => {
      flatNodes.push(node)
      if (node.nodes && node.nodes.length)
        flatNodes.push(...flattenNodes(node.nodes as PlaceNode[]))
    })
  }
  return flatNodes
}

const ApprovalReports: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { isFeatureAvailable } = useLimitedFeatureAvailability()

  const { isBulkApproving, setIsBulkApproving } = useIsBulkApproving()

  const {
    reports,
    count: reportsCount,
    request: reportsRequest,
    isLoading: isReportsLoading,
  } = useSelector((state: RootState) => state.approvalsState.getApprovalReports)

  const getFilterData = () => {
    placesOperations.getPlaces(dispatch, {})
    placesOperations.getPlaceGroups(dispatch)
  }

  const [activeReportId, setActiveReportId] = useState<string>()
  const [chooseReportUUIDs, setChooseReportUUIDs] = useState<{
    [key: string]: boolean
  }>({})
  const {
    // localstorageから読み出したフィルタリング設定＆ページネーション設定
    filters,
    setListFilter,
  } = useListFilter()
  const [approvalReportsFilters, setApprovalReportsFilters] =
    useState<GetApprovalReportsFilter>()
  const [approvalReportGetTrigger, setApprovalReportGetTrigger] =
    useState<boolean>(false)

  useEffect(() => {
    getFilterData()

    // マウント後にデータを取得したいためのトリガー
    setApprovalReportGetTrigger(!approvalReportGetTrigger)

    if (filters) {
      const approvalReportFilter = filters.approvalReports
      updatePagination(
        approvalReportFilter?.pagination?.limit ?? 25,
        approvalReportFilter?.pagination?.offset ?? 0,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setListFilter({
      ...(filters ?? {}),
      approvalReports: {
        pagination: reportsRequest,
        filter: filters?.approvalReports?.filter,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsRequest.limit, reportsRequest.offset])

  useEffect(() => {
    // マウント後にデータを取得する
    if (approvalReportGetTrigger) {
      approvalsOperations.getApprovalReports(
        dispatch,
        reportsRequest,
        approvalReportsFilters,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalReportsFilters, reportsRequest])

  const handleOnMouseEnter = (reportId: string) => {
    setActiveReportId(reportId)
  }

  const handleOnMouseLeave = () => {
    setActiveReportId(undefined)
  }

  const handleCheckReport = (
    reportUUID: string | undefined,
    checked: boolean,
  ) => {
    if (!reportUUID) {
      return
    }
    if (checked) {
      chooseReportUUIDs[reportUUID] = true
      setChooseReportUUIDs({ ...chooseReportUUIDs })
    } else {
      delete chooseReportUUIDs[reportUUID]
      setChooseReportUUIDs({ ...chooseReportUUIDs })
    }
  }

  const getNextApprovers = (
    approvalStepCount: number,
    approvalFlowSteps: ApprovalFlowStep[],
    approvalStepApprovers: User[][],
  ): string => {
    if (approvalStepApprovers.length > 0) {
      return (
        approvalStepApprovers[approvalStepCount]
          .map(user => user.name)
          .join(',') ?? ''
      )
    }

    return (
      approvalFlowSteps[approvalStepCount]?.approvers
        ?.map(approver => approver.name)
        .join(',') ?? ''
    )
  }

  const goReportResult = (report: ReportListItem) => {
    const templateId = report.templateId
    history.push(`/reports/${report.uuid}?templateId=${templateId}`)
  }

  const bulkApproveReports = (reportUUIDs: string[]) => {
    const targetReports = reports
      .filter(
        (report: ReportListItem) =>
          report.uuid !== undefined &&
          reportUUIDs.includes(report.uuid) &&
          (report.approvals ?? []).length === 0 &&
          report.isInvalid !== 1 &&
          report.status?.status === ReportStatusEnum.APPROVAL_PENDING,
      )
      .map((report: ReportListItem) => ({
        reportUUID: report.uuid,
        approvalFlowId: (report.approvalFlows ?? [])[0].id,
      }))
    const stepIds = reports
      .filter(
        (report: ReportListItem) =>
          report.uuid !== undefined &&
          reportUUIDs.includes(report.uuid) &&
          report.approvals !== undefined &&
          (report.approvals ?? []).length !== 0 &&
          report.isInvalid !== 1 &&
          report.status?.status === ReportStatusEnum.APPROVAL_PENDING,
      )
      .map((report: ReportListItem) => {
        const approvalSteps =
          (report.approvals ?? [])[(report.approvals ?? []).length - 1].steps ??
          []
        const approvalStepCount = (approvalSteps ?? []).filter(
          step => step.stepApprovers && step.stepApprovers.length > 0,
        ).length
        return approvalSteps[approvalStepCount].id ?? 0
      })
      .filter((id: number) => id !== 0)
    const bulkApproveReports: BulkApproveReports = {
      reports: targetReports,
      stepIds,
      getApprovalReportsQuery: approvalReportsFilters,
    }
    approvalsOperations.bulkApproveReports(dispatch, {
      bulkApproveReports,
      ...reportsRequest,
    })
  }

  const onBulkApproveButtonClicked = () => {
    Modal.confirm({
      onOk: () => {
        setIsBulkApproving(true)
        bulkApproveReports(Object.keys(chooseReportUUIDs))
        setChooseReportUUIDs({})
      },
      title:
        '必須項目と逸脱の存在しないレポートの一括承認を実行しますがよろしいですか？',
      okText: '実行',
      cancelText: 'キャンセル',
    })
  }

  const isCheckedAll = () => {
    const reportsWithoutDeviation = reports.filter(
      (report: ReportListItem) => !report.isInvalid,
    )
    if (reportsWithoutDeviation.length === 0) return false
    const unChecked = reportsWithoutDeviation.filter(
      (report: ReportListItem) =>
        report.uuid !== undefined &&
        report.uuid !== '' &&
        !Object.prototype.hasOwnProperty.call(chooseReportUUIDs, report.uuid),
    )
    return unChecked.length === 0
  }

  const handleCheckAll = (checked: boolean) => {
    const reportsWithoutDeviation = reports.filter(
      (report: ReportListItem) => !report.isInvalid,
    )
    if (checked) {
      reportsWithoutDeviation.forEach((report: ReportListItem) => {
        if (report.uuid === undefined || report.uuid === '') {
          return
        }
        chooseReportUUIDs[report.uuid] = true
      })
      setChooseReportUUIDs({ ...chooseReportUUIDs })
      return
    }
    reportsWithoutDeviation.forEach((report: ReportListItem) => {
      if (report.uuid === undefined || report.uuid === '') {
        return
      }
      delete chooseReportUUIDs[report.uuid]
    })
    setChooseReportUUIDs({ ...chooseReportUUIDs })
  }

  // 現在のページを調整する
  const currentPage = adjustCurrentPage(
    reportsRequest.limit,
    reportsRequest.offset,
    reportsCount,
  )

  const onPageChange = (
    pageNum: number,
    pageSize?: number,
    filter?: GetApprovalReportsFilter,
  ) => {
    if (pageSize) {
      approvalsOperations.changeApprovalReportsPage(
        dispatch,
        pageNum,
        pageSize,
        filter,
      )
    }
  }

  const onPageSizeChange = (
    size: number,
    filter?: GetApprovalReportsFilter,
  ) => {
    approvalsOperations.changeApprovalReportsSize(dispatch, size, filter)
  }

  const updatePagination = (limit: number, offset: number) => {
    approvalsOperations.updatePagination(dispatch, limit, offset)
  }

  const renderReports = (reports: ReportListItem[]) => {
    return (
      <List
        style={{ width: '100%' }}
        itemLayout="horizontal"
        dataSource={reports}
        renderItem={(report, index) => {
          const approvalFlowSteps =
            report.approvalFlows &&
            report.approvalFlows.length > 0 &&
            report.approvalFlows[0].steps
              ? report.approvalFlows[0].steps
              : []
          const approvalSteps =
            report.approvals &&
            report.approvals.length > 0 &&
            report.approvals[report.approvals.length - 1].steps
              ? report.approvals[report.approvals.length - 1].steps
              : []
          const approvalStepApprovers: User[][] =
            approvalSteps && approvalSteps.length > 0
              ? approvalSteps.map(step =>
                  step.approvers ? step.approvers : [],
                )
              : []
          const approvalStepCount = (approvalSteps ?? []).filter(
            step => step.stepApprovers && step.stepApprovers.length > 0,
          ).length
          return (
            <TableRowWrap
              key={`sectionDate_${index}`}
              onMouseEnter={() => handleOnMouseEnter(report.uuid!)}
              onMouseLeave={() => handleOnMouseLeave()}
            >
              <TableRow theme={{ width: '5%' }}>
                {!report.isInvalid && (
                  <Checkbox
                    checked={
                      report.uuid !== undefined && report.uuid !== ''
                        ? Object.prototype.hasOwnProperty.call(
                            chooseReportUUIDs,
                            report.uuid,
                          )
                        : false
                    }
                    onChange={e =>
                      handleCheckReport(report.uuid, e.target.checked)
                    }
                  />
                )}
              </TableRow>
              <TableRow theme={{ width: '25%' }}>{report.name}</TableRow>
              <TableRow theme={{ width: '10%' }}>
                {date.formatYYYYMMDD(report.reportDate)}
              </TableRow>
              <TableRow theme={{ width: '10%' }}>{report.place?.name}</TableRow>
              <TableRow theme={{ width: '10%' }}>
                {!!report.isInvalid && (
                  <ExclamationCircleOutlined style={{ color: Danger }} />
                )}
              </TableRow>
              <TableRow theme={{ width: '15%' }}>
                {report.assignee?.name}
              </TableRow>
              <TableRow theme={{ width: '20%' }}>
                {approvalFlowSteps.length === approvalStepCount ? (
                  <NextApprovers>
                    <CompleteIcon type="check-circle" />
                    <CompleteText>完了</CompleteText>
                  </NextApprovers>
                ) : (
                  <NextApprovers>
                    {getNextApprovers(
                      approvalStepCount,
                      approvalFlowSteps,
                      approvalStepApprovers,
                    )}
                  </NextApprovers>
                )}
              </TableRow>
              <TableRow
                theme={{ width: '5%' }}
              >{`${approvalStepCount}/${approvalFlowSteps.length}`}</TableRow>
              {activeReportId === report.uuid && (
                <ButtonGroupRow>
                  <RowActionButton
                    onClick={() => {
                      goReportResult(report)
                    }}
                    type="viewDetail"
                  />
                </ButtonGroupRow>
              )}
            </TableRowWrap>
          )
        }}
      />
    )
  }

  return (
    <Layout>
      <ContentRow justify="space-between">
        <ApprovalReportsFilterDropDown
          {...{
            isLoading: isReportsLoading,
            setApprovalReportsFilters,
          }}
        />
        {isFeatureAvailable('approvalFlows') && (
          <Link
            css={css({ marginLeft: 'auto', marginRight: 16 })}
            to="/approvalFlows"
          >
            <Button icon={<SettingOutlined />}>承認フロー設定</Button>
          </Link>
        )}
        <Button
          disabled={
            isBulkApproving || Object.keys(chooseReportUUIDs).length === 0
          }
          onClick={onBulkApproveButtonClicked}
          type="primary"
        >
          {isBulkApproving ? (
            <>
              <Spin size="small" />
              <BulkApprovingText>一括承認中…</BulkApprovingText>
            </>
          ) : (
            <>
              <LikeOutlined />
              <BulkApprovingText>一括承認</BulkApprovingText>
            </>
          )}
        </Button>
      </ContentRow>
      <TableHeaderRow justify="center">
        <ListHeaderWrap>
          <ListHeader>
            <TableHeaderLabel theme={{ width: '5%' }}>
              <Checkbox
                checked={isCheckedAll()}
                onChange={e => handleCheckAll(e.target.checked)}
              />
            </TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '25%' }}>
              レポート名
            </TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '10%' }}>実施日</TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '10%' }}>現場名</TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '10%' }}>
              逸脱有無
            </TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '15%' }}>依頼者</TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '20%' }}>承認者</TableHeaderLabel>
            <TableHeaderLabel theme={{ width: '5%' }}>進捗</TableHeaderLabel>
          </ListHeader>
          {isReportsLoading ? <Loading /> : renderReports(reports)}
        </ListHeaderWrap>
      </TableHeaderRow>
      <ReportsPagination
        showSizeChanger
        current={currentPage}
        pageSizeOptions={['10', '25', '50', '100']}
        pageSize={reportsRequest.limit}
        defaultCurrent={1}
        total={reportsCount}
        onChange={(pageNum, pageSize) =>
          onPageChange(pageNum, pageSize, approvalReportsFilters)
        }
        onShowSizeChange={(_, size) =>
          onPageSizeChange(size, approvalReportsFilters)
        }
      />
    </Layout>
  )
}

export default ApprovalReports
