import {
  TemplateNodeSchema,
  TemplatePageSchema,
  TemplateReportNamingRule,
} from '@ulysses-inc/harami_api_client'
import { schema } from 'normalizr'

const node = new schema.Entity('nodes')
const nodes = new schema.Array(node)
node.define({ nodes })
const schemaTemplatePage = new schema.Entity('templatePages', {
  nodes: [new schema.Entity('nodes', { nodes })],
})
export const SchemaTemplatePages = new schema.Array(schemaTemplatePage)

export type TemplatePagesEntities = {
  templatePages: { [key: number]: TemplatePageSchema }
  nodes: { [key: number]: TemplateNodeSchema }
}

const ruleItem = new schema.Entity('items')
const rule = new schema.Entity('rule', {
  items: [ruleItem],
})

const schemaPage = new schema.Entity('pages', {
  nodes: [new schema.Entity('nodes', { nodes })],
})
export const SchemaTemplatePagesWithRule = {
  pages: [schemaPage],
  rule: rule,
}

export type TemplatePagesWithRuleEntities = {
  pages: { [key: number]: TemplatePageSchema }
  nodes: { [key: number]: TemplateNodeSchema }
  rule: TemplateReportNamingRule
}

export type TemplatePagesWithRuleEntitiesNormalizeResultType = {
  pages: number[]
  rule: number
}
