import { useCallback, useSyncExternalStore } from 'react'

/**
 * storageイベントを同一ウィンドウ内に対して通知する。
 * もともとstorageイベントが別ウィンドウに対してのみ通知される仕様であることへのワークアラウンド。
 * https://developer.mozilla.org/ja/docs/Web/API/Window/storage_event
 */
const dispatchStorageEvent = (key: string, newValue: string | null) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }))
}

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key)
}

const setLocalStorageItem = (key: string, value: string) => {
  window.localStorage.setItem(key, value)
  dispatchStorageEvent(key, value)
}

const subscribeLocalStorage = (callback: () => void) => {
  window.addEventListener('storage', callback)
  return () => window.removeEventListener('storage', callback)
}

// `undefined`のパースを可能にするための`JSON.parse()`のラッパー
function parseJSON(value: string) {
  return value === 'undefined' ? undefined : JSON.parse(value ?? '')
}

/**
 * localStorage の値をリアクティブに利用するためのカスタムフック
 *
 * 仕様:
 * - マウント時にlocalStorageから値を読み取ってstateの初期値として提供する
 * - stateの値が変更されたときにはlocalStorageに値を反映する
 * - localStorageが変更されたとき（他のタブやウィンドウでの変更も含む）はstateに値を反映する
 *
 * 以下の2つのOSSのいいとこ取りをしたもの:
 * - https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js
 * - https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
 */
export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // ローカルストレージを State の Source of Truth として利用する
  const storedValue = useSyncExternalStore(subscribeLocalStorage, () =>
    getLocalStorageItem(key),
  )

  let parsedValue = initialValue

  if (storedValue !== null) {
    try {
      parsedValue = parseJSON(storedValue)
    } catch {
      console.error('パースに失敗しました: ', storedValue)
    }
  }

  const setState = useCallback(
    (nextValue: T) => {
      try {
        setLocalStorageItem(key, JSON.stringify(nextValue))
      } catch {
        console.error(
          'ローカルストレージへの値の保存に失敗しました: ',
          nextValue,
        )
      }
    },
    [key],
  )

  return [parsedValue, setState] as const
}
