import {
  BranchesOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  LogicConditionTypeEnum,
  MultipleChoice,
  TemplateLogic,
  TemplateNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button, Checkbox, Col, Modal, Radio, Row, Typography } from 'antd'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import {
  BranchesIconColor,
  Danger,
  Success,
  White,
} from 'src/features/theme/KdsThemeColor'
import Yup from 'src/features/validation/yup'
import {
  addTemplateMultipleChoiceLogic,
  updateTemplateMultipleChoiceLogic,
} from 'src/state/ducks/templates/actions'
import { closeEditLogic } from 'src/state/ducks/templates/editLogic/actions'
import { RootState } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import styled from 'styled-components'

// この色はこのコンポーネントでしか使っていない。整理が必要。
export const DisabledButtonBg = '#E0E0E0'

const validationSchema = Yup.object().shape({
  responseMultipleChoices: Yup.array().min(1).label('項目選択肢'),
  conditionType: Yup.number().required().label('分岐の条件設定'),
})

const { Title, Text } = Typography

const SettingModal = styled(Modal)`
  width: '100%';
`

const ModalTitleWrap = styled.div`
  display: flex;
  align-items: center;
`

const ModalTitle = styled(Title)`
  && {
    margin-bottom: 0px;
    margin-left: 10px;
  }
`

const ModalCheckButtonGroup = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
`

const ModalCheckButtonSuffixText = styled.span`
  margin-left: 5px;
`

const NormalCheckButtonWrap = styled.div`
  margin-right: 15px;
`

const SettingCheckBoxesRow = styled(Row)`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`

const isIncludesResponseMultipleChoices = (
  questionNode: TemplateNodeSchema | undefined,
  templateNodes: { [key: number]: TemplateNodeSchema },
  id: number | undefined,
) => {
  return questionNode?.nodes.some((nodeId: number) =>
    templateNodes[nodeId]?.logic?.responseMultipleChoices?.some(
      (choice: MultipleChoice) => choice.id === id,
    ),
  )
}

/**
 * 選択肢による条件分岐の設定モーダル
 */
const EditMultipleChoiceLogicModal = () => {
  const dispatch = useDispatch()

  const {
    editLogic: { isOpenMultipleChoiceModal, questionNode },
    templatePages: { templateNodes },
  } = useSelector((state: RootState) => state.templatesState)

  const createInitialValue = (): TemplateLogic => {
    const logicNodes = questionNode?.nodes
      .filter(
        (nodeId: number) =>
          templateNodes[nodeId]?.type === TemplateNodeTypeEnum.Logic,
      )
      .map((nodeId: number) => templateNodes[nodeId])
      .filter(isDefined)

    if (logicNodes && logicNodes.length > 0) {
      const conditionType = logicNodes[0]?.logic?.conditionType

      if (conditionType === LogicConditionTypeEnum.Is) {
        const responseMultipleChoices = logicNodes.map(
          (node: TemplateNodeSchema) =>
            node.logic?.responseMultipleChoices?.[0] ?? {},
        )

        return {
          responseMultipleChoices: responseMultipleChoices as MultipleChoice[],
          conditionType: LogicConditionTypeEnum.Is,
        }
      }

      if (conditionType === LogicConditionTypeEnum.IsOneOf) {
        return {
          responseMultipleChoices:
            logicNodes[0]?.logic?.responseMultipleChoices,
          conditionType: LogicConditionTypeEnum.IsOneOf,
        }
      }
    }
    return {
      responseMultipleChoices: [],
      conditionType: undefined,
    }
  }

  const initialValues = createInitialValue()

  const { errors, values, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      if (values.responseMultipleChoices === undefined) {
        /**
         * FIXME:
         * 空の配列に対してsetFieldValueを実行すると、対象のfieldがundefinedになってしまうissueが現状存在するため、
         * 暫定的にsetFieldErrorを挿入している。Yupに統合したい。
         * https://github.com/jaredpalmer/formik/issues/1616
         */
        formikHelpers.setFieldError(
          'responseMultipleChoices',
          '項目選択肢は必須です',
        )
        formikHelpers.setFieldTouched('responseMultipleChoices', true, false)
        return
      }
      if (values.conditionType === undefined) {
        formikHelpers.setFieldError(
          'responseMultipleChoices',
          '分岐の条件設定は必須です',
        )
        formikHelpers.setFieldTouched('conditionType', true, false)
        return
      }
      // 単に型の都合であり、実際にはundefinedになることはない想定
      if (questionNode === undefined) return

      const isUpdate = questionNode.nodes.some((nodeId: number) => {
        const thisNode = templateNodes[nodeId]
        return thisNode && thisNode.type === TemplateNodeTypeEnum.Logic
      })

      if (isUpdate) {
        dispatch(
          updateTemplateMultipleChoiceLogic(
            questionNode,
            values.conditionType,
            values.responseMultipleChoices,
          ),
        )
      } else {
        dispatch(
          addTemplateMultipleChoiceLogic(
            questionNode,
            values.conditionType,
            values.responseMultipleChoices,
          ),
        )
      }

      dispatch(closeEditLogic())
      formikHelpers.resetForm()
    },
    enableReinitialize: true,
  })

  const responseMultipleChoices =
    (questionNode?.question?.responseMultipleChoices ?? [])[0]?.responses ?? []
  const normalResponseMultipleChoices = responseMultipleChoices?.filter(
    multipleChoice => !multipleChoice.isInvalid,
  )
  const isInvalidResponseMultipleChoices = responseMultipleChoices?.filter(
    multipleChoice => multipleChoice.isInvalid,
  )

  const isUpdate = questionNode?.nodes.some(
    (nodeId: number) =>
      templateNodes[nodeId]?.type === TemplateNodeTypeEnum.Logic,
  )
  return (
    <SettingModal
      title={
        <ModalTitleWrap>
          <BranchesOutlined rotate={90} style={{ color: BranchesIconColor }} />
          <ModalTitle level={4}>条件分岐の設定</ModalTitle>
        </ModalTitleWrap>
      }
      style={{ minWidth: '1000px' }}
      open={isOpenMultipleChoiceModal}
      onOk={() => handleSubmit()}
      onCancel={() => dispatch(closeEditLogic())}
      okText="設定"
      cancelText="キャンセル"
    >
      {questionNode !== undefined ? (
        <>
          <Row>
            <Text strong>
              「{questionNode.question?.name ?? ''}
              」には以下の条件分岐を設定可能です。
            </Text>
          </Row>
          <Row>
            <ModalCheckButtonGroup>
              <NormalCheckButtonWrap>
                <Button
                  onClick={() => {
                    setFieldValue(
                      'responseMultipleChoices',
                      normalResponseMultipleChoices,
                    )
                  }}
                  style={{
                    backgroundColor: isUpdate ? DisabledButtonBg : Success,
                    color: White,
                    borderColor: isUpdate ? DisabledButtonBg : Success,
                  }}
                  icon={<CheckCircleOutlined />}
                  disabled={isUpdate}
                >
                  正常
                </Button>
                <ModalCheckButtonSuffixText>の場合</ModalCheckButtonSuffixText>
              </NormalCheckButtonWrap>
              <div>
                <Button
                  onClick={() => {
                    setFieldValue(
                      'responseMultipleChoices',
                      isInvalidResponseMultipleChoices,
                    )
                  }}
                  style={{
                    backgroundColor: isUpdate ? DisabledButtonBg : Danger,
                    color: White,
                    borderColor: isUpdate ? DisabledButtonBg : Danger,
                  }}
                  icon={<ExclamationCircleOutlined />}
                  disabled={isUpdate}
                >
                  逸脱
                </Button>
                <ModalCheckButtonSuffixText>の場合</ModalCheckButtonSuffixText>
              </div>
            </ModalCheckButtonGroup>
          </Row>
          <SettingCheckBoxesRow>
            <Col span={11}>
              <Row>
                {responseMultipleChoices.map(
                  (multipleChoice: MultipleChoice, index) => {
                    return (
                      <Col key={index} span={12}>
                        <Checkbox
                          onChange={e => {
                            if (e.target.checked) {
                              setFieldValue('responseMultipleChoices', [
                                ...(values?.responseMultipleChoices ?? []),
                                multipleChoice,
                              ])
                            } else {
                              setFieldValue(
                                'responseMultipleChoices',
                                (values?.responseMultipleChoices ?? []).filter(
                                  val => val.id !== multipleChoice.id,
                                ),
                              )
                            }
                          }}
                          checked={
                            values?.responseMultipleChoices
                              ?.map(response => response.id)
                              .includes(multipleChoice.id) ?? false
                          }
                          disabled={
                            isUpdate &&
                            isIncludesResponseMultipleChoices(
                              questionNode,
                              templateNodes,
                              multipleChoice.id,
                            )
                          }
                        >
                          {multipleChoice.response}
                        </Checkbox>
                      </Col>
                    )
                  },
                )}
              </Row>
              <Row>
                <FormikErrorMessage
                  name="responseMultipleChoices"
                  errors={errors}
                  touched={touched}
                />
              </Row>
            </Col>
            <Col span={2}>
              <RightOutlined />
            </Col>
            <Col span={11}>
              {(values.responseMultipleChoices ?? []).length > 0 && (
                <>
                  <Radio.Group
                    onChange={e => {
                      setFieldValue('conditionType', e.target.value)
                    }}
                    value={values.conditionType}
                    disabled={isUpdate}
                  >
                    <Radio value={LogicConditionTypeEnum.Is}>
                      選択したそれぞれの項目で分岐する
                    </Radio>
                    <Radio value={LogicConditionTypeEnum.IsOneOf}>
                      まとめて一つの質問へ分岐する
                    </Radio>
                  </Radio.Group>
                  <FormikErrorMessage
                    name="conditionType"
                    errors={errors}
                    touched={touched}
                  />
                </>
              )}
            </Col>
          </SettingCheckBoxesRow>
        </>
      ) : null}
    </SettingModal>
  )
}

export default EditMultipleChoiceLogicModal
