import { ClientTypeEnum } from '@ulysses-inc/harami_api_client'
import { getBasePath } from 'src/config'
import { ReportLabelInspection } from 'src/modules/lobster/model/report-label-inspection'
import { getApiKey } from 'src/state/middleware/saga/getApiKey'

export const getReportLabelInspections = async (ids: string[]) => {
  const res = await fetch(
    `${getBasePath()}/lobster/report-label-inspections?ids=${ids.join(',')}`,
    {
      credentials: 'include',
      headers: {
        'X-API-KEY': getApiKey(),
        ct: `${ClientTypeEnum.WEB}`,
        locationHref: `${location.href}`,
      },
    },
  )

  // MEMO: この辺はAPI Clientに置き換えたら自動的にエラーハンドリングしてくれるはず
  if (!res.ok) {
    return new Error('failed to get report label inspections')
  }

  // TODO: oapi-codegenで生成された型に置き換える
  const resBody = (await res.json()) as {
    reportLabelInspections: ReportLabelInspection[]
  }
  return resBody.reportLabelInspections
}
