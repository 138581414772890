import { PlaceNode, PlaceNodeTypeEnum } from '@ulysses-inc/harami_api_client'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import placesOperations from 'src/state/ducks/places/operations'
import { RootState } from 'src/state/store'
import FooterButton from 'src/views/components/drawer/FooterButton'
import { useFormikProps } from './EditPlaceGroup.hooks'
import { PlaceGroupPageHeader } from './EditPlaceGroup.styled'
import NameRow from './NameRow'
import ParentRow from './ParentRow'
import PlacesRow from './PlacesRow'

export interface FlatNode extends PlaceNode {
  path: number[]
}

export const flattenNodes = (
  nodes: PlaceNode[],
  path: number[] | undefined,
) => {
  const flatNodes: FlatNode[] = []
  if (nodes && nodes.length > 0) {
    nodes.forEach(n => {
      const node = n as PlaceNode
      flatNodes.push({ ...node, path: [...(path ?? [])] })
      if (node.nodes && node.nodes.length)
        flatNodes.push(
          ...flattenNodes(node.nodes as PlaceNode[], [
            ...(path ?? []),
            node.id,
          ]),
        )
    })
  }
  return flatNodes
}

const EditPlaceGroup: React.FC = () => {
  const nodes = useSelector(
    (state: RootState) => state.placesState.placeGroups.nodes,
  )
  const activeNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeNodeId,
  )
  const activeParentNodeId = useSelector(
    (state: RootState) => state.placesState.placeGroups.activeParentNodeId,
  )
  const isLoadingPlaceGroup = useSelector(
    (state: RootState) => state.placesState.placeGroups.isLoading,
  )

  const dispatch = useDispatch()
  const onCancel = () => {
    placesOperations.changeIsShowEditPlaceGroupDrawer(dispatch, false)
  }

  const placeGroupNodes = flattenNodes(nodes, []).filter(
    ({ type }) => type === PlaceNodeTypeEnum.PlaceGroup,
  )
  const activeNode = placeGroupNodes.find(({ uuid }) => uuid === activeNodeId)
  const activeParentNode = placeGroupNodes.find(
    ({ id }) => id === activeParentNodeId,
  )
  const activeGroup = activeNode?.placeGroup

  const { handleChange, handleSubmit, values, setFieldValue, errors, touched } =
    useFormikProps({
      activeNode,
      activeParentNode,
    })

  return (
    <>
      <PlaceGroupPageHeader>{`現場グループの${activeGroup ? '編集' : '追加'}`}</PlaceGroupPageHeader>
      <NameRow {...{ handleChange, values, touched, errors }} />
      <ParentRow {...{ setFieldValue }} />
      <PlacesRow {...{ setFieldValue }} />
      <FooterButton
        spinning={isLoadingPlaceGroup}
        onCancel={onCancel}
        onSubmit={() => handleSubmit()}
      />
    </>
  )
}

export default EditPlaceGroup
