/* eslint-disable react/prop-types */
import {
  DeleteOutlined,
  DownloadOutlined,
  LeftOutlined,
  PlusCircleOutlined,
  ProjectOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { pdf } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  ReportPageSchema,
  ReportStatusEnum,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import { Button } from 'antd'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { logEvent } from 'firebase/analytics'
import * as H from 'history'
import React, { useEffect, useRef, useState } from 'react'
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { HEADER_HEIGHT, Header } from 'src/components/header/Header'
import { HeaderBackButton } from 'src/components/header/HeaderBackButton'
import { HeaderTitle } from 'src/components/header/HeaderTitle'
import { Lightbox } from 'src/components/lightbox/Lightbox'
import { localStorageKeys } from 'src/constants/localStorageKeys'
import { ReportResultSchema } from 'src/exShared/types/types'
import { isInvalid } from 'src/exShared/util/report/isInvalid'
import filterNodesAnsweredBranch from 'src/exShared/util/reportResult/filterNodesAnsweredBranch'
import isEmployeeCheckOriginalSection from 'src/exShared/util/reportResult/isEmployeeCheckOriginalSection'
import {
  allPagesChildNodes,
  pageChildNodes,
} from 'src/exShared/util/reportResult/nodes'
import ApprovalsContainer from 'src/features/approvals/Approvals'
import DataUnavailableWidget from 'src/features/dataUnavailableWidget/DataUnavailableWidget'
import { useDataUnavailableWidget } from 'src/features/dataUnavailableWidget/useDataUnavailableWidget'
import { useLimitedFeatureAvailability } from 'src/features/featureAvailability/useLimitedFeatureAvailability'
import { firebaseAnalytics } from 'src/features/firebase/firebase'
import { ReportResultSummary } from 'src/features/reports/result/components/reportResult/ReportResultSummary'
import {
  AppBackgroundColor,
  BorderColor,
  DeleteIconColor,
} from 'src/features/theme/KdsThemeColor'
import { useLobsterService } from 'src/modules/lobster'
import approvalsOperations from 'src/state/ducks/approvals/operations'
import reportsOperations from 'src/state/ducks/reports/operations'
import { selectReportLabelInspectionIds } from 'src/state/ducks/reports/reports/selectors'
import { RootState, store } from 'src/state/store'
import { isDefined } from 'src/util/idDefined'
import styled from 'styled-components'
import Loading from '../../../components/loading/Loading'
import { SideMenu } from './SideMenu'
import { WebReportResultProps } from './WebReportResultProps'
import ReportSummaryDocument from './downloadPDF/reportSummaryDocument/ReportSummaryDocument'

// リファクタリングの過程でやむなく仮設したカスタムフック
const useProps = (): WebReportResultProps => {
  const history = useHistory()
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state)
  const urlParams = useParams<{ id: string }>()

  return {
    // -----------------------------------------------------------------------------------------------------------------
    // WebStateProps
    // -----------------------------------------------------------------------------------------------------------------
    reportId: urlParams['id'],
    templateImages: state.reportsState.reportResult.templateImages,
    report: state.reportsState.reportResult.report,
    isLoadingReportResultOrTemplatePages:
      state.reportsState.reportResult.isLoadingReportResult ||
      state.reportsState.reportResult.isLoadingTemplatePages,
    comment: state.reportsState.reportResult.report.comment,
    deviatedAnswers: Object.values(
      state.reportsState.reportResult.report.nodes,
    ).filter(node => {
      if (node.type !== TemplateNodeTypeEnum.Question) {
        return false
      }
      return isInvalid(node.question)
    }),
    isOffline: false,
    // 直下のnode取得
    getReportPageNodes: (page: ReportPageSchema): Array<ReportNodeSchema> => {
      const allNodes = state.reportsState.reportResult.report.nodes
      return filterNodesAnsweredBranch(
        (page.nodes ?? [])
          .map((id: number) => allNodes[id])
          .filter((elem): elem is NonNullable<typeof elem> => !!elem),
        allNodes,
      )
    },
    // 直下のnode取得
    getReportNodesNodes: (node: ReportNodeSchema): Array<ReportNodeSchema> => {
      if (isEmployeeCheckOriginalSection(node)) {
        return []
      }
      const allNodes = state.reportsState.reportResult.report.nodes
      return filterNodesAnsweredBranch(
        (node.nodes ?? []).map((id: number) => allNodes[id]).filter(isDefined),
        allNodes,
      )
    },
    getPages: (): ReportPageSchema[] =>
      state.reportsState.reportResult.report.pageIds
        .map((id: number) => state.reportsState.reportResult.report.pages[id])
        .filter(isDefined),
    allPagesChildNodes: () =>
      allPagesChildNodes(
        state.reportsState.reportResult.report.pages,
        state.reportsState.reportResult.report.nodes,
      ),
    // ページに紐付く子node全て取得
    pageChildNodes: (page: ReportPageSchema) =>
      pageChildNodes(page, state.reportsState.reportResult.report.nodes),

    // -----------------------------------------------------------------------------------------------------------------
    // WebReducersProps
    // -----------------------------------------------------------------------------------------------------------------

    getReportResult: (reportId?: string) => {
      reportsOperations.getReportResult(dispatch, reportId)
    },
    addReportComment: (reportId: string, comment: string) => {
      reportsOperations.addReportComment(dispatch, reportId, comment)
    },
    addAttachmentFile: (reportId: string, file: File) => {
      reportsOperations.uploadReportAttachmentFile(dispatch, reportId, file)
    },
    deleteAttachmentFile: (reportId: string, fileId: string) => {
      reportsOperations.deleteReportAttachmentFile(dispatch, reportId, fileId)
    },
    resetReport: () => {
      reportsOperations.resetReportResult(dispatch)
      approvalsOperations.resetApproval(dispatch)
    },
    addApprovalReport: (reportUUID: string, approvalFlowId: number) => {
      const location = window.location
      const url = location.href
      approvalsOperations.addApprovalReport(
        dispatch,
        reportUUID,
        approvalFlowId,
        url,
      )
    },
    goImproveDetail: (history: H.History, improveUUID: string) => {
      history.push(`/improves/${improveUUID}`)
    },

    // -----------------------------------------------------------------------------------------------------------------
    // WebNavigateProps
    // -----------------------------------------------------------------------------------------------------------------

    goPreviewMemo: (_imageUrl: string) => {
      // ReportResultSceneでreact-image-lightboxを使用して実装している
    },
    goPreviewPhoto: (_imageUrls: string[], _selectedImageIndex: number) => {
      // ReportResultSceneでreact-image-lightboxを使用して実装している
    },
    goReportList: (history: H.History) => {
      if (history.action === 'POP') {
        history.push('/reports')
      } else {
        history.goBack()
      }
    },

    // -----------------------------------------------------------------------------------------------------------------
    // ReactRouterProps
    // -----------------------------------------------------------------------------------------------------------------

    history,
  }
}

/**
 * サイドメニューや承認ペインの表示・非表示を切り替える境界
 */
const BREAK_POINT_WIDTH = 768

const MIN_SIDEBAR_WIDTH = 230
const MIN_APPROVAL_FLOW_WIDTH = 280

const SideMenuToggleIcon = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: ${props => props.theme.left};
  z-index: 3;
  transform: translateX(0%);
  transition: all 0.3s ease-out;
`

/**
 * サイドメニューの描写領域を確保する（幅の狭い画面用・オーバーレイ部分）
 */
const SmallLayoutSideMenuContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  width: ${props => props.theme.width};
  z-index: 1;
  left: 0;
`

/**
 * サイドメニューの描写領域を確保する（幅の狭い画面用・サイドメニュー部分）
 */
const SmallLayoutSideMenuWrap = styled.div`
  background: white;
  border: 1px ${BorderColor} solid;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  top: ${HEADER_HEIGHT}px;
  transition: all 0.3s ease-out;
  width: ${props => props.theme.width};
`

const ReportStamp = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 32px;
`

const ReportLabelPageHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
`

// @react-pdf の PDFDownloadLink コンポーネントを利用しない理由は
// PDFDownloadLink では画面が開かれた時に PDF の生成処理が走って、画面をブロックする可能性があるため
// PDFDownloadButton では ボタン押下時に PDF の生成を行うようにしている
const PDFDownloadButton: React.FC<{
  comment: string
  disabled: boolean
  report: ReportResultSchema
}> = ({ comment, disabled, report }) => {
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.usersState.user.user)

  const handleClick = async () => {
    setLoading(true)
    const blob = await pdf(
      <Provider store={store}>
        <ReportSummaryDocument comment={comment} report={report} />
      </Provider>,
    ).toBlob()
    setLoading(false)
    saveAs(
      blob,
      `${report.name ?? 'report'}_${format(report.reportDate, 'yyyyMMdd')}.pdf`,
    )
    logEvent(firebaseAnalytics, 'report_pdf_download', {
      companyId: user?.company.id,
      reportUUID: report.uuid,
      reportLayout: report.layoutType,
    })
  }

  return (
    <Button
      icon={<DownloadOutlined />}
      disabled={disabled}
      onClick={handleClick}
    >
      {loading ? 'Loading...' : 'PDF'}
    </Button>
  )
}

const ReportResultSummaryWrap: React.FC<WebReportResultProps> = props => {
  const { isFeatureAvailable } = useLimitedFeatureAvailability()
  const [isOpenImageModal, setIsOpenImageModal] = useState(false)
  const [images, setImages] = useState<string[]>([])
  const [imageSelectedIndex, setImageSelectedIndex] = useState(1)
  const inputEl = useRef<HTMLInputElement>(null)
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          margin: '10px 0',
        }}
      >
        <div>
          <div>
            {props.report.improve && isFeatureAvailable('improves') && (
              <Button
                type="link"
                onClick={() =>
                  props.goImproveDetail(
                    props.history,
                    props.report.improve?.uuid,
                  )
                }
              >
                <ProjectOutlined />
                カイゼンを確認
              </Button>
            )}
            <Button
              type="link"
              onClick={() => {
                if (inputEl && inputEl.current) {
                  inputEl.current.click()
                }
              }}
            >
              <PlusCircleOutlined />
              ファイルを添付
            </Button>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={inputEl}
              onChange={e => {
                const file = e.target.files && e.target.files.item(0)
                if (file) {
                  props.addAttachmentFile(props.reportId, file)
                }
              }}
            />
            {props.report.attachmentFiles &&
              props.report.attachmentFiles.length > 0 &&
              props.report.attachmentFiles.map(file => (
                <div
                  key={`attachmentFile-${file.uuid}`}
                  style={{ marginTop: 4, paddingLeft: 16, marginBottom: 4 }}
                >
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                  <Button
                    icon={<DeleteOutlined style={{ color: DeleteIconColor }} />}
                    style={{
                      marginLeft: 8,
                      background: AppBackgroundColor,
                      border: 'none',
                    }}
                    onClick={() => {
                      if (file.uuid) {
                        props.deleteAttachmentFile(props.reportId, file.uuid)
                      }
                    }}
                  />
                </div>
              ))}
          </div>
          <ReportStamp>
            {/** TODO テンプレートにスタンプを付与できるようになったら表示する
             <img
             width="100"
             height="auto"
             style={{ objectFit: 'contain' }}
             src={
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSdC5oGMKzG5z_Jwc0nvZ0gvf4rt7xM6T1Hr0ECCHrQ1BZvDkYJw&s'
            }
             />
             */}
          </ReportStamp>
        </div>
      </div>
      <ReportResultSummary
        {...{
          ...props,
          goPreviewMemo: (imageUrl: string) => {
            setImages([imageUrl])
            setImageSelectedIndex(0)
            setIsOpenImageModal(true)
          },
          goPreviewPhoto: (imageUrls: string[], selectedImageIndex: number) => {
            setImages(imageUrls)
            setImageSelectedIndex(selectedImageIndex)
            setIsOpenImageModal(true)
          },
        }}
      />
      <Lightbox
        carousel={{ finite: true }}
        close={() => setIsOpenImageModal(!isOpenImageModal)}
        index={imageSelectedIndex}
        open={isOpenImageModal}
        slides={images.map(image => ({ src: image }))}
      />
    </>
  )
}

const ReportResultScene = () => {
  const props = useProps()
  const dataUnavailableWidget = useDataUnavailableWidget()

  const hasUuid = (props.report?.uuid ?? '').length > 0
  const showPdfButton = !props.isLoadingReportResultOrTemplatePages && hasUuid

  useEffect(() => {
    props.getReportResult(props.reportId)
    return () => {
      props.resetReport()
      dataUnavailableWidget.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reportId])

  const [showSideMenu, setShowSideMenu] = useState(false)

  const approvalFlows = props.report.approvalFlows ?? []
  const approvalFlow =
    approvalFlows.length === 0 ? undefined : (approvalFlows[0] ?? undefined)
  const approvals = props.report.approvals ?? []
  const approvalUUID =
    approvals.length > 0 ? (approvals[approvals.length - 1]?.uuid ?? '') : ''

  const shouldShowApprovalFlowColumn = !!approvalFlow

  // NOTE: https://kaminashi.atlassian.net/browse/INE-1822
  // レポート一覧と詳細画面を行き来することで、
  // 非同期処理が制御されなくなり state に不整合が発生している可能性がある
  // そのため、別のレポートの承認情報が誤って表示、
  // 承認が行えないように承認中は戻るボタンを非活性にする
  // 本来であれば画面全体をローディング状態にし、操作を行えないようにすべきである
  // 上記を行ってしまうと ApprovalsContainer の useEffect で実行している
  // dispatch(getApproval(approvalUUID)) が
  // isLoadingApprovals が切り替わるたびに発火してしまうため、このような実装となっている
  // また、どちらの実装でもブラウザバックに対する制御は行えていないため、
  // 将来的にはブラウザバックに対する制御も検討する必要がある
  const isLoadingApprovals = useSelector(
    ({
      approvalsState: {
        approval,
        deleteApproval,
        addApprovalStep,
        addApprovalReport,
      },
    }: RootState) =>
      approval.isLoading ||
      deleteApproval.isLoading ||
      addApprovalStep.isLoading ||
      addApprovalReport.isLoading,
    shallowEqual,
  )

  return (
    <div>
      <Header
        rightSlot={
          showPdfButton && (
            <PDFDownloadButton
              comment={props.comment}
              disabled={dataUnavailableWidget.isVisible}
              report={props.report}
            />
          )
        }
        sticky
      >
        <HeaderBackButton
          disabled={isLoadingApprovals}
          onClick={() => props.goReportList(props.history)}
        />
        <HeaderTitle css={styles.headerTitle} title={props.report.name ?? ''} />
      </Header>
      {props.isLoadingReportResultOrTemplatePages ? (
        <Loading />
      ) : dataUnavailableWidget.isVisible ? (
        <DataUnavailableWidget />
      ) : (
        <div css={styles.mainArea}>
          {/* サイドメニュー */}
          <div css={styles.sideMenuColumn}>
            <SideMenu {...props} />
          </div>

          {/* レポート結果 */}
          <div css={styles.reportResultColumn}>
            <ReportResultSummaryWrap {...props} />
          </div>

          {/* 承認フロー */}
          <div
            css={[
              styles.approvalFlowColumn,
              // 承認フローを表示しなくてよい場合でも、画面上は透明なカラム幅を確保するデザイン仕様となっているため
              shouldShowApprovalFlowColumn && { background: 'white' },
            ]}
          >
            {shouldShowApprovalFlowColumn && (
              <ApprovalsContainer
                reportUUID={props.reportId}
                isReport={true}
                approvalUUID={approvalUUID}
                approvalFlow={approvalFlow}
                header={
                  <ReportLabelPageHeader>承認フロー</ReportLabelPageHeader>
                }
                onApproval={() =>
                  props.addApprovalReport(
                    props.report.uuid ?? '',
                    approvalFlow.id ?? 0,
                  )
                }
                isDisabled={
                  props.report.isEditable
                    ? false
                    : props.report.status.status ===
                        ReportStatusEnum.INCOMPLETE ||
                      props.report.status.status ===
                        ReportStatusEnum.APPROVAL_REMAND
                }
                isRemanded={
                  props.report.status.status ===
                  ReportStatusEnum.APPROVAL_REMAND
                }
                isInCompleted={
                  props.report.status.status === ReportStatusEnum.INCOMPLETE
                }
                assignees={props.report.assignee ? [props.report.assignee] : []}
                reportName={props.report.name}
                placeName={props.report.place?.name ?? ''}
                reportDate={props.report.reportDate}
                remandedUserName={
                  localStorage.getItem(localStorageKeys.loginUserName) || ''
                }
              />
            )}
          </div>

          {/* サイドメニュー for モバイルデバイス? */}
          <div css={styles.sideMenuForNarrowDevice}>
            <SideMenuToggleIcon theme={{ left: showSideMenu ? '70%' : '8px' }}>
              <Button
                icon={
                  showSideMenu ? (
                    <LeftOutlined style={{ fontSize: 14 }} />
                  ) : (
                    <RightOutlined style={{ fontSize: 14 }} />
                  )
                }
                onClick={e => {
                  e.stopPropagation()
                  setShowSideMenu(!showSideMenu)
                }}
                style={{ width: 46 }}
              />
            </SideMenuToggleIcon>
            <SmallLayoutSideMenuContainer
              theme={{ width: showSideMenu ? '100vw' : 0 }}
            >
              <SmallLayoutSideMenuWrap
                theme={{ width: showSideMenu ? '70%' : 0 }}
              >
                <SideMenu {...props} />
              </SmallLayoutSideMenuWrap>
            </SmallLayoutSideMenuContainer>
          </div>
        </div>
      )}
    </div>
  )
}

const ReportResultSceneWithLobsterStore = () => {
  const { LabelCheckAnswerStoreProvider } = useLobsterService()

  const reportLabelInspectionIds = useSelector(
    selectReportLabelInspectionIds,
    (prev, next) =>
      prev.length === next.length && prev.every((v, i) => v === next[i]),
  )

  return (
    <LabelCheckAnswerStoreProvider
      labelInspectionIds={reportLabelInspectionIds}
    >
      <ReportResultScene />
    </LabelCheckAnswerStoreProvider>
  )
}

const styles = {
  headerTitle: css`
    // 長いレポート名が設定されていることもあるから
    max-width: 70vw;
  `,
  mainArea: css`
    display: flex;
  `,
  sideMenuColumn: css`
    flex: 0 0 max(20vw, ${MIN_SIDEBAR_WIDTH}px);
    height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: auto;
    position: sticky;
    top: ${HEADER_HEIGHT}px;

    @media screen and (max-width: ${BREAK_POINT_WIDTH}px) {
      display: none;
    }
  `,
  reportResultColumn: css`
    flex: 1 1 auto;
    overflow-x: hidden;
    padding-bottom: 48px;
    padding-left: 0;
    padding-right: 24px;

    @media screen and (max-width: ${BREAK_POINT_WIDTH}px) {
      padding: 0 24px;
    }
  `,
  approvalFlowColumn: css`
    flex: 0 0 max(25vw, ${MIN_APPROVAL_FLOW_WIDTH}px);
    height: calc(100vh - ${HEADER_HEIGHT}px);
    overflow-y: auto;
    padding: 24px;
    position: sticky;
    top: ${HEADER_HEIGHT}px;

    @media screen and (max-width: ${BREAK_POINT_WIDTH}px) {
      display: none;
    }
  `,
  // この人は実際には`display: fixed`な場所に描写される
  sideMenuForNarrowDevice: css`
    display: none;

    @media screen and (max-width: ${BREAK_POINT_WIDTH}px) {
      display: block;
    }
  `,
}

export default ReportResultSceneWithLobsterStore
