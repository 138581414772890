import { BookOutlined, ExportOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { HTMLAttributes, ReactNode } from 'react'
import { ClampText } from 'src/components/clampText/ClampText'
import { navItemStyle } from 'src/features/globalNavigation/components/navItem/navItemStyle'
import { BetaBadge } from '../BetaBadge'

type Props = {
  /**
   * 外部リンク
   * e.g. `https://example.com/help-page`
   */
  externalPath: string
  isBeta?: boolean
  /**
   * 折り畳まれているかどうか
   */
  isCollapsed: boolean
  icon?: ReactNode
  title: string
} & HTMLAttributes<HTMLElement>

/**
 * グローバルナビゲーションの個々のアイテム (外部リンク)
 */
export const NavItemExternalLink = (props: Props) => {
  const {
    externalPath,
    isCollapsed,
    title,
    icon,
    isBeta = false,
    ...restProps
  } = props

  // 開閉状態に応じて表示内容を切り替える
  let content: ReactNode
  if (isCollapsed) {
    content = icon ? icon : <BookOutlined />
  } else {
    content = (
      <>
        {icon && icon}
        <ClampText css={navItemStyle.title}>{title}</ClampText>
        {!icon && <ExportOutlined css={styles.exportIcon} />}
        {isBeta && <BetaBadge />}
      </>
    )
  }

  return (
    <Tooltip title={isCollapsed ? title : ''} placement="right">
      <a
        {...restProps}
        css={[
          navItemStyle.container,
          isCollapsed && navItemStyle.containerCollapsed,
        ]}
        href={externalPath}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}
      </a>
    </Tooltip>
  )
}

const styles = {
  exportIcon: css`
    margin-left: auto;
    width: 12px;
  `,
}
