import { FeatureNameEnum } from '@ulysses-inc/harami_api_client'
import { useFeature } from 'src/features/featureFlags/useFeatureFlags'
import { appEnv } from 'src/util/appEnv'

/**
 * 宣伝バナーが表示できるかどうかを返すカスタムフック
 */
export const useSanmaPromoBanner = (): boolean => {
  const isPromoBannerEnabled =
    useFeature(FeatureNameEnum.PROMO_BANNER).canUse === 'yes'

  // ローカル環境では無条件で表示する
  if (appEnv.isLocal) return true

  return isPromoBannerEnabled
}
