import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { RuleDefTypeEnum } from '@ulysses-inc/harami_api_client'
import { Row, Select } from 'antd'
import { FormikProps } from 'formik'
import { FC, useId } from 'react'
import FormikErrorMessage from 'src/components/formikErrorMessage/FormikErrorMessage'
import { NumberRuleProps } from './EditResponseNumberRuleEnableProductMasterDrawer'
import { FixedRuleSelector } from './FixedRuleSelector'
import { MasterRuleSelector } from './MasterRuleSelector'
import {
  FormGroup,
  RuleContainer,
  RuleDefTypeSelect,
  RuleSwitch,
  RuleSwitchRow,
} from './NormalValueRow.styled'

const { Option } = Select

type Props = {
  formikProps: FormikProps<NumberRuleProps>
}

const NormalValueRow: FC<Props> = ({ formikProps }) => {
  const {
    values: { hasNumberRule, ruleDefType },
    setFieldValue,
    errors,
  } = formikProps
  const update = (key: keyof NumberRuleProps) => (value: unknown) =>
    setFieldValue(key, value)

  const onChange = (checked: boolean) => {
    if (!checked) {
      update('minimum')(undefined)
      update('maximum')(undefined)
      update('scale')(undefined)
      update('subType')(undefined)
      update('ruleDefType')(undefined)
      update('minimumRuleVariableDef')(undefined)
      update('maximumRuleVariableDef')(undefined)
    }

    update('hasNumberRule')(!!checked)
  }

  const hasNumberRuleLabelId = useId()

  return (
    <FormGroup>
      <RuleSwitchRow>
        <RuleSwitch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={hasNumberRule}
          onChange={onChange}
          aria-labelledby={hasNumberRuleLabelId}
        />
        <label id={hasNumberRuleLabelId}>正常値を設定する</label>
      </RuleSwitchRow>
      {hasNumberRule && (
        <RuleContainer>
          <RuleDefTypeSelect
            placeholder="設定方法を選択"
            value={ruleDefType || undefined}
            onChange={update('ruleDefType')}
            aria-label="設定方法の選択"
          >
            <Option
              value={RuleDefTypeEnum.Fixed}
              data-testid="select-number-ruledeftype-item"
            >
              数値で設定する
            </Option>
            <Option
              value={RuleDefTypeEnum.Master}
              data-testid="select-number-ruledeftype-item"
            >
              マスタから設定する
            </Option>
          </RuleDefTypeSelect>
          {ruleDefType === RuleDefTypeEnum.Fixed && (
            <FixedRuleSelector formikProps={formikProps} />
          )}
          {ruleDefType === RuleDefTypeEnum.Master && (
            <MasterRuleSelector formikProps={formikProps} />
          )}
        </RuleContainer>
      )}
      <Row>
        <FormikErrorMessage name="subType" errors={errors} />
        <FormikErrorMessage name="maximum" errors={errors} />
        <FormikErrorMessage name="minimum" errors={errors} />
        <FormikErrorMessage name="ruleDefType" errors={errors} />
        <FormikErrorMessage name="minimumRuleVariableDef" errors={errors} />
        <FormikErrorMessage name="maximumRuleVariableDef" errors={errors} />
      </Row>
    </FormGroup>
  )
}

export default NormalValueRow
