import { StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  ReportQuestion,
  ResponseTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import filterNodesAnsweredBranch from 'src/exShared/util/reportResult/filterNodesAnsweredBranch'
import { isDefined } from 'src/util/idDefined'
import Grid from '../Grid'
import ReportSummaryLogic from '../reportSummaryLogic/ReportSummaryLogic'
import ReportSummaryQuestionOptions from './questionOptions/ReportSummaryQuestionOptions'
import DateTime from './responses/DateTime'
import Employees from './responses/Employees'
import Formula from './responses/Formula'
import Images from './responses/Images'
import Information from './responses/Information'
import { LabelCheck } from './responses/LabelCheck'
import MultipleChoices from './responses/MultipleChoices'
import NoAnswer from './responses/NoAnswer'
import Number from './responses/Number'
import ResponseSets from './responses/ResponseSets'
import Signature from './responses/Signature'
import ResponseText from './responses/Text'
import { URL } from './responses/URL'

interface OwnProps {
  isFirst: boolean
  isSectionPrev: boolean
  node: ReportNodeSchema
  nodes: { [key: number]: ReportNodeSchema }
  isShowLogic?: boolean
}

interface MergeProps {
  getReportNodesNodes: () => Array<ReportNodeSchema>
}

type ReportSummaryQuestionProps = OwnProps & MergeProps

const ReportSummaryQuestionContainer: React.FC<OwnProps> = (
  _props: OwnProps,
) => {
  const isShowLogic =
    _props.isShowLogic !== undefined ? _props.isShowLogic : true
  const mergeProps: MergeProps = {
    getReportNodesNodes: () => {
      const nodeNodes = _props.node.nodes ?? []
      const allNodes = _props.nodes
      return filterNodesAnsweredBranch(
        nodeNodes.map((id: number) => allNodes[id]).filter(isDefined),
        allNodes,
      )
    },
  }
  const props = { ...{ ..._props, isShowLogic }, ...mergeProps }
  return <ReportSummaryQuestion {...props} />
}

const renderResponse = (question: ReportQuestion) => {
  const questionName = question.name ?? ''
  if (question?.responseAnswer?.noAnswer) {
    return (
      <NoAnswer
        questionName={questionName}
        noAnswer={question.responseAnswer.noAnswer}
        recordedAt={question.responseAnswer?.recordedAt}
        isTimeDisplayed={question.isTimeDisplayed === 1}
      />
    )
  }
  switch (question.responseType) {
    case ResponseTypeEnum.MULTIPLE_CHOICE: {
      return (
        <MultipleChoices
          questionName={questionName}
          answerMultipleChoice={question.responseAnswer?.multipleChoice}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.RESPONSE_SET: {
      return (
        <ResponseSets
          questionName={questionName}
          responses={question.responseAnswer?.responseSets ?? []}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.NUMBER: {
      return (
        <Number
          questionName={questionName}
          questionScale={question.scale ?? ''}
          responseNumber={(question.responseNumbers ?? [])[0] ?? {}}
          numberValue={question.responseAnswer?.numberValue}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.SIGNATURE: {
      return (
        <Signature
          questionName={questionName}
          signature={(question.responseAnswer?.signatures ?? [])[0] ?? {}}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.TEXT: {
      return (
        <ResponseText
          questionName={questionName}
          textValue={question.responseAnswer?.textValue}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.RESULT_IMAGE: {
      return (
        <Images
          questionName={questionName}
          images={question.responseAnswer?.resultImages}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.DATETIME: {
      return (
        <DateTime
          questionName={questionName}
          datetimeValue={question.responseAnswer?.datetimeValue}
          subType={(question.responseDatetimes ?? [])[0]?.subType}
          responseDatetime={question.responseDatetimes?.[0]}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.INFORMATION: {
      return (
        <Information
          questionName={questionName}
          questionInformation={(question.responseInformations ?? [])[0]}
          answerInformation={(question.responseAnswer?.informations ?? [])[0]}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.EMPLOYEE: {
      return (
        <Employees
          questionName={questionName}
          employees={question.responseAnswer?.employees ?? []}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.FORMULA: {
      return (
        <Formula
          questionName={questionName}
          questionScale={question.scale ?? ''}
          responseFormula={(question.responseFormulas ?? [])[0] ?? {}}
          formulaValue={question.responseAnswer?.formulaValue}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.URL: {
      const url = question.responseURLs?.[0]?.url ?? ''
      return (
        <URL
          questionName={questionName}
          url={url}
          recordedAt={question.responseAnswer?.recordedAt}
          isTimeDisplayed={question.isTimeDisplayed === 1}
        />
      )
    }
    case ResponseTypeEnum.LABEL_CHECK: {
      return <LabelCheck questionName={questionName} />
    }
    default: {
      return <Text>{questionName}</Text>
    }
  }
}

const styles = StyleSheet.create({
  fullSizeWidth: { width: '100%' },
})

const ReportSummaryQuestion: React.FC<ReportSummaryQuestionProps> = (
  props: ReportSummaryQuestionProps,
) => {
  const question = props.node.question
  const images = question?.responseAnswer?.images ?? []
  const textMemos = question?.responseAnswer?.textMemos ?? []
  const manualMemos = question?.responseAnswer?.manualMemos ?? []
  const informationImages = question?.informationImages ?? []
  return (
    <View style={styles.fullSizeWidth}>
      <Grid
        borderBottom
        borderTop={props.isFirst || props.isSectionPrev}
        style={styles.fullSizeWidth}
      >
        {question !== undefined && renderResponse(question)}
      </Grid>
      {images.length +
        textMemos.length +
        manualMemos.length +
        informationImages.length >
        0 && (
        <ReportSummaryQuestionOptions
          images={images}
          textMemos={textMemos}
          manualMemos={manualMemos}
          informationImages={informationImages}
        />
      )}
      {props.isShowLogic &&
        props
          .getReportNodesNodes()
          .map((node: ReportNodeSchema) => (
            <ReportSummaryLogic key={node.id} node={node} nodes={props.nodes} />
          ))}
    </View>
  )
}

export default ReportSummaryQuestionContainer
