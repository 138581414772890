import { StyleSheet, View } from '@react-pdf/renderer'
import {
  ReportNodeSchema,
  TemplateNodeTypeEnum,
} from '@ulysses-inc/harami_api_client'
import React from 'react'
import filterNodesAnsweredBranch from 'src/exShared/util/reportResult/filterNodesAnsweredBranch'
import BreakText from 'src/features/reports/result/downloadPDF/BreakText'
import { isDefined } from 'src/util/idDefined'
import ReportSummaryQuestion from '../reportSummaryQuestion/ReportSummaryQuestion'

interface OwnProps {
  node: ReportNodeSchema
  nodes: { [key: number]: ReportNodeSchema }
  parentPath: string
}

interface MergeProps {
  childNodes: Array<ReportNodeSchema>
  path: string
  hasQuestion: boolean
  isFirstQuestionId: number
  isSectionPrevQuestionIds: Array<number>
}

type ReportSummarySectionProps = OwnProps & MergeProps

const styles = StyleSheet.create({
  sectionWrap: {
    marginTop: 5,
    marginBottom: 5,
  },
})

const ReportSummarySection: React.FC<ReportSummarySectionProps> = (
  props: ReportSummarySectionProps,
) => {
  return (
    <View style={styles.sectionWrap}>
      {props.hasQuestion && <BreakText text={props.path} />}
      {props.childNodes.map((node: ReportNodeSchema, index: number) => {
        if (
          props.node.section?.isEmployeeCheck === 1 &&
          props.node.section?.originalNodeUuid &&
          index === 0
        ) {
          return <></>
        }
        if (node.type === TemplateNodeTypeEnum.Section)
          return (
            <ReportSummarySectionContainer
              key={node.id}
              node={node}
              nodes={props.nodes}
              parentPath={props.path}
            />
          )
        if (node.type === TemplateNodeTypeEnum.Question)
          return (
            <ReportSummaryQuestion
              key={node.id}
              node={node}
              nodes={props.nodes}
              isFirst={props.isFirstQuestionId === node.id}
              isSectionPrev={props.isSectionPrevQuestionIds.includes(node.id)}
            />
          )
        return null
      })}
    </View>
  )
}

const ReportSummarySectionContainer: React.FC<OwnProps> = (
  _props: OwnProps,
) => {
  const section = _props.node.section ?? {}
  const nodeNodes = _props.node.nodes ?? []
  const allNodes = _props.nodes
  const childNodes = filterNodesAnsweredBranch(
    nodeNodes.map((id: number) => allNodes[id]).filter(isDefined),
    allNodes,
  )

  const mergeProps: MergeProps = {
    childNodes: childNodes,
    path:
      _props.parentPath === ''
        ? (section.name ?? '')
        : [_props.parentPath, section.name].join('/'),
    hasQuestion:
      childNodes.findIndex(
        node => node.type === TemplateNodeTypeEnum.Question,
      ) !== -1,
    isFirstQuestionId:
      childNodes.find(node => node.type === TemplateNodeTypeEnum.Question)
        ?.id ?? 0,
    isSectionPrevQuestionIds: childNodes
      .filter(
        (_, index: number) =>
          childNodes[index - 1]?.type === TemplateNodeTypeEnum.Section,
      )
      .map((node: ReportNodeSchema) => node.id),
  }
  const props = { ..._props, ...mergeProps }
  return <ReportSummarySection {...props} />
}

export default ReportSummarySectionContainer
