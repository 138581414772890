import { AppRoleEnum } from '@ulysses-inc/harami_api_client'
import { appRole } from 'src/libraries/roles/appRole'

/**
 * leaderとして振る舞える者の一覧 (leaderが持っているロール（役割）群**ではない**)
 */
const leaderPermissions = [
  AppRoleEnum.LEADER,
  AppRoleEnum.EDITOR,
  AppRoleEnum.OWNER,
]

/**
 * editorとして振る舞える者の一覧 (editorが持っているロール（役割）群**ではない**)
 */
const editorPermissions = [AppRoleEnum.EDITOR, AppRoleEnum.OWNER]

/**
 * ownerとして振る舞える者の一覧 (ownerが持っているロール（役割）群**ではない**)
 */
const ownerPermissions = [AppRoleEnum.OWNER]

/**
 * ログインユーザが指定した権限を持っているかどうかを返す
 */
const hasPermission = (permissions: Array<AppRoleEnum>) => {
  const userRole = appRole()
  if (!userRole) return false
  return permissions.includes(userRole)
}

export { editorPermissions, hasPermission, leaderPermissions, ownerPermissions }
