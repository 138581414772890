import { createSlice } from '@reduxjs/toolkit'
import { MasterFieldDef } from '@ulysses-inc/harami_api_client'

interface ProductMasterFieldDefsState {
  productMasterFieldDefs: MasterFieldDef[]
  isLoading: boolean
}

const initialState: ProductMasterFieldDefsState = {
  productMasterFieldDefs: [],
  isLoading: false,
}

interface SuccessGetProductMasterFieldDefsAction {
  type: string
  payload: MasterFieldDef[]
}

export const productMasterFieldDefsSlice = createSlice({
  name: 'harami/ProductMasterFieldDefs',
  initialState,
  reducers: {
    getProductMasterFieldDefsRequested: state => {
      state.isLoading = true
    },
    getProductMasterFieldDefsSucceeded: (
      state,
      action: SuccessGetProductMasterFieldDefsAction,
    ) => {
      state.productMasterFieldDefs = action.payload
      state.isLoading = false
    },
    getProductMasterFieldDefsFailed: state => {
      state.productMasterFieldDefs = []
      state.isLoading = false
    },
    productMasterFieldDefsReset: () => initialState,
  },
})

export const {
  getProductMasterFieldDefsRequested,
  getProductMasterFieldDefsSucceeded,
  getProductMasterFieldDefsFailed,
  productMasterFieldDefsReset,
} = productMasterFieldDefsSlice.actions
export default productMasterFieldDefsSlice.reducer
